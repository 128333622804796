import React from 'react';
import { Menu as MenuAnt, MenuProps } from 'antd';

import { useMenuStyles } from './Menu.style';
import { concatClasses } from '../utils';

export const Menu = ({ className, ...props }: MenuProps) => {
    const classes = useMenuStyles();

    return (
        <MenuAnt
            theme={props.theme || 'dark'}
            mode={props.mode || 'horizontal'}
            className={concatClasses(classes.menu, className || '')}
            {...props}
        />
    );
};
