import { WalletAddress } from '../../types';
import { NETWORK, NETWORK_TO_NETWORK_CHAIN } from '../../constants/global';

export type ErrorBadRequestBody = {
    code: string;
    value: string;
    info: {
        method: string;
    };
};
export class WalletHelper {
    static CHAR_COUNT_BEFORE = 5;
    static CHAR_COUNT_AFTER = 6;
    static CHAR_INSIDE = '...';
    static BAD_DATA_ERROR_CODE = 'BAD_DATA';
    static BAD_VALUE_CODE = '0x';
    static BAD_INFO_CODE_FOR_GET_POOL = 'getPool';
    static BAD_INFO_MESSAGE = 'This is not supportable network';

    static addressShortName(address: WalletAddress | null): string | null {
        if (!address) {
            return address;
        }
        const startAddress = address.substring(0, WalletHelper.CHAR_COUNT_BEFORE);
        const endAddress = address.substring(address.length - WalletHelper.CHAR_COUNT_AFTER);
        return startAddress + WalletHelper.CHAR_INSIDE + endAddress;
    }

    static getNetworkNumber(network: NETWORK) {
        return Number(NETWORK_TO_NETWORK_CHAIN.get(network));
    }

    static messageInCaseOfNotCorrectNetwork(error: ErrorBadRequestBody) {
        const isBadData = error.code === WalletHelper.BAD_DATA_ERROR_CODE;
        const isBadValue = error.value === WalletHelper.BAD_VALUE_CODE;
        const isItWasForGetPoolMethod =
            error.info?.method === WalletHelper.BAD_INFO_CODE_FOR_GET_POOL;
        return isBadData && isBadValue && isItWasForGetPoolMethod
            ? WalletHelper.BAD_INFO_MESSAGE
            : '';
    }
}
