type Payload<P> = P extends undefined ? undefined : P;

export function actionCreator<T>(type: keyof T): { type: keyof T };
export function actionCreator<T, P>(
    type: keyof T,
    payload: Payload<P>,
): { type: keyof T; payload: P };
export function actionCreator<T, P, M>(
    type: keyof T,
    payload: Payload<P>,
    meta: Payload<M>,
): { type: keyof T; payload: P; meta: M };

export function actionCreator<T, P, M>(type: keyof T, payload?: Payload<P>, meta?: Payload<M>) {
    return { type, meta, payload };
}
