import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select as SelectAnt } from 'antd';
import { ReactComponent as ETH } from '@blink/assets/src/icons/ethereum.svg';
import { ReactComponent as BNB } from '@blink/assets/src/icons/bnb.svg';
import { ReactComponent as POLYGON } from '@blink/assets/src/icons/polygon.svg';
import { ReactComponent as ARBITRUM } from '@blink/assets/src/icons/arbitrum.svg';
import { ReactComponent as ALERT } from '@blink/assets/src/icons/alert.svg';
import { ReactComponent as Arrow } from '@blink/assets/src/icons/arrow.svg';
import { ReactComponent as CheckMark } from '@blink/assets/src/icons/checkmark.svg';
import {
    NETWORK,
    NETWORK_READABLE_NAME,
    UNSUPPORTED_NETWORK_ID,
} from '@blink/components/src/constants/global';
import { DefaultOptionType } from 'antd/es/select';

import { Store } from 'src/store';
import { changeNetworkSagaAction } from 'src/store/wallets/actions';
import { useSelectStyles } from './SelectNetwork.style';

interface IProps {
    handleChange?: (value: NETWORK, option?: DefaultOptionType | DefaultOptionType[]) => void;
    handleShowBanner: (value: boolean) => void;
}

const { Option } = SelectAnt;

const getIcon = (param: NETWORK) => {
    switch (param) {
        case NETWORK.ETHEREUM:
            return <ETH />;
        case NETWORK.BNB:
            return <BNB />;
        case NETWORK.POLYGON:
            return <POLYGON />;
        case NETWORK.ARBITRUM:
            return <ARBITRUM />;
        case NETWORK.ARKIS:
            return <ETH />;
        default:
            return <ALERT />;
    }
};

const currData = [
    {
        value: NETWORK.ETHEREUM,
        label: NETWORK_READABLE_NAME.get(NETWORK.ETHEREUM),
    },
    // {
    //     value: NETWORK.BNB,
    //     label: NETWORK_READABLE_NAME.get(NETWORK.BNB),
    // },
    // {
    //     value: NETWORK.POLYGON,
    //     label: NETWORK_READABLE_NAME.get(NETWORK.POLYGON),
    // },
    // {
    //     value: NETWORK.ARBITRUM,
    //     label: NETWORK_READABLE_NAME.get(NETWORK.ARBITRUM),
    // },
    {
        value: UNSUPPORTED_NETWORK_ID,
        label: UNSUPPORTED_NETWORK_ID + ' Network',
    },
];

export const SelectNetwork: React.FC<IProps> = ({ handleShowBanner }) => {
    const dispatch = useDispatch();
    const network: NETWORK = useSelector((state: Store) => state.wallets.network);
    const handleCurrChange = (value: NETWORK) => {
        dispatch(changeNetworkSagaAction(value));
    };

    const [isUnsupportedNetwork, setIsUnsupportedNetwork] = useState<boolean>(false);

    useEffect(() => {
        if (!network) {
            return;
        }

        if (!NETWORK_READABLE_NAME.has(network)) {
            handleShowBanner(true);
            setIsUnsupportedNetwork(true);
        } else {
            handleShowBanner(false);
            setIsUnsupportedNetwork(false);
        }
    }, [network]);

    const classes = useSelectStyles();

    const options = currData;
    const value: any = NETWORK_READABLE_NAME.has(network) ? network : UNSUPPORTED_NETWORK_ID;

    const dropdownContent = (menu: any) => <div className={classes.wrapper}>{menu}</div>;

    const hideUnsupportedElementIfNotSelected = (element: any) => {
        return element === UNSUPPORTED_NETWORK_ID ? classes.hidden : null;
    };

    return (
        <>
            {isUnsupportedNetwork ? (
                <>
                    <SelectAnt
                        value={value}
                        onChange={handleCurrChange}
                        className={classes.select}
                        popupClassName={classes.popup}
                        suffixIcon={<Arrow />}
                        dropdownRender={dropdownContent}
                        defaultOpen={isUnsupportedNetwork}
                    >
                        {options.map((option, idx) => {
                            return (
                                <Option
                                    key={idx}
                                    value={option.value}
                                    label={option.label}
                                    className={hideUnsupportedElementIfNotSelected(option.value)}
                                >
                                    <div className={classes.option}>
                                        {getIcon(option.value as NETWORK)}
                                        <span className={classes.optionLabel}>{option.label}</span>
                                    </div>
                                    <CheckMark />
                                </Option>
                            );
                        })}
                    </SelectAnt>
                </>
            ) : (
                <div className={classes.item}>{getIcon(NETWORK.ETHEREUM as NETWORK)}</div>
            )}
        </>
    );
};
