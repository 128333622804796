import { actionCreator } from '@blink/components/src/utils';

import { GET_ASSETS_TO_SUPPLY, GET_USERS_SUPPLIES, SET_USERS_SUPPLIES } from './constants';
import { UserSupply } from './index';

export const getUsersSupplies = () => actionCreator(GET_USERS_SUPPLIES);

export const setUsersSupplies = (payload: UserSupply[]) =>
    actionCreator(SET_USERS_SUPPLIES, payload);

export const getAssetsToSupplyAction = () => actionCreator(GET_ASSETS_TO_SUPPLY);
