import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';

export const useAssetsToLendRowsStyle = createUseStyles({
    tr: {
        height: 72,
        borderTop: `1px solid ${color.border.opaque}`,
        background: color.background.primary,
        '&:hover': {
            background: color.background.secondary,
        },
    },
    rewardAmount: {
        paddingLeft: '20px',
    },
    subTitle: {
        fontSize: fontSize.sm,
        color: color.content.secondary,
        fontWeight: 600,
        marginLeft: spacing['04'],
    },
    firstTd: {
        paddingLeft: spacing['06'],
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: spacing['05'],
        marginRight: spacing['06'],
    },
    supplyButton: {
        width: 100,
    },
    withdrawButton: {
        width: 100,
    },
    supplyApyGroup: {
        display: 'flex',
        alignItems: 'center',
        color: color.content.secondary,
        '& svg': {
            width: 16,
            height: 16,
            marginLeft: spacing['03'],
        },
    },
    progressWrapper: {
        margin: `${spacing['04']} ${spacing['08']} 0 0`,
        '& .ant-progress-line': {
            marginBottom: 0,
        },
        '& .ant-progress-bg': {
            height: '4px !important',
        },
        '& .ant-progress': {
            position: 'relative',
            '& .ant-progress-inner': {
                backgroundColor: `${color.background.fourth} !important`,
            },
        },
        '& .ant-progress-text': {
            position: 'absolute',
            top: -14,
            visibility: 'hidden',
        },
        '& .ant-progress-outer': {
            paddingInlineEnd: '0 !important',
        },
    },
    detailsButton: {
        width: 100,
    },
    tooltipContentRow: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: fontSize.sm,
        fontWeight: 400,
    },
});
