import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import App from '../App';
import { Leverage } from '../pages/Leverage';
import { WalletConnect } from '../pages/WalletConnect';
import { Borrow } from '../pages/Borrow';
import { BorrowDetails } from '../pages/BorrowDetails';
import { Pool } from 'src/pages/Pool';
import { ProtectedRoute } from './ProtectedRoute';

const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        children: [
            {
                path: '/',
                element: <Leverage />,
            },
            {
                path: '/borrow',
                element: (
                    <ProtectedRoute>
                        <Borrow />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/borrow/:address',
                element: (
                    <ProtectedRoute>
                        <BorrowDetails />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/wallet-connect',
                element: <WalletConnect />,
            },
            {
                path: '/pool/:address',
                element: (
                    <ProtectedRoute>
                        <Pool />
                    </ProtectedRoute>
                ),
            },
        ],
    },
]);

export default router;
