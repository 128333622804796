import { actionCreator } from '@blink/components/src/utils';

import { GET_AGREEMENTS, SET_AGREEMENTS, SET_AGREEMENTSLOADING_FALSE } from './constants';
import { Agreement } from 'src/api/borrow';

export const getAgreementsAction = () => actionCreator(GET_AGREEMENTS);

export const setAgreementsAction = (payload: Agreement[]) => actionCreator(SET_AGREEMENTS, payload);

export const setAgreementsLeadingFalseAction = () => actionCreator(SET_AGREEMENTSLOADING_FALSE);
