import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';

export const useFooterStyle = createUseStyles({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 'auto',
        padding: '32px 10%',
        background: color.background.primary,
        fontSize: fontSize.md,
        color: color.content.secondary,
        borderTop: `1px solid ${color.border.opaque}`,
    },
    links: {
        display: 'flex',
        gap: spacing['07'],
        '& > a': {
            color: `${color.content.secondary} !important`,
        },
    },
});
