import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { message } from 'antd';

import { Store } from 'src/store';
export const ErrorMessage = () => {
    const errors = useSelector((state: Store) => state.errors);

    useEffect(() => {
        Object.keys(errors).forEach((key) => {
            if (errors[key] !== '') {
                message.error(errors[key]);
            }
        });
    }, [errors]);

    return null;
};
