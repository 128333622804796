import React, { FC } from 'react';
import { Button, ButtonProps } from 'antd';

import { useTextButtonStyles } from './TextButton.style';

type IconButtonProps = ButtonProps;

export const TextButton: FC<IconButtonProps> = ({ ...props }) => {
    const classes = useTextButtonStyles();

    return <Button type='text' {...props} className={`${classes.root} ${props.className}`} />;
};
