import React, { FC } from 'react';
import { ReactComponent as SuccessIcon } from '@blink/assets/src/icons/success.svg';
import { ReactComponent as PendingIcon } from '@blink/assets/src/icons/pending.svg';
import { ReactComponent as ErrorIcon } from '@blink/assets/src/icons/error.svg';
import { ButtonStroke } from '@blink/components';
import { ButtonSize } from '@blink/components/src/Button/Button.types';
import { useDispatch, useSelector } from 'react-redux';

import { useOperationStatusModalStyle } from './OperationStatusModal.style';
import { Store } from '../../store';
import { hideModalAction, showModalAction } from '../../store/modals/actions';

type OperationStatusModalProps = {
    type: OperationStatusType;
    description: string;

    onClick?: () => void;
};

export enum OperationStatusType {
    Success = 'Success',
    Pending = 'Pending',
    Error = 'Error',
}

const propsMap = new Map()
    .set(OperationStatusType.Success, {
        icon: <SuccessIcon />,
        title: 'Success!',
        buttonName: 'Got it',
    })
    .set(OperationStatusType.Pending, {
        icon: <PendingIcon />,
        title: 'In Progress...',
        buttonName: 'Pending',
    })
    .set(OperationStatusType.Error, {
        icon: <ErrorIcon />,
        title: 'Oops, your request was declined...',
        buttonName: 'Try again',
    });

export const OperationStatusModal: FC = () => {
    const classes = useOperationStatusModalStyle();
    const dispatch = useDispatch();
    const { description, type, previousModal } = useSelector((state: Store) => ({
        ...(state.modals.modalProps as OperationStatusModalProps),
        previousModal: state.modals.previousModal,
    }));
    const { icon, title, buttonName } = propsMap.get(type) || {};

    const onClick = () => {
        if (type === OperationStatusType.Success) {
            dispatch(hideModalAction());

            return;
        }

        if (type === OperationStatusType.Error) {
            dispatch(
                showModalAction({
                    modalName: previousModal?.activeModal,
                    ...previousModal,
                    previousModal: null,
                }),
            );
        }
    };

    return (
        <div className={classes.root}>
            <div>{icon}</div>
            <div className={classes.title}>{title}</div>
            <div className={classes.description}>{description}</div>
            <ButtonStroke
                className={classes.button}
                size={ButtonSize.xl}
                isSecondary={true}
                onClick={onClick}
            >
                {buttonName}
            </ButtonStroke>
        </div>
    );
};
