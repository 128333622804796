import { color, spacing } from '@blink/components';
import { createUseStyles } from 'react-jss';

export const useStatusStyle = createUseStyles({
    root: {
        display: 'flex',
        alignItems: 'center',

        '& > .ant-spin': {
            marginRight: spacing['02'],
        },

        '& > svg': {
            marginRight: spacing['02'],
        },
    },
    error: {
        '& > svg': {
            marginRight: spacing['02'],
            width: '16px',
            height: '16px',
            '& > path': {
                fill: color.border.negative,
            },
        },
    },
});
