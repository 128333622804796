import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';

export const useLiquidityAssetInputStyle = createUseStyles({
    root: {
        background: color.background.secondary,
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${color.border.opaque}`,
        borderRadius: 4,
        padding: `${spacing['04']} ${spacing['05']}`,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        color: color.content.secondary,
    },
    balanceWrapper: {
        display: 'flex',
    },
    balanceName: {
        color: color.content.primary,
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    balance: {
        marginLeft: spacing['02'],
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: spacing['03'],
    },
    assetName: {
        fontSize: fontSize.xl,
    },
    input: {
        width: '100%',
        margin: `0 0 0 ${spacing['04']}`,
        '& .ant-input-number': {
            width: '100%',
            fontSize: fontSize.xl,
            borderColor: 'transparent',
            backgroundColor: 'inherit',
            textAlign: 'end',
            lineHeight: 0.5,
        },
        '& .ant-input-number:hover': {
            borderColor: 'transparent',
        },
        '& .ant-input-number-focused': {
            boxShadow: 'none',
        },
        '& input': {
            textAlign: 'end !important',
        },
    },
});
