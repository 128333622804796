import { walletMap } from '@blink/components/src/constants/wallet';
import { EthereumWallet } from '@blink/components/src/utils';
import {
    EIP6963AnnounceProviderEvent,
    EIP6963ProviderDetail,
    EIP1193Provider,
} from '@blink/components/src/utils/wallet/types';

declare global {
    interface WindowEventMap {
        'eip6963:announceProvider': CustomEvent<EIP6963AnnounceProviderEvent>;
    }
}

let providers: EIP6963ProviderDetail[] = [];

class EIP6963Wallet extends EthereumWallet {
    constructor(private provider: EIP1193Provider) {
        super();
    }

    public isWalletInProvider(): boolean {
        return true;
    }

    public async getProvider(): Promise<any> {
        return this.provider;
    }

    public isAvailable(): boolean {
        return true;
    }
}

export const walletStore = {
    value: () => providers,

    subscribe: (callback: () => void) => {
        function onAnnouncement(event: EIP6963AnnounceProviderEvent) {
            walletMap.set(event.detail.info.name as any, new EIP6963Wallet(event.detail.provider));
            providers = [...providers, event.detail];
            callback();
        }

        window.addEventListener(
            'eip6963:announceProvider',
            onAnnouncement as unknown as EventListener,
        );
        window.dispatchEvent(new Event('eip6963:requestProvider'));

        return () =>
            window.removeEventListener(
                'eip6963:announceProvider',
                onAnnouncement as unknown as EventListener,
            );
    },
};
