import { createUseStyles } from 'react-jss';
import { color, fontSize, fontWeight, lineHeight, spacing } from '@blink/components';

export const useModalStyles = createUseStyles({
    wrapper: {
        minWidth: '456px',
    },
    icon: {
        display: 'flex',
        margin: `0px auto ${spacing['07']}`,
        width: '100px',
        height: '100px',
        '& svg': {
            width: '100%',
            height: '100%',
        },
    },
    title: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        fontSize: fontSize.xxl,
        fontWeight: fontWeight.medium,
    },
    description: {
        justifyContent: 'center',
        lineHeight: lineHeight.sm,
        marginBottom: spacing['07'],
        display: 'flex',
        textAlign: 'center',
        width: '100%',
        fontSize: fontSize.lg,
        color: color.content.secondary,
    },
    btn: {
        margin: `${spacing['05']} 0px ${spacing['06']}`,
        fontSize: fontSize.lg,
    },
});
