import { createUseStyles } from 'react-jss';
import { color, spacing } from '@blink/components';

export const useSelectableCardStyle = createUseStyles({
    root: {
        background: color.background.secondary,
        border: `1px solid ${color.border.opaque}`,
        borderRadius: 4,
        padding: `${spacing['05']} ${spacing['04']}`,
        cursor: 'pointer',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        '&:hover': {
            border: `1px solid ${color.border.accent}`,
            '& #border': {
                fill: `${color.border.accent} !important`,
                fillOpacity: `1 !important`,
            },
        },
    },
    rootSelected: {
        border: `1px solid ${color.border.accent}`,
    },
    label: {
        marginTop: spacing['04'],
        fontWeight: 400,
    },
});
