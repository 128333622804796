import React, { FC } from 'react';
import { AssetWithIcon, ButtonStroke } from '@blink/components';
import { Progress } from '@blink/components/src/Progress/Progress';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonSize } from '@blink/components/src/Button/Button.types';
import { shortFormatNumber } from '@blink/components/src/utils';
import { useNavigate } from 'react-router';

import { useAssetsToBorrowRowsStyle } from './AssetsToBorrowItemRow.style';
import { FixedRateMenuKey } from '../../FixedRateMenu/constants';
import { Store } from 'src/store';
import { Agreement } from 'src/api/borrow';
import { showModalAction } from 'src/store/modals/actions';
import { ModalName } from 'src/store/modals/constants';

type AssetsToBorrowRowsProps = {
    allItems: Agreement[];
    itemIndex: number;
    selectedRate: FixedRateMenuKey;
};

export const AssetsToBorrowItemRow: FC<AssetsToBorrowRowsProps> = ({ itemIndex, allItems }) => {
    const classes = useAssetsToBorrowRowsStyle();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const agreement = allItems[itemIndex];
    const { address, title, leverage, apy, totalDeposited, thresholdOnTotalDeposit } = agreement;

    const totalBorrow = shortFormatNumber({ amount: Number(thresholdOnTotalDeposit) });
    const walletAddress = useSelector((state: Store) => state.wallets.active.address);

    const handleDetails = () => {
        navigate(`/pool/${address}?role=borrower`);
        return;
    };

    const handleBorrowClick = () => {
        dispatch(
            showModalAction({
                modalName: ModalName.BORROW_MODAL,
                modalTitle: `Borrow ${leverage.symbol}`,
                modalProps: {
                    tokenSymbol: leverage.symbol,
                    agreementAddress: address,
                    tokenAddress: leverage.address,
                },
            }),
        );

        return;
    };

    const borrowProgress = Math.round(
        (Number(totalDeposited) / Number(thresholdOnTotalDeposit)) * 100,
    );

    return (
        <>
            <tr className={classes.tr}>
                <td className={classes.firstTd}>{title}</td>
                <td>
                    <AssetWithIcon assetName={leverage.symbol} />
                </td>
                <td>
                    <div className={classes.progressWrapper}>
                        <div>{`${totalBorrow} ${leverage.symbol}`}</div>
                        <Progress percent={borrowProgress} />
                    </div>
                </td>
                <td>
                    <div>{apy}%</div>
                </td>
                <td>
                    <div>{`${leverage.balance} ${leverage.symbol}`}</div>
                </td>
                <td>
                    <div className={classes.buttonWrapper}>
                        <ButtonStroke
                            isPrimary
                            size={ButtonSize.md}
                            className={classes.borrowButton}
                            onClick={handleBorrowClick}
                            disabled={!walletAddress}
                        >
                            {'Borrow'}
                        </ButtonStroke>
                        <ButtonStroke
                            size={ButtonSize.md}
                            className={classes.detailsButton}
                            onClick={handleDetails}
                        >
                            Details
                        </ButtonStroke>
                    </div>
                </td>
            </tr>
        </>
    );
};
