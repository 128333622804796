import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';

export const useWalletConnectStatus = createUseStyles({
    root: {
        color: color.content.primary,
        textDecoration: 'underline',
    },
    closeButton: {
        background: 'transparent',
        border: 0,
        width: '17px',
        height: '17px',
    },
    closeIcon: {
        width: '17px',
        height: '17px',
        cursor: 'pointer',
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: `${spacing['07']} ${spacing['07']} 0 ${spacing['07']}`,
    },
    holder: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 44,
        height: 44,
        padding: spacing['03'],
    },
    title: {
        color: color.content.primary,
        textAlign: 'center',
        fontSize: fontSize.xl,
        lineHeight: '32px',
        letterSpacing: '-0.42px',
    },
    tooltip: {
        '& .ant-tooltip-inner': {
            color: color.content.primary,
        },
    },
});
