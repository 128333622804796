import React from 'react';
import { Button as ButtonAnt, ButtonProps } from 'antd';

import { useBtnStyles } from './Button.style';

export const Button: React.FC<ButtonProps> = ({
    type = 'primary',
    className,
    name,
    children,
    ...props
}) => {
    const classes = useBtnStyles();

    return (
        <ButtonAnt type={type} className={`${classes.btn} ${className}`} {...props}>
            {name}
            {children}
        </ButtonAnt>
    );
};
