import React, { FC } from 'react';
import { AssetWithIcon, ButtonStroke } from '@blink/components';
import { useDispatch } from 'react-redux';
import { ButtonSize } from '@blink/components/src/Button/Button.types';

import { useUserSuppliesRowsStyle } from './UserSuppliesRows.style';
import { showModalAction } from '../../../store/modals/actions';
import { ModalName } from '../../../store/modals/constants';
import { UserSupply } from 'src/store/supplies';

type UserSuppliesRowsProps = {
    items: UserSupply[];
};

export const UserSuppliesRows: FC<UserSuppliesRowsProps> = ({ items }) => {
    const classes = useUserSuppliesRowsStyle();
    const dispatch = useDispatch();

    if (!items) {
        return null;
    }

    return (
        <>
            {items.map((item, index) => {
                const handleWithdraw = () => {
                    dispatch(
                        showModalAction({
                            modalName: ModalName.LIQUIDITY_MODAL,
                            modalTitle: 'Withdraw Liquidity',
                            modalProps: {
                                balance: item.balance,
                                currentApy: Number(item.apy),
                                totalDeposited: item.totalDeposited,
                                thresholdOnTotalDeposit: item.thresholdOnTotalDeposit,
                                isWithdraw: true,
                                agreementAddress: item.agreementAddress,
                                tokenAddress: item.tokenAddress,
                                tokenSymbol: item.tokenSymbol,
                                tokenDecimals: item.tokenDecimals,
                            },
                        }),
                    );
                };
                return (
                    <tr key={index} className={classes.tr}>
                        <td className={classes.firstTd}>{item.agreementTitle}</td>
                        <td>
                            <AssetWithIcon assetName={item.tokenSymbol || 'DAI'} />
                        </td>
                        <td>
                            <div>{item.balance}</div>
                            {/* <div className={classes.secondary}>$ {item.balance.value_in_usdt}</div> */}
                        </td>
                        <td>{item.apy}%</td>
                        <td>
                            <div className={classes.rewards}>
                                <div>
                                    {item.reward} {item.tokenSymbol}
                                </div>
                            </div>
                        </td>
                        <td className={classes.buttonTd}>
                            <ButtonStroke
                                size={ButtonSize.md}
                                className={classes.button}
                                isPrimary
                                onClick={handleWithdraw}
                            >
                                Withdraw
                            </ButtonStroke>
                        </td>
                    </tr>
                );
            })}
        </>
    );
};
