import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';

export const useBorrowDetailsCloseCredit = createUseStyles({
    title: {
        display: 'flex',
        color: color.content.primary,
        gap: spacing['05'],
        width: 'fit-content',
        fontSize: fontSize.xl,
        marginBottom: spacing['07'],
        '& svg': {
            transform: 'rotate(90deg)',
            width: 16,
            height: 16,
            '& path': {
                fill: color.content.primary,
            },
        },
    },
    tr: {
        borderBottom: `1px solid ${color.border.opaque}`,
        height: 50,
        '& > th': {
            fontWeight: 'normal',
            textAlign: 'left',
            color: color.content.primary,
            width: '30%',

            '&:first-child': {
                width: '10%',
            },
        },

        '& > td:last-child, th:last-child': {
            paddingRight: 14,
        },
        '& > th:first-child, td:first-child': {
            paddingLeft: 14,
        },
    },
    assetIcon: {
        width: 16,
        height: 16,
    },
    checkbox: {
        marginTop: 6,
    },
    swapToETH: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${spacing['04']} ${spacing['03']} ${spacing['05']}`,
        marginTop: spacing['06'],
        borderBottom: `1px solid ${color.border.opaque}`,
    },
    totalDebt: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${spacing['05']} ${spacing['03']} ${spacing['08']} ${spacing['03']}`,
        borderBottom: `1px solid ${color.border.opaque}`,
        width: '100%',
    },
    listLeft: {
        display: 'flex',
        flexDirection: 'column',
        gap: spacing['02'],
    },
    listText: {
        color: color.content.secondary,
    },
    button: {
        width: '100%',
        marginTop: spacing['07'],
        fontSize: fontSize.lg,
        fontWeight: '600',
    },
    spin: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
    },
});
