import React, { FC, useState } from 'react';
import { Button, ButtonStroke } from '@blink/components';
import { ReactComponent as CloseIcon } from '@blink/assets/src/icons/close.svg';
import { ButtonSize } from '@blink/components/src/Button/Button.types';
import { MINIMAL_RISK_FACTOR_VALUE } from '@blink/components/src/constants/global';

import { BorrowDetailState } from '../../../../pages/BorrowDetails';
import { AssetInput } from '../../../AssetInput';
import { useBorrowDetailsBalanceManagementStyles } from './BorrowDetailsBalanceManagement.style';
import { CollateralHealthCard } from '../../../Modals/BorrowModal/CollateralAsset/CollateralHealthCard';

type BorrowDetailsBalanceManagementProps = {
    pageState: BorrowDetailState;
    assetName: string;
    setPageState: (value: BorrowDetailState) => void;
};

// TODO: add API usages for this component
export const BorrowDetailsBalanceManagement: FC<BorrowDetailsBalanceManagementProps> = ({
    assetName,
    pageState,
    setPageState,
}) => {
    const classes = useBorrowDetailsBalanceManagementStyles();
    const [value, setValue] = useState(0);
    const noCollateralAssets = false;
    const health = value ? Math.random() * 5 : null;

    const title = pageState === BorrowDetailState.BORROW_MORE ? 'Borrow More' : 'Decrease Debt';
    const inputTitle =
        pageState === BorrowDetailState.BORROW_MORE ? 'You Borrow' : 'Repayment Amount';
    const buttonText = BorrowDetailState.BORROW_MORE ? 'Confirm Borrowing' : 'Confirm Repayment';

    const handleSubmit = () => {
        // TODO: add API usage for submit action
        setPageState(BorrowDetailState.DEFAULT);
    };

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <div>{title}</div>
                <Button
                    className={classes.closeButton}
                    type='text'
                    onClick={() => setPageState(BorrowDetailState.DEFAULT)}
                >
                    <CloseIcon />
                </Button>
            </div>
            <AssetInput
                assetName={assetName}
                balance={5}
                value={value}
                setValue={setValue}
                customTitle={inputTitle}
                customBalanceLabel={'Available'}
            />
            <div className={classes.healthCard}>
                <CollateralHealthCard health={health} />
            </div>
            <div className={classes.submitButtonWrapper}>
                <ButtonStroke
                    isSecondary={true}
                    size={ButtonSize.xl}
                    onClick={handleSubmit}
                    disabled={
                        noCollateralAssets ||
                        !value ||
                        !health ||
                        health < MINIMAL_RISK_FACTOR_VALUE
                    }
                >
                    {buttonText}
                </ButtonStroke>
            </div>
        </div>
    );
};
