import { createUseStyles } from 'react-jss';

import { color, fontSize, spacing } from '../variables';

export const useTipCardStyle = createUseStyles({
    root: {
        display: 'flex',
        border: `1px solid ${color.border.opaque}`,
        borderRadius: 4,
        padding: spacing['05'],
        gap: spacing['03'],
        '& svg': {
            width: 31,
        },
    },
    header: {
        display: 'flex',
    },
    content: {
        color: color.content.secondary,
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: -0.308,
    },
    title: {
        fontSize: fontSize.lg,
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: -0.34,
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        gap: spacing['03'],
    },
});
