import { objectToCamel } from 'ts-case-convert';

import * as actions from './actions';
import {
    SET_USER_BORROWS_REGISTERED,
    SET_USER_BORROWS_SUSPENDED,
    SET_USER_BORROWS_CLOSED,
    SET_USER_BORROWS_MAINTENANCE,
    SET_USER_BORROWS_MAINTENANCE_HISTORY,
    GET_USER_BORROWS_SUSPENDED,
    GET_USER_BORROWS_REGISTERED,
    GET_USER_BORROWS_CLOSED,
    GET_USER_BORROWS_OPENED,
    SET_USER_BORROWS_OPENED,
} from './constants';
import { UserBorrow } from 'src/api/borrow';

export type BorrowState = {
    userBorrowsOpened: UserBorrow[];
    userBorrowsOpenedLoading: boolean;
    userBorrowsSuspended: UserBorrow[];
    userBorrowsSuspendedLoading: boolean;
    userBorrowsRegistered: UserBorrow[];
    userBorrowsRegisteredLoading: boolean;
    userBorrowsClosed: UserBorrow[];
    userBorrowsClosedLoading: boolean;
};

const defaultState: BorrowState = {
    userBorrowsOpenedLoading: false,
    userBorrowsSuspendedLoading: false,
    userBorrowsClosedLoading: false,
    userBorrowsRegisteredLoading: false,
    userBorrowsOpened: [],
    userBorrowsSuspended: [],
    userBorrowsRegistered: [],
    userBorrowsClosed: [],
};

type BorrowAction = ReturnType<TInferValueTypes<typeof actions>>;

export default function borrows(state = defaultState, action: BorrowAction): BorrowState {
    switch (action.type) {
        case GET_USER_BORROWS_OPENED: {
            return {
                ...state,
                userBorrowsOpenedLoading: true,
            };
        }
        case SET_USER_BORROWS_OPENED: {
            const updatedUserBorrows = action.payload.map((newBorrow) => {
                const existingBorrow = state.userBorrowsOpened.find(
                    (borrow) => borrow.address === newBorrow.address,
                );
                if (existingBorrow) {
                    return {
                        ...existingBorrow,
                        apy: newBorrow.apy,
                        leverage: {
                            ...existingBorrow.leverage,
                            ...newBorrow.leverage,
                        },
                        opened_at: newBorrow.openedAt || existingBorrow.openedAt,
                    };
                }
                return newBorrow;
            });

            return {
                ...state,
                userBorrowsOpenedLoading: false,
                userBorrowsOpened: updatedUserBorrows,
            };
        }
        case GET_USER_BORROWS_SUSPENDED: {
            return {
                ...state,
                userBorrowsSuspendedLoading: true,
            };
        }
        case SET_USER_BORROWS_SUSPENDED: {
            return {
                ...state,
                userBorrowsSuspendedLoading: false,
                userBorrowsSuspended: action.payload,
            };
        }
        case GET_USER_BORROWS_REGISTERED: {
            return {
                ...state,
                userBorrowsRegisteredLoading: true,
            };
        }
        case SET_USER_BORROWS_REGISTERED: {
            return {
                ...state,
                userBorrowsRegisteredLoading: false,
                userBorrowsRegistered: action.payload,
            };
        }
        case GET_USER_BORROWS_CLOSED: {
            return {
                ...state,
                userBorrowsClosedLoading: true,
            };
        }
        case SET_USER_BORROWS_CLOSED: {
            return {
                ...state,
                userBorrowsClosedLoading: false,
                userBorrowsClosed: action.payload,
            };
        }
        case SET_USER_BORROWS_MAINTENANCE: {
            const address = action.payload.address;

            return {
                ...state,
                userBorrowsOpened: state.userBorrowsOpened.map((item) => {
                    if (item.address === address) {
                        return {
                            ...item,
                            maintenance: objectToCamel(action.payload),
                        };
                    }

                    return { ...item };
                }),
            };
        }
        case SET_USER_BORROWS_MAINTENANCE_HISTORY: {
            return {
                ...state,
                userBorrowsOpened: state.userBorrowsOpened.map((borrow) => {
                    const updatedHistory = objectToCamel(action.payload).filter(
                        (item) => item.data.address === borrow.address,
                    );
                    if (updatedHistory) {
                        return {
                            ...borrow,
                            history: [
                                ...(borrow.history
                                    ? borrow.history.length >= 100
                                        ? borrow.history.slice(1)
                                        : borrow.history
                                    : []),
                                ...updatedHistory,
                            ],
                        };
                    }
                    return borrow;
                }),
            };
        }

        default: {
            return state;
        }
    }
}
