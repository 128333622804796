import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CopyIcon } from '@blink/assets/src/icons/copy.svg';
import { ButtonStroke, TextButton, ViewOnEtherscanLink } from '@blink/components';
import { copyToClipboard, WalletHelper } from '@blink/components/src/utils';
import { message } from 'antd';
import { ButtonSize } from '@blink/components/src/Button/Button.types';

import { Store } from '../../../store';
import { disconnectWalletSaga } from '../../../store/wallets/actions';
import { useWalletInfoModalStyle } from './WalletInfoModal.style';
import { hideModalAction, showModalAction } from '../../../store/modals/actions';
import { ModalName } from '../../../store/modals/constants';
import { WalletIcon } from '../../WalletIcon/WalletIcon';

export const WalletInfoModal: FC = () => {
    const classes = useWalletInfoModalStyle();
    const { address, type } = useSelector((state: Store) => ({
        address: state.wallets.active?.address,
        type: state.wallets.active?.type,
    }));
    const dispatch = useDispatch();

    const handleCopyAddress = () => {
        copyToClipboard(address);
        message.success('Copied to clipboard!');
    };

    const handleDisconnectWallet = () => {
        dispatch(disconnectWalletSaga());
        dispatch(hideModalAction());
    };

    const handleChangeWallet = () =>
        dispatch(showModalAction({ modalName: ModalName.CONNECT_WALLET_MODAL }));

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.walletNameWrapper}>
                    <WalletIcon walletType={type} />

                    <div className={classes.walletName}>
                        {WalletHelper.addressShortName(address)}
                    </div>
                </div>
                <div className={classes.buttonWrapper}>
                    <ButtonStroke
                        isSecondary={true}
                        size={ButtonSize.sm}
                        onClick={handleChangeWallet}
                        className={`${classes.button} ${classes.fillBtn}`}
                    >
                        Change
                    </ButtonStroke>
                    <ButtonStroke
                        size={ButtonSize.sm}
                        onClick={handleDisconnectWallet}
                        className={classes.button}
                        isOutline
                    >
                        Disconnect
                    </ButtonStroke>
                </div>
            </div>
            <div className={classes.address} />
            <div className={classes.footer}>
                <TextButton onClick={handleCopyAddress} className={classes.textButton}>
                    <CopyIcon />
                    <div>Copy Address</div>
                </TextButton>
                <ViewOnEtherscanLink address={address} />
            </div>
        </div>
    );
};
