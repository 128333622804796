import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { hideModalAction, showModalAction } from '../../store/modals/actions';
import { Store } from '../../store';

export const useModal = () => {
    return {
        ...useSelector((state: Store) => ({
            modalName: state.modals.activeModal,
            modalTitle: state.modals.modalTitle,
            modalProps: state.modals.modalProps,
            previousModal: state.modals.previousModal,
        })),
    };
};

export const useHideModal = () => {
    const dispatch = useDispatch();
    const { previousModal } = useSelector((state: Store) => ({
        previousModal: state.modals.previousModal,
    }));

    const handleCloseModal = useCallback(() => {
        if (previousModal) {
            dispatch(
                showModalAction({
                    modalName: previousModal.activeModal,
                    modalTitle: previousModal.modalTitle,
                    modalProps: previousModal.modalProps,
                    previousModal: null,
                }),
            );

            return;
        }
        dispatch(hideModalAction());
    }, [dispatch]);
    return {
        handleCloseModal,
    };
};
