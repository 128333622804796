import React, { FC, useContext, useEffect, useState } from 'react';
import { ButtonStroke } from '@blink/components';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import { AbstractWallet, ETHEREUM_METHOD, WalletHelper } from '@blink/components/src/utils';
import Layout from 'antd/es/layout/layout';
import { MINIMAL_RISK_FACTOR_VALUE } from '@blink/components/src/constants/global';
import { Contract, ethers, parseUnits } from 'ethers';
import { walletMap } from '@blink/components/src/constants/wallet';
import IERCAbi from '@blink/leverage/src/abi/IERCAbi.json';

import { Store } from '../../../store';
import { useBorrowStyle } from './BorrowModal.style';
import { useModal } from '../ModalWrapperHooks';
import { AssetInputSupply } from '../../AssetInputSupply';
import { showModalAction } from '../../../store/modals/actions';
import { ModalName } from '../../../store/modals/constants';
import { getAssetsToSupplyAction } from '../../../store/supplies/actions';
import { CentrifugeContext } from 'src/context/centrifuge';
import { ROLE, Token } from 'src/api/borrow';
import { InfoCard } from 'src/components/InfoCard';
import { CollateralAssetsSection } from './CollateralAsset/CollateralAssetsSection';
import { CollateralPendingActions } from './CollateralAsset/CollateralPendingActions';
import { CollateralHealthCard } from './CollateralAsset/CollateralHealthCard';

export type SelectedBorrowAsset = {
    symbol: string;
    amount: string;
    amountInAssetToBorrow: string;
    address: string;
    name: string;
    decimals: number;
};

export const BorrowModal: FC = () => {
    const centrifuge = useContext(CentrifugeContext);
    const classes = useBorrowStyle();
    const dispatch = useDispatch();
    const { modalProps } = useModal();
    const { tokenSymbol, agreementAddress } = modalProps || {};

    const { type, blockchainName, walletAddress, tokens, agreements } = useSelector(
        (state: Store) => ({
            blockchainName: state.wallets.network,
            walletAddress: state.wallets.active.address,
            type: state.wallets.active.type,
            tokens: state.tokens.tokens,
            agreements: state.agreements.agreements,
        }),
    );

    useEffect(() => {
        dispatch(getAssetsToSupplyAction());
    }, [type, blockchainName, walletAddress]);

    const agreement = agreements.filter(
        (agreement) => agreement.address === agreementAddress && agreement.role === ROLE.BORROWER,
    )[0];
    const assetsToCollateralAddresses = agreement.collaterals;
    const assetsToCollateral = tokens.filter((token) =>
        assetsToCollateralAddresses.includes(token.address),
    );

    const [maxBorrow, setMaxBorrow] = useState('');
    const [maxBorrowLoading, setMaxBorrowLoading] = useState(true);

    const [collateralAssets, setCollateralAssets] = useState(assetsToCollateralAddresses);

    const getSortedCollaterals = (assetsToCollateral: Token[]) => {
        const sortedCollateralAssets = () =>
            assetsToCollateral.sort((collateralAssets1, collateralAssets2) => {
                return Number(collateralAssets2.balance) - Number(collateralAssets1.balance);
            });
        setCollateralAssets(sortedCollateralAssets);
    };

    useEffect(() => {
        getSortedCollaterals(assetsToCollateral);
    }, []);

    const { assetAddress, assetSymbol, borrowApy, assetFullName, decimals } = {
        assetAddress: agreement.leverage.address,
        assetSymbol: tokenSymbol,
        borrowApy: agreement.apy,
        assetFullName: agreement.leverage.name,
        decimals: agreement.leverage.decimals,
    };

    const [assetAmount, setAssetAmount] = useState<string>();
    const [selectedAssets, setSelectedAssets] = useState<SelectedBorrowAsset[]>([]);
    const [health, setHealth] = useState<number | null>(null);

    useEffect(() => {
        if (!assetAddress) {
            return;
        }
        (async () => {
            const wallet: AbstractWallet = walletMap.get(type) as AbstractWallet;
            const walletProvider = await wallet.getProvider();

            await walletProvider.request({ method: ETHEREUM_METHOD.REQUEST });

            const provider = new ethers.BrowserProvider(
                walletProvider,
                WalletHelper.getNetworkNumber(blockchainName),
            );

            const signer = await provider.getSigner();

            const token = new Contract(agreement.leverage.address as string, IERCAbi, signer);
            const tokenBalanceOf = await token.balanceOf(agreement.address);
            const decimals = await token.decimals();
            const tokenBalance = ethers.formatUnits(tokenBalanceOf, decimals);
            setMaxBorrow(tokenBalance.toString());
            setMaxBorrowLoading(false);
        })();
    }, [assetAddress]);

    const handleAssetAmountChange = (value: string) => {
        setAssetAmount(value);
        setSelectedAssets([]);
        setHealth(null);
    };

    const handleCollateralAssetSelect = async (selectedAssets: SelectedBorrowAsset[]) => {
        const leverageDecimals = assetsToCollateral.find(
            (asset: { symbol: string }) => asset.symbol === tokenSymbol,
        )!.decimals;
        setSelectedAssets(selectedAssets);
        setHealth(null);

        const borrowAmount = assetAmount?.toString() || '0';

        const collateral = selectedAssets.map((item) => {
            return {
                name: item.name,
                amount: parseUnits(item.amount.toString(), item.decimals).toString(),
                address: item.address,
                symbol: item.symbol,
                decimals: item.decimals,
            };
        });

        if (collateral.length === 0) {
            return;
        }

        const params = {
            leverage: {
                address: assetAddress,
                name: assetFullName,
                symbol: assetSymbol,
                decimals: leverageDecimals,
                amount: parseUnits(borrowAmount, leverageDecimals).toString(),
            },
            collateral,
            agreement_address: agreementAddress,
        };

        centrifuge?.rpc('estimate_risk_factor', params).then(
            function (res: { data: { result: { risk_factor: string } } }) {
                setHealth(Number(res.data.result.risk_factor));
            },
            function (err: string) {
                message.error(err);
            },
        );
    };

    const getRegisterModalTitle = () => {
        return selectedAssets.length && selectedAssets.length > 1
            ? selectedAssets.length + ' Pending Actions'
            : '1 Pending Action';
    };

    const handleSubmit = async () => {
        dispatch(
            showModalAction({
                modalName: ModalName.REGISTER_MARGIN_ACCOUNT_MODAL,
                modalTitle: getRegisterModalTitle(),
                modalProps: {
                    agreementAddress: agreement.address,
                    selectedAssets,
                    assetSymbol,
                    assetAmount,
                    assetAddress,
                },
            }),
        );
        return;
    };

    const isAssesAvailable = Number(maxBorrow) !== 0;

    const noCollateralAssets = !collateralAssets.some(
        (item: { balance: any }) => Number(item.balance) > 0,
    );

    const getContent = () => {
        return (
            <>
                <div className={classes.left}>
                    <div className={classes.text}>How much do you want to borrow?</div>
                    <AssetInputSupply
                        tokenSymbol={assetSymbol}
                        balance={maxBorrow}
                        value={assetAmount}
                        setValue={handleAssetAmountChange}
                        disabled={noCollateralAssets || Number(maxBorrow) === 0}
                        isSupply={false}
                        borrowApy={borrowApy}
                        decimals={decimals}
                    />
                    {!isAssesAvailable && !maxBorrowLoading && (
                        <InfoCard
                            type='alert'
                            text='No assets available to borrow.'
                            classes={{ root: classes.noCollateralAlert }}
                        />
                    )}
                    {noCollateralAssets && (
                        <InfoCard
                            type='alert'
                            text='No collateralized assets found. To continue borrowing, please make sure to deposit enough collateral into your account.'
                            classes={{ root: classes.noCollateralAlert }}
                        />
                    )}
                    {!noCollateralAssets && (
                        <CollateralAssetsSection
                            disabled={!assetAmount || !isAssesAvailable}
                            assets={collateralAssets}
                            assetName={assetSymbol}
                            selectedAssets={selectedAssets}
                            setSelectedAssets={handleCollateralAssetSelect}
                            assetAmount={assetAmount}
                            assetSymbol={assetSymbol}
                            classes={{
                                root:
                                    assetAmount && isAssesAvailable
                                        ? classes.collateralAssetRoot
                                        : classes.collateralAssetNotAllowed,
                            }}
                        />
                    )}
                </div>
                <div className={classes.right}>
                    {selectedAssets.length > 0 && (
                        <div className={classes.rightCardWrapperNoSidePadding}>
                            <CollateralPendingActions
                                selectedAssets={selectedAssets}
                                setSelectedAssets={handleCollateralAssetSelect}
                            />
                        </div>
                    )}

                    <div className={classes.rightCardWrapper}>
                        <CollateralHealthCard health={health} />
                    </div>
                    <div className={classes.btnWrapper}>
                        <ButtonStroke
                            className={classes.submitButton}
                            isPrimary
                            onClick={handleSubmit}
                            disabled={
                                Number(maxBorrow) === 0 ||
                                !selectedAssets.length ||
                                noCollateralAssets ||
                                !health ||
                                health < MINIMAL_RISK_FACTOR_VALUE
                            }
                        >
                            Take Leverage
                        </ButtonStroke>
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className={classes.wrapper}>
            <Layout>
                <div className={classes.content}>{getContent()}</div>
            </Layout>
        </div>
    );
};
