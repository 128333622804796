import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';

export const useUnsupportedNetworkModalStyle = createUseStyles({
    wrapper: {
        textAlign: 'center',
    },
    title: {
        fontSize: fontSize.xl,
        color: color.content.primary,
        marginBottom: spacing['04'],
    },
    subtitle: {
        fontSize: fontSize.lg,
        color: color.content.secondary,
        marginBottom: spacing['07'],
    },
    cardsWrapper: {
        display: 'grid',
        gap: spacing['04'],
        gridTemplateColumns: '50% 50%',
        minWidth: 480,
    },
});
