import { createUseStyles } from 'react-jss';
import { fontSize } from '@blink/components';

export const useFixRateMenuStyle = createUseStyles({
    root: {
        '& .ant-menu > .ant-menu-item > .ant-menu-title-content': {
            fontSize: `${fontSize.md} !important`,
        },
    },
});
