import * as actions from './actions';
import { GET_ME, SET_ME } from './constants';
import { Me } from 'src/api/info';

export type MeState = {
    info: Me;
};

export const MeDefault = {
    name: '',
    organizationName: '',
    email: '',
};

const defaultState: MeState = {
    info: MeDefault,
};

type OrderAction = ReturnType<TInferValueTypes<typeof actions>>;

export default function me(state = defaultState, action: OrderAction): MeState {
    switch (action.type) {
        case GET_ME: {
            return {
                ...state,
            };
        }
        case SET_ME: {
            return {
                ...state,
                info: action.payload,
            };
        }
        default: {
            return state;
        }
    }
}
