import { createUseStyles } from 'react-jss';
import { color } from '@blink/components';

export const useCollateralAssetStyle = createUseStyles({
    assetsWrapper: {
        maxHeight: '400px',
        overflowY: 'scroll',
        border: `1px solid ${color.border.opaque}`,
        overflowX: 'hidden',
        borderRadius: 4,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 8,
    },
    balanceName: {
        color: color.content.primary,
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    button: {},
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        margin: '10px 0',
    },
});
