import { createUseStyles } from 'react-jss';

export const useStyle = createUseStyles({
    layout: {
        minHeight: '100vh',
        '& main': {
            display: 'flex',
            flexDirection: 'column',
        },
    },
});
