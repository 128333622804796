import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';

export const useLiquidityAssetInputStyle = createUseStyles({
    root: {
        background: color.background.secondary,
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${color.border.opaque}`,
        borderRadius: 4,
        padding: `${spacing['05']}`,
        '&:active': {
            borderColor: color.border.selected,
        },
        '&:focus': {
            borderColor: color.border.selected,
        },
        '&:focus-within': {
            borderColor: color.border.selected,
        },
        '&.error': {
            borderColor: color.content.negative,
            '& .title': {
                color: color.content.negative,
            },
        },
        '& img': {
            width: 28,
            height: 28,
        },
    },
    balanceWrapper: {
        display: 'flex',
    },
    infoWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: spacing['03'],
    },
    balance: {
        color: color.content.primary,
        marginLeft: spacing['02'],
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    assetName: {
        fontSize: fontSize.xl,
    },
    input: {
        display: 'flex',
        width: '100%',
        margin: `0 ${spacing['04']} 0 0`,
        alignItems: 'center',
        '& .ant-input': {
            width: '100%',
            fontSize: fontSize.xl,
            borderColor: 'transparent',
            backgroundColor: 'inherit',
            textAlign: 'end',
            lineHeight: 0.5,
            padding: '0 !important',
        },
        '& .ant-input:hover': {
            borderColor: 'transparent',
        },
        '& .ant-input-outlined:focus': {
            boxShadow: 'none',
        },
        '& input': {
            textAlign: 'start !important',
        },
        '& .ant-input::placeholder': {
            color: color.content.secondary,
        },
        '& .ant-input:focus::placeholder': {
            color: color.content.primary,
        },
    },
    borrowApyWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: spacing['02'],
    },
    borrowApy: {
        display: 'flex',
        alignItems: 'center',
        gap: spacing['04'],
        color: color.content.secondary,
    },
    borrowApyNumber: {
        fontWeight: 600,
    },
    borrowImg: {
        width: 24,
        height: 24,
    },
    inputWrapper: {
        display: 'flex',
    },
    textBtn: {
        color: color.content.accent,
        borderBottom: `1px solid ${color.border.accent}`,
        cursor: 'pointer',
        marginLeft: spacing['02'],
    },
});
