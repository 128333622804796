import { createUseStyles } from 'react-jss';

import { color, fontSize } from '../../variables';

export const useButtonPrimaryStyles = createUseStyles({
    root: {
        fontSize: fontSize.sm,
        color: color.content.inversePrimary,
        fontWeight: 600,
        background: `${color.background.accent} !important`,
        borderRadius: 4,
        border: 'none',
        '&:hover': {
            color: `${color.content.inversePrimary} !important`,
            background: `linear-gradient(0deg, ${color.background.overlayDark} 0%, ${color.background.overlayDark} 100%), ${color.background.accent}`,
        },
        '&:active': {
            background: color.background.primary,
            border: `3px solid ${color.border.accent} !important`,
            color: `${color.content.tertiary} !important`,
        },
        '&:disabled': {
            background: color.background.disabled,
            borderColor: `${color.border.inverseOpaque} !important`,
            color: `${color.content.stateDisable} !important`,
        },
    },
});
