import React, { FC } from 'react';
import { Input as AntInput, InputProps as AntInputProps } from 'antd';

type InputProps = AntInputProps & {
    label?: string;
};

export const Input: FC<InputProps> = ({ label, ...props }) => {
    return (
        <>
            {label && <div>{label}</div>}
            <AntInput {...props} />
        </>
    );
};
