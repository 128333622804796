import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';

export const useBorrowDetailsButtonStyle = createUseStyles({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: `0 ${spacing['03']}`,
    },
    left: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'initial',
        gap: spacing['04'],
    },
    title: {
        color: color.content.primary,
        marginBottom: spacing['02'],
    },
    text: {
        color: color.content.secondary,
        fontSize: fontSize.sm,
    },
    arrowIcon: {
        width: 20,
        height: 20,
        transform: 'rotate(-90deg)',
    },
});
