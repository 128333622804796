import React, { FC } from 'react';
import { Tooltip } from 'antd';
import { ReactComponent as CoinsIcon } from '@blink/assets/src/icons/coins.svg';
import { ReactComponent as InfoIcon } from '@blink/assets/src/icons/info.svg';
import { ReactComponent as DotIcon } from '@blink/assets/src/icons/dot.svg';
import { AssetIcon } from '@blink/components/src/Asset/AssetIcon/AssetIcon';

import { useCollateralAssetSelectedCardStyle } from './CollateralAssetSelectedCard.style';
import { SelectedBorrowAsset } from '../BorrowModal';

type CollateralAssetSelectedCardProps = {
    assets: Omit<SelectedBorrowAsset, 'amount'>[];
    assetAmount?: string;
    assetSymbol?: string;
};

export const CollateralAssetSelectedCard: FC<CollateralAssetSelectedCardProps> = ({ assets }) => {
    const classes = useCollateralAssetSelectedCardStyle();
    const assetNames: string[] = [];
    let amount = 0;

    assets.forEach((item) => {
        assetNames.push(item.name);
        amount += Number(Number(item.amountInAssetToBorrow).toFixed(8));
    });

    return (
        <div className={classes.root}>
            {assets.length === 1 && (
                <>
                    <div className={classes.tokenWrapper}>
                        <AssetIcon assetName={assets[0].symbol} />
                        <span>{assets[0].symbol}</span>
                    </div>

                    <div className={classes.amountWrapper}>
                        <div>{amount}</div>
                        <CoinsIcon />
                    </div>
                </>
            )}
            {assets.length === 2 && (
                <>
                    <div className={classes.tokenWrapper}>
                        <div className={classes.iconStack}>
                            <AssetIcon assetName={assets[0].symbol} />
                            <AssetIcon
                                assetName={assets[1].symbol}
                                className={classes.secondToken}
                            />
                        </div>

                        <div className={classes.titleWrapper}>
                            <span>{assets[0].symbol}</span>
                            <DotIcon />
                            <span>{assets[1].symbol}</span>
                        </div>
                    </div>

                    <div className={classes.amountWrapper}>
                        <div>{amount}</div>
                        <CoinsIcon />
                    </div>
                </>
            )}
            {assets.length >= 3 && (
                <>
                    <div className={classes.tokenWrapper}>
                        <div className={classes.iconStack}>
                            <AssetIcon assetName={assets[0].symbol} />
                            <div className={classes.allTokens}>{`+${assets.length - 1}`}</div>
                        </div>

                        <div className={classes.titleWrapper}>
                            <span>{`${assets.length} tokens selected`}</span>
                            <Tooltip
                                rootClassName={classes.tooltip}
                                placement='topLeft'
                                arrow={{ pointAtCenter: true }}
                                title={() => {
                                    const style = {
                                        width: `calc(16px*${assets.length})`,
                                    };
                                    return (
                                        <div className={classes.iconWrapper} style={style}>
                                            {assets.map((asset, idx) => {
                                                const style = {
                                                    left: `calc(16px*${idx})`,
                                                };

                                                return (
                                                    <div
                                                        className={classes.icon}
                                                        style={style}
                                                        key={idx}
                                                    >
                                                        <AssetIcon assetName={asset.symbol} />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                }}
                            >
                                <div className={classes.info}>
                                    <InfoIcon />
                                </div>
                            </Tooltip>
                        </div>
                    </div>

                    <div className={classes.amountWrapper}>
                        <div>{amount}</div>
                        <CoinsIcon />
                    </div>
                </>
            )}
        </div>
    );
};
