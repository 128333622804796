export enum LOCAL_STORAGE {
    WALLET_CONNECT_URI = 'wallet_connect_uri',
    WALLET_CONNECT_TOPIC = 'wallet_connect_topic',
    WALLET_CONNECT_ADDRESS = 'wallet_connect_address',
    WALLET_CONNECT_MARGIN_ADDRESS = 'wallet_connect_margin_address',
    WALLET = 'wallet',
    FAVORITE_POOLS = 'favorite_pools',
    WATCH_LIST = 'watch_list',
    CONNECTED_DAPP = 'connected_dapp',
    NETWORK = 'network',
}

export class LocalStorage {
    static getItem(key: LOCAL_STORAGE): string | null {
        return localStorage.getItem(key);
    }

    static setItem(key: LOCAL_STORAGE, value: string): void {
        localStorage.setItem(key, value);
    }
}
