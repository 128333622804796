import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';

export const useRegisterMarginAccountStyle = createUseStyles({
    root: {
        minWidth: 500,
        color: color.content.primary,
        maxHeight: '600px',
        overflow: 'scroll',
        marginTop: spacing['04'],

        '& > .ant-steps > .ant-steps-item': {
            marginRight: spacing['04'],
            '& .ant-steps-item-icon': {
                marginRight: 36,
            },
            '& .ant-steps-item-tail:after': {
                marginInlineStart: `13px !important`,
                marginTop: 9,
                height: `99% !important`,
                backgroundColor: color.background.accent,
                width: 2,
            },
            '& > .ant-steps-item-container': {
                paddingLeft: 1,
            },
        },

        '& .ant-steps-item-finish .ant-steps-item-icon svg': {
            padding: spacing['02'],
            borderRadius: '100%',
            backgroundColor: color.content.accent,
            '& path': {
                fill: color.content.inversePrimary,
            },
        },
        '& .ant-steps-item-wait:nth-child(n+4)': {
            opacity: 0.4,
        },
    },
    amount: {
        color: color.content.primary,
    },
    title: {
        color: color.border.opaque,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: `${spacing['06']} 0 ${spacing['03']}`,
    },
    summary: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: spacing['03'],
    },
    tipWrapper: {
        marginTop: spacing['04'],
        borderTop: `1px solid ${color.border.opaque}`,
    },
    waiting: {
        color: `${color.content.secondary} !important`,
    },
    submitButton: {
        fontSize: fontSize.md,
        width: '100%',
        marginTop: 'auto',
    },
    allowanceTitle: {
        fontSize: fontSize.md,
        marginBottom: spacing['03'],
        color: color.content.primary,
    },
    allowance: {
        marginBottom: spacing['07'],
        display: 'flex',
        alignItems: 'flex-start',
    },
    allowanceStatus: {
        marginLeft: spacing['06'],
        width: '100%',
    },
    check: {
        color: color.content.accent,
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '100px',
        border: `2px solid ${color.border.accent}`,
        width: 24,
        height: 24,
    },
    dot: {
        borderRadius: '100px',
        backgroundColor: color.background.accent,
        width: 7,
        height: 7,
    },
});
