import React, { FC } from 'react';
import { Button, ButtonProps } from 'antd';

import { useButtonStrokeStyle } from './ButtonStroke.style';
import { concatClasses } from '../../utils';
import { ButtonSize } from '../Button.types';
import { useBtnStyles } from '../Button.style';
import { useButtonSecondaryStyle } from './ButtonSecondary.style';
import { useButtonPrimaryStyles } from './ButtonPrimary.style';
import { useButtonOutlineStyles } from './ButtonOutline.style';

type ButtonStrokeProps = Omit<ButtonProps, 'size'> & {
    size?: ButtonSize;
    isPrimary?: boolean;
    isSecondary?: boolean;
    isOutline?: boolean;
};

export const ButtonStroke: FC<ButtonStrokeProps> = ({
    className,
    size = ButtonSize.lg,
    isSecondary,
    isPrimary,
    isOutline,
    ...props
}) => {
    const baseClasses = useBtnStyles();
    const strokeClasses = useButtonStrokeStyle();
    const primaryClasses = useButtonPrimaryStyles();
    const secondaryClasses = useButtonSecondaryStyle();
    const outlineClasses = useButtonOutlineStyles();

    const sizeMap = new Map()
        .set(ButtonSize.sm, baseClasses.small)
        .set(ButtonSize.md, baseClasses.medium)
        .set(ButtonSize.lg, baseClasses.large)
        .set(ButtonSize.xl, baseClasses.extraLarge);

    const getBaseClass = () => {
        if (isPrimary) {
            return primaryClasses;
        }

        if (isSecondary) {
            return secondaryClasses;
        }

        if (isOutline) {
            return outlineClasses;
        }

        return strokeClasses;
    };

    return isOutline ? (
        <div className={outlineClasses.overlay}>
            <Button
                type='default'
                className={concatClasses(getBaseClass().root, sizeMap.get(size), className || '')}
                {...props}
            />
        </div>
    ) : (
        <Button
            type='default'
            className={concatClasses(getBaseClass().root, sizeMap.get(size), className || '')}
            {...props}
        />
    );
};
