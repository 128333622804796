import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';

export const useOperationStatusModalStyle = createUseStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 430,
        textAlign: 'center',
    },
    title: {
        color: color.content.primary,
        fontSize: fontSize.xl,
        margin: `${spacing['06']} 0 ${spacing['03']}`,
        lineHeight: '32px',
        letterSpacing: -0.42,
    },
    description: {
        color: color.content.secondary,
        marginBottom: spacing['09'],
        fontSize: fontSize.lg,
        lineHeight: '24px',
        letterSpacing: -0.306,
    },
    button: {
        width: '100%',
        fontSize: fontSize.lg,
    },
});
