import { createUseStyles } from 'react-jss';
import { color, spacing } from '@blink/components';

export const useAllowedStyle = createUseStyles({
    tableWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        overflowX: 'auto',
        background: color.background.primary,
        '& th': {
            fontWeight: 500,
        },
    },
    table: {
        borderCollapse: 'collapse',
        width: '100%',
        background: color.background.primary,
        border: `1px solid ${color.border.opaque}`,
    },
    tr: {
        borderBottom: `1px solid ${color.border.opaque}`,
        height: 52,
        '& span': {
            fontWeight: 500,
        },
        '& > th': {
            fontWeight: 'normal',
            textAlign: 'left',
            color: color.content.primary,
            width: '20%',
            background: color.background.secondary,
        },

        '& > td:last-child, th:last-child': {
            paddingRight: spacing['07'],
        },
        '& > th:first-child, td:first-child': {
            paddingLeft: spacing['06'],
        },
    },
    asset: {
        width: 250,
    },
    firstTd: {
        padding: `${spacing['04']} ${spacing['05']}`,
    },
    param: {
        overflow: 'hidden',
        color: color.content.primary,
        textOverflow: 'ellipsis',
        fontSize: 14,
        fontWeight: 600,
        lineHeight: '20px',
        letterSpacing: '-0.42px',
    },
    subtitle: {
        overflow: 'hidden',
        color: color.content.secondary,
        textOverflow: 'ellipsis',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '-0.42px',
    },
});
