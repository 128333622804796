export enum NETWORK {
    ETHEREUM = 'ethereum-mainnet',
    BNB = 'bnb-mainnet',
    POLYGON = 'polygon-mainnet',
    ARBITRUM = 'arbitrum-mainnet',
    ARKIS = 'arkis-mainnet',
}

export enum NETWORK_CHAIN {
    ETHEREUM_NETWORK = '1',
    BNB_NETWORK = '56',
    POLYGON_NETWORK = '137',
    ARBITRUM_NETWORK = '42161',
    ARKIS_NETWORK = '6969',
}

export const UNSUPPORTED_NETWORK_ID = 'Unsupported';

export const NETWORK_READABLE_NAME = new Map<NETWORK, string>()
    .set(NETWORK.ETHEREUM, 'Ethereum')
    .set(NETWORK.BNB, 'BNB')
    .set(NETWORK.POLYGON, 'Polygon')
    .set(NETWORK.ARBITRUM, 'Arbitrum')
    .set(NETWORK.ARKIS, 'Arkis');

export const NETWORK_CHAIN_TO_NETWORK = new Map<string, NETWORK>()
    .set(NETWORK_CHAIN.ETHEREUM_NETWORK, NETWORK.ETHEREUM)
    .set(NETWORK_CHAIN.BNB_NETWORK, NETWORK.BNB)
    .set(NETWORK_CHAIN.POLYGON_NETWORK, NETWORK.POLYGON)
    .set(NETWORK_CHAIN.ARBITRUM_NETWORK, NETWORK.ARBITRUM)
    .set(NETWORK_CHAIN.ARKIS_NETWORK, NETWORK.ARKIS);

export const NETWORK_TO_NETWORK_CHAIN = new Map<NETWORK, NETWORK_CHAIN>()
    .set(NETWORK.ETHEREUM, NETWORK_CHAIN.ETHEREUM_NETWORK)
    .set(NETWORK.BNB, NETWORK_CHAIN.BNB_NETWORK)
    .set(NETWORK.POLYGON, NETWORK_CHAIN.POLYGON_NETWORK)
    .set(NETWORK.ARBITRUM, NETWORK_CHAIN.ARBITRUM_NETWORK)
    .set(NETWORK.ARKIS, NETWORK_CHAIN.ARKIS_NETWORK);

export const METAMASK_DOWNLOAD_URL = 'https://metamask.io/download/';

export const COINBASE_DOWNLOAD_URL = 'https://www.coinbase.com/wallet/downloads';

export const RABBY_DOWNLOAD_URL = 'https://rabby.io/';

export const INSTALL_METAMASK_URL = 'https://metamask.io/';

export enum DEX {
    ONEINCH = '1inch',
    BALANCER = 'balancer',
    CURVE = 'curve',
    DODO = 'dodo',
    SUSHI = 'sushi',
    UNISWAPV2 = 'uniswapv2',
    UNISWAPV3 = 'uniswapv3',
    AAVE = 'aave',
    CAKE = 'cake',
    CVX = 'cvx',
    LIDO = 'lido',
}
export const enum MEDIA_ITEMS {
    DISCORD = 'discord',
    GITHUB = 'github',
    MEDIUM = 'medium',
    TWITTER = 'twitter',
}

export const MEDIA_IITEMS_URL: Record<MEDIA_ITEMS, string> = {
    discord: 'https://discord.com/',
    github: 'https://github.com/',
    medium: 'https://medium.com/',
    twitter: 'https://twitter.com/?lang=en',
};

export const enum METAMASK_ERRORS {
    USER_REJECTED = 4001,
    CHAIN_NOT_ADDED = 4902,
}

export const enum COINBASE_ERRORS {
    CHAIN_NOT_ADDED = -32603,
}

export const MINIMAL_RISK_FACTOR_VALUE = 1;
