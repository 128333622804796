import { AbstractWallet, EthereumWallet } from '../utils';
import { InstalledWalletList, WALLET } from '../types';
import { WalletConnect } from '../utils/wallet/walletConnect';

class NullWallet extends EthereumWallet {
    constructor() {
        super();
    }

    public isWalletInProvider(): boolean {
        return true;
    }

    public async getProvider(): Promise<any> {
        return undefined;
    }

    public isAvailable(): boolean {
        return false;
    }
}

const walletMap: Map<WALLET, AbstractWallet> = new Map<WALLET, AbstractWallet>()
    .set(WALLET.META_MASK, new NullWallet())
    .set(WALLET.COIN_BASE, new NullWallet())
    .set(WALLET.RABBY, new NullWallet())
    .set(WALLET.WALLET_CONNECT, new WalletConnect());

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const installedWallets: InstalledWalletList = {
    [WALLET.META_MASK]: false,
    [WALLET.COIN_BASE]: false,
};

walletMap.forEach((value: AbstractWallet, key: WALLET) => {
    installedWallets[key] = value.isAvailable();
});

export { walletMap, installedWallets };
