import { createUseStyles } from 'react-jss';
import { color, spacing } from '@blink/components';

export const useInfoCardStyle = createUseStyles({
    root: {
        padding: `${spacing['04']} ${spacing['05']}`,
        display: 'flex',
        gap: spacing['03'],
        borderRadius: 4,
        '& svg': {
            width: 20,
            height: 20,
        },
    },
    success: {
        background: color.background.lightPositive,
        color: color.content.positive,
        border: `1px solid ${color.border.positive}`,
        '& rect': {
            fill: color.content.positive,
        },
    },
    alert: {
        background: color.background.lightWarning,
        color: color.background.warning,
        border: `1px solid ${color.border.warning}`,
        '& rect': {
            fill: color.border.warning,
        },
    },
    error: {
        background: color.background.lightNegative,
        color: color.background.negative,
        border: `1px solid ${color.border.negative}`,
        '& rect': {
            fill: color.border.negative,
        },
    },
    info: {
        color: color.content.secondary,
        '& svg > path': {
            fill: color.content.secondary,
        },
    },
});
