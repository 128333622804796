import { createUseStyles } from 'react-jss';
import { color, spacing } from '@blink/components';

export const useCollateralAssetInputStyle = createUseStyles({
    inputSection: {
        display: 'flex',
        justifyContent: 'space-between',
        background: color.background.secondary,
        borderLeft: `2px solid ${color.border.accent}`,
        padding: spacing['04'],
        alignItems: 'center',
    },
    assetImage: {
        width: 17,
        height: 17,
    },
    inputSectionRight: {
        display: 'flex',
        alignItems: 'center',
        color: color.content.secondary,
    },
    maxButton: {
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        color: color.content.accent,
        fontWeight: 600,
        cursor: 'pointer',
        marginLeft: spacing['02'],
        borderBottom: `1px solid ${color.border.accent}`,
    },
    submitButton: {
        padding: `0 ${spacing['02']}`,
        '&:hover': {
            color: `${color.content.positive} !important`,
        },
    },
    inputSectionConclusion: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: spacing['04'],
        marginLeft: spacing['04'],
        borderLeft: `1px solid ${color.border.opaque}`,
        gap: spacing['02'],
    },
    secondary: {
        color: color.content.secondary,
    },
    input: {
        border: 'none',
        background: 'transparent',
        '&:hover': {
            background: color.background.secondary,
            borderInlineEndWidth: 0,
        },
        '&:focus': {
            boxShadow: 'none',
            background: color.background.secondary,
            borderInlineEndWidth: 0,
        },
        '&.error': {
            border: `1px solid ${color.content.negative}`,
        },
    },
    hidden: {
        display: 'none',
    },
    max: {
        '&.error': {
            color: color.content.negative,
        },
    },
});
