import React, { FC } from 'react';
import { concatClasses, copyToClipboard, truncate } from '@blink/components/src/utils';
import { NETWORK, NETWORK_READABLE_NAME } from '@blink/components/src/constants/global';
import { ReactComponent as BinanceIcon } from '@blink/assets/src/icons/binanceLogo.svg';
import { ReactComponent as EthereumIcon } from '@blink/assets/src/icons/ethereumWhite.svg';
import { ReactComponent as PolygonIcon } from '@blink/assets/src/icons/polygonLogo.svg';
import { ReactComponent as ArbitrumIcon } from '@blink/assets/src/icons/arbitrumLogo.svg';
import { ReactComponent as CopyIcon } from '@blink/assets/src/icons/copy.svg';
import { Link } from 'react-router-dom';
import { AssetIcon } from '@blink/components/src/Asset/AssetIcon';
import { TextButton } from '@blink/components';
import { message } from 'antd';
import { formatUnits } from 'ethers';
import SkeletonInput from 'antd/es/skeleton/Input';

import { useUserBorrowsCardStyles } from './UserBorrowsCard.styles';
import { UserBorrowItem } from '../UserBorrowsSection';
import { BorrowStatusType } from 'src/api/borrow';

type UserBorrowsCard = UserBorrowItem & {
    connectedMarginAccount?: string;
    status: BorrowStatusType;
};

export const UserBorrowsCard: FC<UserBorrowsCard> = ({
    apy,
    address,
    amount,
    decimals,
    maintenance,
    history,
    symbol,
    connectedMarginAccount,
    status,
}) => {
    const classes = useUserBorrowsCardStyles();

    const iconMap = new Map()
        .set(NETWORK.ETHEREUM, <EthereumIcon />)
        .set(NETWORK.BNB, <BinanceIcon />)
        .set(NETWORK.ARBITRUM, <ArbitrumIcon />)
        .set(NETWORK.POLYGON, <PolygonIcon />);

    const isBorrowConnected = connectedMarginAccount === address;
    const connectedTitle = () => {
        if (!isBorrowConnected) {
            return;
        }
        return <div className={classes.connectedTitle}>Connected with WalletConnect</div>;
    };

    const riskFactor =
        maintenance?.riskFactor || history?.[history.length - 1]?.data?.riskFactor || '0';
    const accruedInterest =
        maintenance?.accruedInterest || history?.[history.length - 1]?.data?.accruedInterest || '0';

    const stressTested =
        maintenance?.stressTested || history?.[history.length - 1]?.data?.stressTested || '0';

    const handleCopyAddress = (e: any) => {
        e.preventDefault();
        copyToClipboard(address);
        message.success('Copied to clipboard!');
    };

    return (
        <Link
            to={status === BorrowStatusType.OPENED ? `/borrow/${address}` : ''}
            className={`${classes.root} ${isBorrowConnected && classes.connected} ${
                status !== BorrowStatusType.OPENED && classes.notClickable
            }`}
        >
            <div className={classes.headerRow}>
                <div className={classes.assetInfo}>
                    <AssetIcon assetName={symbol} className={classes.assetIcon} />
                    <div>
                        <div className={classes.white}>{symbol}</div>
                        <div className={classes.wallet}>
                            {truncate({ value: address, startSymbols: 6 })}
                            <TextButton onClick={handleCopyAddress}>
                                <CopyIcon />
                            </TextButton>
                        </div>
                    </div>
                </div>
                <div className={classes.networkBadge}>
                    {iconMap.get(NETWORK.ETHEREUM)}
                    <div>{NETWORK_READABLE_NAME.get(NETWORK.ETHEREUM)}</div>
                </div>
            </div>
            <div className={concatClasses(classes.footerRow, classes.stressTestValue)}>
                <div className={classes.footerCell}>
                    {status !== BorrowStatusType.OPENED && (
                        <>
                            <div>Amount</div>
                            <div className={classes.stressValue}>
                                <div
                                    className={concatClasses(
                                        classes.white,
                                        classes.stressValueFull,
                                    )}
                                >
                                    {formatUnits(amount, decimals)}
                                </div>
                            </div>
                        </>
                    )}
                    {status === BorrowStatusType.OPENED && (
                        <>
                            <div>Stress-tested value</div>
                            <div className={classes.stressValue}>
                                <div
                                    className={concatClasses(
                                        classes.white,
                                        classes.stressValueFull,
                                    )}
                                >
                                    {Number(formatUnits(stressTested, decimals)).toFixed(8)}
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className={classes.footerRight}>
                    <div className={classes.footerCell}>
                        <div>Borrow APR</div>
                        {Number(apy) ? (
                            <div className={classes.white}>{Number(apy)}%</div>
                        ) : (
                            <SkeletonInput className={classes.smallSkeleton} size='small' />
                        )}
                    </div>
                    {status === BorrowStatusType.OPENED && (
                        <div className={concatClasses(classes.footerCell, classes.lastCell)}>
                            <div>Risk Factor</div>
                            <div className={classes.riskFactor}>
                                {Number(riskFactor).toFixed(8)}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {status === BorrowStatusType.OPENED && (
                <div className={classes.footerRow}>
                    <div className={concatClasses(classes.footerCell, classes.totalValue)}>
                        <div>Total Interest Amount</div>
                        <div className={classes.totalBalance}>
                            <div className={concatClasses(classes.white, classes.totalBalanceFull)}>
                                {Number(formatUnits(accruedInterest, decimals)).toFixed(8)}
                            </div>
                            <div className={classes.white}>{symbol}</div>
                        </div>
                    </div>
                </div>
            )}

            {connectedTitle()}
        </Link>
    );
};
