import { createUseStyles } from 'react-jss';

import { color, fontSize } from '../../variables';

export const useButtonStrokeStyle = createUseStyles({
    root: {
        fontSize: fontSize.sm,
        fontWeight: 600,
        color: color.content.primary,
        borderColor: color.border.opaque,
        borderRadius: 4,
        background: `${color.background.primary} !important`,

        '&:hover': {
            background: `${color.background.primary} !important`,
            borderColor: `${color.border.opaque} !important`,
            color: `${color.content.primary} !important`,
            opacity: 0.85,
        },
        '&:active': {
            background: color.background.primary,
            border: `3px solid ${color.border.accent} !important`,
            color: `${color.content.primary} !important`,
        },
        '&:disabled': {
            background: color.background.disabled,
            borderColor: `${color.border.accent} !important`,
            color: `${color.content.primary} !important`,
        },
    },
});
