import React, { FC, ReactNode } from 'react';
import { ReactComponent as ArrowIcon } from '@blink/assets/src/icons/arrow.svg';
import { Button } from '@blink/components';
import { ButtonProps } from 'antd';

import { useBorrowDetailsButtonStyle } from './BorrowDetailsButton.style';

type BorrowDetailsButtonProps = ButtonProps & {
    icon: ReactNode;
    title: string;
    text: string;
};

export const BorrowDetailsButton: FC<BorrowDetailsButtonProps> = ({
    icon,
    text,
    title,
    ...props
}) => {
    const classes = useBorrowDetailsButtonStyle();

    return (
        <Button type='text' className={classes.root} {...props}>
            <div className={classes.left}>
                {icon}
                <div>
                    <div className={classes.title}>{title}</div>
                    <div className={classes.text}>{text}</div>
                </div>
            </div>
            <ArrowIcon className={classes.arrowIcon} />
        </Button>
    );
};
