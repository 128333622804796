import React, { FC } from 'react';
import { ReactComponent as Loader } from '@blink/assets/src/icons/loaderDash.svg';

import { useUserSuppliesStyle } from '../UserSupplies.style';

type UserSuppliesEmpty = {
    customTitle?: string;
    customSubtitle?: string;
    customButtonName?: string;
};

export const UserSuppliesEmpty: FC<UserSuppliesEmpty> = ({ customTitle, customSubtitle }) => {
    const commonClasses = useUserSuppliesStyle();

    return (
        <div className={commonClasses.noSuppliesBlock}>
            <Loader />
            <div className={commonClasses.noSuppliesTitle}>
                {customTitle ?? 'Nothing supplied yet'}
            </div>
            <div className={commonClasses.noSuppliesSubtitle}>
                {customSubtitle ?? 'Start supplying by choosing an asset'}
            </div>
        </div>
    );
};
