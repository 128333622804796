import React, { FC } from 'react';
import { ReactComponent as InfoIcon } from '@blink/assets/src/icons/info.svg';

import { useTipCardStyle } from './TipCard.style';

type TipCardProps = {
    title: string;
    text: string;
};

export const TipCard: FC<TipCardProps> = ({ title, text }) => {
    const classes = useTipCardStyle();

    return (
        <div className={classes.root}>
            <InfoIcon />
            <div className={classes.contentWrapper}>
                <div className={classes.header}>
                    <div className={classes.title}>{title}</div>
                </div>
                <div className={classes.content}>{text}</div>
            </div>
        </div>
    );
};
