import { HIDE_MODAL, SHOW_MODAL, ModalName } from './constants';
import { ModalProps } from './index';

export type ShowModalActionProps = {
    modalName: ModalName;
    modalTitle?: string;
    modalProps?: any;
    previousModal?: ModalProps | null;
};

export const showModalAction = (payload: ShowModalActionProps) => ({
    type: SHOW_MODAL,
    payload,
});

export const hideModalAction = () => ({
    type: HIDE_MODAL,
});
