import React, { FC } from 'react';
import { Skeleton } from 'antd';

import { userRiskFactorStyle } from './RiskFactor.style';

export type RiskFactorBarProps = {
    health: number | null;
};

export const RiskFactorBar: FC<RiskFactorBarProps> = ({ health }) => {
    const classes = userRiskFactorStyle();

    const pivotHighRisk = 1;
    const pivotLowRisk = 3;

    const loading = health === null;
    const isLow = !loading && health > pivotLowRisk;
    const isWarning = !loading && health >= pivotHighRisk && health <= pivotLowRisk;
    const isHigh = !loading && health < pivotHighRisk;

    const formattedHealth = health?.toFixed(2);
    return (
        <div className={classes.placeholderBarWrapper}>
            {loading ? (
                <>
                    <Skeleton.Input className={classes.small} active />
                    <Skeleton.Input className={classes.small} active />
                    <Skeleton.Input className={classes.small} active />
                </>
            ) : (
                <>
                    <div className={classes.barWrapper}>
                        <div className={isHigh ? classes.errorBar : classes.emptyBar}>
                            <input
                                className={classes.slider}
                                type='range'
                                min='0'
                                max={pivotHighRisk}
                                defaultValue={formattedHealth}
                                step='0.1'
                            />
                        </div>
                        <div>High</div>
                    </div>
                    <div className={classes.barWrapper}>
                        <div className={isWarning ? classes.warningBar : classes.emptyBar}>
                            <input
                                className={classes.slider}
                                type='range'
                                min={pivotHighRisk + 0.001}
                                max={pivotLowRisk}
                                defaultValue={formattedHealth}
                                step='0.1'
                            />
                        </div>
                        <div>Normal</div>
                    </div>
                    <div className={classes.barWrapper}>
                        <div className={isLow ? classes.successBar : classes.emptyBar}>
                            <input
                                className={classes.slider}
                                type='range'
                                min={pivotLowRisk + 0.001}
                                max='10'
                                defaultValue={formattedHealth}
                            />
                        </div>
                        <div>Low</div>
                    </div>
                </>
            )}
        </div>
    );
};
