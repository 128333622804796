import { createUseStyles } from 'react-jss';
import { color } from '@blink/components';

export const useLendStyle = createUseStyles({
    root: {
        marginTop: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '32px 0 48px',
    },
    group: {
        width: 280,
        fontWeight: 500,
        '& .ant-radio-button-wrapper': {
            height: 42,
            padding: `1px 20px`,
        },
        '& .ant-radio-button-wrapper-checked': {
            border: `2px solid ${color.border.accent} !important`,
            padding: `0 19px !important`,
        },
    },
});
