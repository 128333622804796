import {
    SET_USERS_SUPPLIES_ERROR,
    ADD_NETWORK_ERROR,
    ADD_WALLETS_ERROR,
    SET_USER_BORROWS_ERROR,
    SET_AGREEMENTS_ERROR,
} from 'src/store/errors/constants';
import * as actions from './actions';

export type ErrorState = {
    [key: string]: string;
};

const defaultState: ErrorState = {
    setAssetsToSupplyError: '',
    setUsersSuppliesError: '',
    setUserBorrowsError: '',
    addWalletsError: '',
    addNetworksError: '',
    setAgreementError: '',
};

export type ErrorAction = ReturnType<TInferValueTypes<typeof actions>>;

export default function error(state = defaultState, action: ErrorAction): ErrorState {
    switch (action.type) {
        case SET_USERS_SUPPLIES_ERROR: {
            return {
                ...state,
                setUsersSuppliesError: action.payload.message,
            };
        }
        case SET_USER_BORROWS_ERROR: {
            return {
                ...state,
                setUserBorrowsError: action.payload.message,
            };
        }
        case ADD_WALLETS_ERROR: {
            return {
                ...state,
                addWalletsError: action.payload.message,
            };
        }
        case ADD_NETWORK_ERROR: {
            return {
                ...state,
                addNetworksError: action.payload.message,
            };
        }
        case SET_AGREEMENTS_ERROR: {
            return {
                ...state,
                addAgreementsError: action.payload.message,
            };
        }
        default: {
            return state;
        }
    }
}
