import { actionCreator } from '@blink/components/src/utils';

import {
    SET_USERS_SUPPLIES_ERROR,
    ADD_WALLETS_ERROR,
    ADD_NETWORK_ERROR,
    SET_USER_BORROWS_ERROR,
    SET_AGREEMENTS_ERROR,
} from './constants';

export type ErrorActionProps = {
    message: string;
};

export const usersSuppliesErrorAction = (payload: ErrorActionProps) =>
    actionCreator(SET_USERS_SUPPLIES_ERROR, payload);

export const userBorrowsErrorAction = (payload: ErrorActionProps) =>
    actionCreator(SET_USER_BORROWS_ERROR, payload);

export const addWalletsErrorAction = (payload: ErrorActionProps) =>
    actionCreator(ADD_WALLETS_ERROR, payload);

export const addNetworksErrorAction = (payload: ErrorActionProps) =>
    actionCreator(ADD_NETWORK_ERROR, payload);

export const agreementErrorAction = (payload: ErrorActionProps) =>
    actionCreator(SET_AGREEMENTS_ERROR, payload);
