import { createUseStyles } from 'react-jss';
import { color, spacing } from '@blink/components';

export const useCollateralPendingActions = createUseStyles({
    root: {
        color: color.content.primary,
        padding: `0 ${spacing['06']}`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 'max-content',
        borderLeft: `2px solid transparent`,
        '&:hover': {
            borderLeft: `2px solid ${color.border.opaque}`,
            background: 'rgba(54, 247, 211, 0.05)',
        },
        '&:last-of-type .border': {
            borderBottom: 'none',
        },
        '& div': {
            fontWeight: 600,
        },
    },
    addBorder: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: `${spacing['04']} 0`,
        borderBottom: `1px solid ${color.border.opaque}`,
    },
    amountWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: spacing['04'],
        fontWeight: 600,
    },
    flexWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: `0px ${spacing['06']} ${spacing['05']}`,
        color: color.content.primary,
    },
    btn: {
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        color: color.content.accent,
        fontWeight: 600,
        cursor: 'pointer',
        marginLeft: spacing['02'],
        '& > svg': {
            width: 20,
            height: 20,
            '& path': {
                fillOpacity: 1,
            },
        },
        '& > span': {
            textDecoration: 'none',
            fontWeight: 500,
        },
    },
    scrollWrapper: {
        maxHeight: '230px',
        overflowX: 'hidden',
        overflowY: 'scroll',
    },
});
