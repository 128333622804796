import React, { FC } from 'react';
import { Progress as AntProgress, ProgressProps as AntProgressProps } from 'antd';

import { color } from '../variables';

type ProgressProps = AntProgressProps;

export const Progress: FC<ProgressProps> = (props) => {
    return (
        <AntProgress
            size='small'
            strokeColor={color.content.primary}
            trailColor='rgba(116, 129, 164, 0.1)'
            {...props}
        />
    );
};
