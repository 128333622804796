import { createUseStyles } from 'react-jss';

import { color, fontSize, spacing } from '../variables';

export const useInputStyles = createUseStyles({
    maxButtonLink: {
        padding: `0 ${spacing['02']}`,
        color: color.content.primary,
        height: 'fit-content',
        '&:hover': {
            color: `${color.content.primary} !important`,
        },
    },
    maxButton: {
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        color: color.content.accent,
        fontWeight: 600,
        cursor: 'pointer',
        marginLeft: spacing['02'],
        marginRight: spacing['02'],
        borderBottom: `1px solid ${color.border.accent}`,
    },
    inputWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
});
