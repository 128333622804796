import { createUseStyles } from 'react-jss';
import { color, spacing } from '@blink/components';

export const useManuHeaderStyle = createUseStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '72px !important',
        paddingInline: 0,
        background: color.background.primary,
    },
    left: {
        display: 'flex',
        alignItems: 'center',
    },
    right: {
        display: 'flex',
        alignItems: 'center',
        gap: spacing['04'],
    },
    logo: {
        paddingRight: spacing['07'],
    },
    logoLink: {
        display: 'flex',
        alignItems: 'center',
    },
});
