import React, { ReactNode } from 'react';
import { Alert } from 'antd';

import { useBannerStyles } from './Banner.styles';

interface IProps {
    icon: ReactNode;
    message: string;
    description: string;
    closable?: boolean;
}

export const Banner: React.FC<IProps> = ({ icon, message, description, closable }) => {
    const classes = useBannerStyles();
    return (
        <Alert
            icon={icon}
            showIcon
            message={message}
            description={description}
            closable={closable}
            className={classes.banner}
        />
    );
};
