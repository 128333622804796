import React, { FC, ReactNode } from 'react';
import { ReactComponent as HealthIcon } from '@blink/assets/src/icons/loaderDashWhite.svg';
import { concatClasses } from '@blink/components/src/utils';

import { useInfoCardStyle } from './InfoCard.style';

type InfoCardProps = {
    type: InfoCardType;
    text: ReactNode;

    classes?: Partial<Record<InfoCardClasses, string>>;
};

export type InfoCardType = 'success' | 'error' | 'alert' | 'info';
type InfoCardClasses = 'root';

export const InfoCard: FC<InfoCardProps> = ({ type, text, classes }) => {
    const baseClasses = useInfoCardStyle();

    return (
        <div className={concatClasses(baseClasses.root, baseClasses[type], classes?.root || '')}>
            <HealthIcon />
            <div>{text}</div>
        </div>
    );
};
