import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider } from 'react-router-dom';
import { Api } from '@blink/components';

import router from './routes';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
console.table({
    'Margin Account': Api.APP_DISPATCHER_ADDRESS,
});

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>,
);
