import { createUseStyles } from 'react-jss';
import { color, spacing } from '@blink/components';

export const useWalletConnectStyle = createUseStyles({
    root: {
        color: color.content.primary,
        maxWidth: 600,
        margin: '0 auto',
        marginTop: spacing['09'],
        display: 'flex',
        flexDirection: 'column',
        gap: spacing['05'],
    },
    label: {
        marginBottom: spacing['02'],
        fontSize: 17,
    },
    button: {
        marginTop: spacing['03'],
        fontSize: 17,
    },
    log: {
        marginBottom: spacing['04'],
        fontSize: 14,
    },
});
