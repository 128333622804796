import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';

export const useCollateralAssetSelectedCardStyle = createUseStyles({
    root: {
        marginBottom: spacing['03'],
        color: color.content.primary,
        padding: `${spacing['04']}`,
        gap: `${spacing['03']}`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 'max-content',
        border: `1px solid ${color.border.opaque}`,
        borderRadius: 4,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
    },
    progress: {
        width: 40,
        height: 40,
    },
    row: {
        display: 'flex',
        gap: spacing['02'],
        '& span': {
            fontSize: 14,
            fontWeight: 600,
        },
    },
    container: {
        display: 'flex',
        gap: spacing['02'],
        fontWeight: 600,
        '& span': {
            fontSize: 14,
            fontWeight: 400,
        },
    },
    amountWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: spacing['04'],
    },
    tokenWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: spacing['04'],
    },
    iconStack: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        width: 40,
    },
    secondToken: {
        position: 'absolute',
        left: 14,
    },
    titleWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 6,
    },
    allTokens: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 24,
        height: 24,
        borderRadius: 100,
        border: `0.857px solid ${color.background.secondary}`,
        background: color.background.tertiary,
        position: 'absolute',
        left: 14,
        fontSize: fontSize.xs,
    },
    tooltip: {
        borderRadius: 4,
        '& .ant-tooltip-inner': {
            padding: `${spacing['03']} ${spacing['05']} ${spacing['03']} ${spacing['04']}`,
            border: `1px solid transparent`,
            background: `${color.background.fourth} !important`,
        },
        '& .ant-tooltip-arrow:before': {
            background: `${color.background.fourth} !important`,
        },
    },
    icon: {
        position: 'absolute',
        left: 14,
        '& img': {
            width: 20,
            height: 20,
        },
    },
    iconWrapper: {
        position: 'relative',
        height: 20,
    },
    info: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
            width: 20,
            height: 20,
        },
    },
});
