import React from 'react';

import { userRiskFactorStyle } from './RiskFactor.style';

export type RiskFactorProps = {
    riskFactor: number;
};

const MAX_RISK_FACTOR_BEFORE_LIQUIDATED = 1;

export const RiskFactor = ({ riskFactor }: RiskFactorProps) => {
    const classes = userRiskFactorStyle();
    const colorClass = riskFactor >= MAX_RISK_FACTOR_BEFORE_LIQUIDATED ? classes.good : classes.bad;
    return <div className={colorClass}>{riskFactor}</div>;
};
