import { createUseStyles } from 'react-jss';
import { color, fontSize, radius, spacing } from '@blink/components';

export const useUseControlsStyles = createUseStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: radius.overall,
        border: `1px solid ${color.border.opaque}`,
        height: 44,
        width: 140,
        boxShadow: 'none !important',
        whiteSpace: 'nowrap',
        '& span': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: fontSize.md,
        },
        '& svg': {
            flexShrink: 0,
            marginRight: 6,
        },
    },
    textButton: {
        color: `${color.content.secondary} !important`,
        width: 'fit-content',
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        gap: spacing['03'],
        fontWeight: 600,
        transition: 'none',
        border: 'none',
        '& path': {
            fill: color.content.secondary,
        },
        '&:hover': {
            color: `${color.content.primary} !important`,
            backgroundColor: 'transparent !important',
            border: 'none !important',
            '& path': {
                fill: color.border.selected,
            },
        },
    },
    dropdownWQL: {
        width: '250px',
        padding: `${spacing['03']} 0px`,
        '& > div': {
            width: '250px',
            border: `1px solid ${color.border.opaque}`,
        },
        '& > ul': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            flexShrink: 0,
        },
        '& .ant-dropdown-menu-item': {
            display: 'flex !important',
            alignItems: 'center !important',
            padding: `${spacing['04']} ${spacing['05']} !important`,
            alignSelf: 'stretch !important',
            gap: 8,
            backgroundColor: color.background.primary,
        },
        '& .ant-btn-default': {
            boxShadow: 'none !important',
        },
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: color.background.primary,
        padding: `${spacing['04']} ${spacing['06']} ${spacing['03']} ${spacing['06']}!important`,
        alignSelf: 'stretch !important',
        '& .name': {
            fontWeight: 600,
        },
    },
});
