import React from 'react';
import { Input, InputNumberProps } from 'antd';
import { parseUnits } from 'ethers';

import { useInputStyles } from './Input.style';

type InputNumberCustomProps = InputNumberProps & {
    handleChange: (value: any) => void;
    max?: string;
    isMax?: boolean;
    btnLink?: boolean;
    disabled?: boolean;
    className?: string;
    decimals: number;
};

export const InputNumberCustom: React.FC<InputNumberCustomProps> = ({
    value,
    placeholder,
    handleChange,
    disabled,
    className,
    decimals,
    max,
}) => {
    const classes = useInputStyles();

    const handleOnChange = (e: { target: { value: string } }) => {
        const inputValue = e.target.value.trim();
        if (/^(0|[1-9]\d*)(\.\d{0,18})?$/.test(inputValue)) {
            try {
                parseUnits(inputValue, decimals);
            } catch (e) {
                return;
            }

            handleChange(inputValue);
        }

        if (inputValue === '') {
            handleChange('');
        }
    };

    const handleMaxClick = () => {
        if (max) {
            handleChange(max);
        }
    };

    return (
        <div className={classes.inputWrapper}>
            <Input
                type='text'
                value={value ?? ''}
                onChange={handleOnChange}
                placeholder={placeholder}
                disabled={disabled}
                className={className}
            />
            {max && (
                <div onClick={handleMaxClick} className={classes.maxButton}>
                    Max
                </div>
            )}
        </div>
    );
};
