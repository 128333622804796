import React from 'react';
import { Radio, RadioChangeEvent } from 'antd';

import { useButtonGroupStyles } from './ButtonGroup.style';

export interface IItem {
    text: string;
    value: number | string;
}

export interface IItems {
    items: IItem[];
    value: number | string;
    handleChange: (e: RadioChangeEvent) => void;
    className?: string;
    disabled?: boolean;
}

export const ButtonGroup: React.FC<IItems> = ({
    items,
    value,
    handleChange,
    className,
    disabled,
}) => {
    const classes = useButtonGroupStyles();

    return (
        <>
            <Radio.Group
                value={value}
                onChange={handleChange}
                className={`${classes.btn} ${className}`}
            >
                {items.map((item: IItem, index) => {
                    return (
                        <Radio.Button key={index} value={item.value} disabled={disabled}>
                            {item.text}
                        </Radio.Button>
                    );
                })}
            </Radio.Group>
        </>
    );
};
