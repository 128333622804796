import { Api } from '@blink/components';

export type Me = {
    name: string;
    organizationName?: string;
    email?: string;
};

export const getMe = () => {
    return Api.getResponse<Me>(`${Api.REACT_APP_ANALYTICS_HTTP_URL}/v1/me`);
};
