import React, { FC, useEffect, useState } from 'react';
import { Api, ButtonStroke, Input } from '@blink/components';
import { Core } from '@walletconnect/core';
import { ICore } from '@walletconnect/types';
import { Web3Wallet, IWeb3Wallet } from '@walletconnect/web3wallet';
import { useDispatch, useSelector } from 'react-redux';
import { WALLET } from '@blink/components/src/types';
import { buildApprovedNamespaces } from '@walletconnect/utils';

import { useWalletConnectStyle } from './WalletConnect.style';
import { Store } from '../../store';
import { ModalWrapper } from '../../components/Modals';
import { addWalletsSaga } from '../../store/wallets/actions';
export let web3wallet: IWeb3Wallet;
export let core: ICore;

const projectId = Api.WC_PROJECT_ID as string;

export const WalletConnect: FC = () => {
    const classes = useWalletConnectStyle();
    const [uri, setUri] = useState('');
    const [marginAccountAddress, setMarginAccountAddress] = useState('');
    const address = useSelector((state: Store) => state.wallets.active.address);
    const dispatch = useDispatch();
    const [proposal, setProposal] = useState<any>(null);
    const [logs, setLogs] = useState<string[]>([]);
    const [, setSessionRequest] = useState<any>(null);

    useEffect(() => {
        (async () => {
            const core: any = new Core({
                logger: 'debug',
                projectId,
                relayUrl: 'wss://relay.walletconnect.com',
            });

            web3wallet = await Web3Wallet.init({
                core,
                metadata: {
                    name: 'React Web3Wallet',
                    description: 'React Web3Wallet for WalletConnect',
                    url: 'https://walletconnect.com/',
                    icons: ['https://avatars.githubusercontent.com/u/37784886'],
                },
            });

            web3wallet.on('session_proposal', (e) => {
                console.log('WC:set_proposal', e);
                setProposal(e);
            });

            web3wallet.on('auth_request', (e) => {
                console.log('WC:auth_request', e);
            });

            web3wallet.on('session_delete', (e) => {
                console.log('WC:sessiont_delete', e);
            });

            web3wallet.on('session_request', (e) => {
                console.log('WC:session_request', e);
                setSessionRequest(e);
            });
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (proposal) {
                const { id, params } = proposal;
                const { relays } = params;

                const namespaces = buildApprovedNamespaces({
                    proposal: params,
                    supportedNamespaces: {
                        eip155: {
                            accounts: [
                                `eip155:5:${'0x8355a41154797d888ff832ed86710e04ff60664c'}`,
                                `eip155:1:${'0x8355a41154797d888ff832ed86710e04ff60664c'}`,
                            ],
                            chains: ['eip155:5', 'eip155:1'],
                            events: ['chainChanged', 'accountsChanged'],
                            methods: ['eth_sendTransaction', 'personal_sign'],
                        },
                    },
                });

                await web3wallet.approveSession({
                    id,
                    relayProtocol: relays[0].protocol,
                    namespaces,
                });
                setLogs([...logs, `Connected wallet - ${address}`]);
                setProposal(null);
            }
        })();
    }, [address, proposal]);

    const handlePair = async () => {
        const bla = await web3wallet.core.pairing.pair({ uri, activatePairing: true });
        await web3wallet.core.pairing.activate({ topic: bla.topic });
    };

    const getContent = () => {
        if (!address) {
            return (
                <ButtonStroke
                    onClick={() => {
                        dispatch(addWalletsSaga(WALLET.META_MASK));
                    }}
                    className={classes.button}
                >
                    Connect MetaMask Wallet
                </ButtonStroke>
            );
        }

        return (
            <>
                <div>
                    <div className={classes.label}>Margin account address</div>
                    <Input
                        value={marginAccountAddress}
                        onChange={(e) => setMarginAccountAddress(e.target.value)}
                    />
                </div>
                <div>
                    <div className={classes.label}>Wallet connect ID</div>
                    <Input value={uri} onChange={(e) => setUri(e.target.value)} />
                </div>
                <ButtonStroke onClick={handlePair} className={classes.button}>
                    Connect
                </ButtonStroke>
            </>
        );
    };

    return (
        <div className={classes.root}>
            <ModalWrapper />
            {getContent()}
            {logs.length > 0 && (
                <div>
                    <h3>Logs</h3>
                    {logs.map((log, index) => (
                        <div key={index} className={classes.log}>
                            {log}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
