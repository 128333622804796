import { put, select, call, takeEvery } from 'redux-saga/effects';
import { Errors } from '@blink/components/src/constants/errors';

import { Store } from '../store';
import { userBorrowsErrorAction } from 'src/store/errors/actions';
import { Agreement, BorrowStatusType, getUserBorrows, ROLE, UserBorrow } from 'src/api/borrow';
import {
    setUserBorrowsOpenedAction,
    setUserBorrowsClosedAction,
    setUserBorrowsRegisteredAction,
    setUserBorrowsSuspendedAction,
} from 'src/store/borrows/actions';
import {
    GET_USER_BORROWS_CLOSED,
    GET_USER_BORROWS_OPENED,
    GET_USER_BORROWS_REGISTERED,
    GET_USER_BORROWS_SUSPENDED,
} from 'src/store/borrows/constants';

function* fetchUserBorrows(
    statusToFetch: BorrowStatusType,
    successAction: (arg0: UserBorrow[]) => any,
) {
    const status = statusToFetch;
    try {
        const { walletAddress, agreements } = yield select((state: Store) => ({
            walletAddress: state.wallets.active.address,
            agreements: state.agreements.agreements,
        }));

        const userBorrows: UserBorrow[] = yield call(getUserBorrows, {
            walletAddress,
            status,
        });

        if (!userBorrows || userBorrows.length === 0 || !agreements || agreements.length === 0) {
            yield put(successAction([] as UserBorrow[]));
            return;
        }

        userBorrows.forEach((item) => {
            const agr = agreements.find((agreement: Agreement) => {
                return (
                    agreement.address === item.agreementAddress && agreement.role === ROLE.BORROWER
                );
            });

            if (!agr) {
                item.apy = 'N/A';
            } else {
                item.apy = agr.apy;
            }
        });

        yield put(successAction(userBorrows as UserBorrow[]));
    } catch (e: any) {
        yield put(successAction([] as UserBorrow[]));
        yield put(userBorrowsErrorAction({ message: e.message || Errors.USER_BORROWS }));
        console.error(e);
    }
}

function* getUserBorrowsOpenedSaga() {
    yield call(fetchUserBorrows, BorrowStatusType.OPENED, setUserBorrowsOpenedAction);
}

function* getUserBorrowsSuspendedSaga() {
    yield call(fetchUserBorrows, BorrowStatusType.SUSPENDED, setUserBorrowsSuspendedAction);
}

function* getUserBorrowsRegisteredSaga() {
    yield call(fetchUserBorrows, BorrowStatusType.REGISTERED, setUserBorrowsRegisteredAction);
}

function* getUserBorrowsClosedSaga() {
    yield call(fetchUserBorrows, BorrowStatusType.CLOSED, setUserBorrowsClosedAction);
}

export function* borrowsWatcher() {
    yield takeEvery(GET_USER_BORROWS_OPENED, getUserBorrowsOpenedSaga);
    yield takeEvery(GET_USER_BORROWS_SUSPENDED, getUserBorrowsSuspendedSaga);
    yield takeEvery(GET_USER_BORROWS_REGISTERED, getUserBorrowsRegisteredSaga);
    yield takeEvery(GET_USER_BORROWS_CLOSED, getUserBorrowsClosedSaga);
}
