import React, { FC, useState } from 'react';

import { Layout } from '../../components/Layout/Layout';
import { GuideInfo } from '../../components/GuideInfo/GuideInfo';
import { LeverageScreenButtonGroup } from '../../components/Lend/Lend';
import { FixedRateMenu } from '../../components/FixedRateMenu/FixedRateMenu';
import { Faqs } from '../../components/Faqs/Faqs';
import { UserBorrows } from '../../components/Borrow/UserBorrows';
import { FixedRateMenuKey } from '../../components/FixedRateMenu/constants';
import { AssetsToClaim } from '../../components/AssetsToClaim/AssetsToClaim';
import { AssetsToBorrowSection } from 'src/components/AssetsToBorrow/AssetsToBorrowSection';

export const Borrow: FC = () => {
    const [selectedRate, setSelectedRate] = useState(FixedRateMenuKey.FIXED_RATE);

    return (
        <div>
            <Layout isBorder>
                <GuideInfo />
                <LeverageScreenButtonGroup />
                <FixedRateMenu selected={selectedRate} setSelected={setSelectedRate} />
            </Layout>
            <Layout>
                <UserBorrows />
                <AssetsToClaim />
                <AssetsToBorrowSection selectedRate={selectedRate} />
            </Layout>
            <Faqs />
        </div>
    );
};
