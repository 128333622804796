import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';

export const useMarginAccountDisconnectionModalStyle = createUseStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        margin: `${spacing['07']} 0 ${spacing['03']}`,
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 432,
        height: 192,
        padding: `${spacing['07']} 0 ${spacing['08']}`,
        flexDirection: 'column',
        gap: spacing['05'],
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: spacing['04'],
        gap: spacing['06'],
    },
    text: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: `0 ${spacing['03']}`,
        flexDirection: 'column',
        color: color.content.secondary,
        textAlign: 'center',
        fontSize: fontSize.lg,
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '-0.34px',
    },
    buttonWrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        '& button': {
            height: 44,
            width: '100%',
            fontSize: fontSize.md,
        },
    },
});
