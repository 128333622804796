export const GET_USER_BORROWS_OPENED = 'GET_USER_BORROWS_OPENED';
export const SET_USER_BORROWS_OPENED = 'SET_USER_BORROWS_OPENED';

export const GET_USER_BORROWS_SUSPENDED = 'GET_USER_BORROWS_SUSPENDED';
export const SET_USER_BORROWS_SUSPENDED = 'SET_USER_BORROWS_SUSPENDED';

export const GET_USER_BORROWS_REGISTERED = 'GET_USER_BORROWS_REGISTERED';
export const SET_USER_BORROWS_REGISTERED = 'SET_USER_BORROWS_REGISTERED';

export const GET_USER_BORROWS_CLOSED = 'GET_USER_BORROWS_CLOSED';
export const SET_USER_BORROWS_CLOSED = 'SET_USER_BORROWS_CLOSED';

export const SET_USER_BORROWS_MAINTENANCE = 'SET_USER_BORROWS_MAINTENANCE';
export const SET_USER_BORROWS_MAINTENANCE_HISTORY = 'SET_USER_BORROWS_MAINTENANCE_HISTORY';
