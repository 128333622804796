import { createUseStyles } from 'react-jss';
import { color, spacing } from '@blink/components';

export const useGuideInfoStyle = createUseStyles({
    root: {
        display: 'none', //stabilization
        marginTop: 32,
        position: 'relative',
        color: color.content.primary,
        //display: 'flex', //stabilization
        alignItems: 'center',
        background: color.background.secondary,
        border: `1px solid ${color.border}`,
        padding: spacing['04'],
        height: '64px',
        justifyContent: 'center',
    },
    closeIcon: {
        position: 'absolute',
        cursor: 'pointer',
        right: 12,
        '&:hover': {
            '& path': {
                fillOpacity: '1',
            },
        },
    },
});
