import React, { FC } from 'react';

import { CollateralAssetCard } from './CollateralAssetCard';
import { useCollateralAssetStyle } from './CollateralAsset.style';
import { Token } from 'src/api/borrow';
import { SelectedBorrowAsset } from '../BorrowModal';
import { CollateralAssetSelectedCard } from './CollateralAssetSelectedCard';

type CollateralAssetsSectionProps = {
    assetName: string;
    assets: Token[];
    selectedAssets: SelectedBorrowAsset[];
    setSelectedAssets: (selectedAssets: SelectedBorrowAsset[]) => void;

    disabled?: boolean;
    classes?: Partial<Record<CollateralAssetClasses, string>>;
    assetAmount?: string;
    assetSymbol?: string;
};

type CollateralAssetClasses = 'root';

export const CollateralAssetsSection: FC<CollateralAssetsSectionProps> = ({
    setSelectedAssets,
    assets,
    classes,
    assetName,
    selectedAssets,
    disabled,
    assetAmount,
    assetSymbol,
}) => {
    const baseClasses = useCollateralAssetStyle();

    const handleSubmit = (asset: SelectedBorrowAsset) => {
        if (Number(asset.amount) === 0) {
            setSelectedAssets(selectedAssets.filter((item) => item.name !== asset.name));
            return;
        }

        const newSelectedAssets = [...selectedAssets];
        const assetAlreadySelectedIndex = newSelectedAssets.findIndex(
            (item) => item.name === asset.name,
        );

        if (assetAlreadySelectedIndex !== -1) {
            newSelectedAssets[assetAlreadySelectedIndex] = {
                ...newSelectedAssets[assetAlreadySelectedIndex],
                amount: asset.amount,
                amountInAssetToBorrow: asset.amountInAssetToBorrow,
            };
        } else {
            newSelectedAssets.push(asset);
        }
        setSelectedAssets(newSelectedAssets);
    };

    return (
        <div className={classes?.root}>
            <div className={baseClasses?.header}>
                <div>Select a token to use as a collateral</div>
                <div>Balances</div>
            </div>
            {selectedAssets.length > 0 && (
                <CollateralAssetSelectedCard
                    assets={selectedAssets}
                    assetAmount={assetAmount}
                    assetSymbol={assetSymbol}
                />
            )}
            <div className={baseClasses.assetsWrapper}>
                {assets.map((asset) => {
                    return (
                        <CollateralAssetCard
                            key={asset.id}
                            assetToBorrowName={assetName}
                            amount={asset.balance || ''}
                            name={asset.name}
                            address={asset.address || ''}
                            onSubmit={handleSubmit}
                            selectedInfo={selectedAssets.find(
                                (selectedAsset) => selectedAsset.name === asset.name,
                            )}
                            disabled={disabled}
                            symbol={asset.symbol}
                            decimals={asset.decimals || 0}
                        />
                    );
                })}
            </div>
        </div>
    );
};
