import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';

export const useConnectWalletModalStyle = createUseStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        gap: spacing['04'],
        minWidth: 430,
    },
    bottomContent: {
        marginTop: spacing['04'],
    },
    title: {
        fontSize: fontSize.lg,
        fontWeight: 500,
        margin: `${spacing['07']} 0 ${spacing['05']}`,
    },
    button: {
        fontSize: fontSize.lg,
    },
    checkbox: {
        padding: '30px 0 38px',
        borderBottom: `1px solid ${color.content.stateDisable}`,
        marginBottom: spacing['07'],
        display: 'flex',
        alignItems: 'center',
    },
    tip: {
        paddingTop: spacing['05'],
        color: color.content.secondary,
        textAlign: 'center',
    },
    walletLink: {
        color: color.content.accent,
        marginLeft: spacing['02'],
        fontWeight: 600,
    },
    checkboxText: {
        color: color.content.secondary,
    },
    checkboxLink: {
        color: color.content.primary,
    },
});
