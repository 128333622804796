import React, { FC } from 'react';
import { ReactComponent as EthereumIcon } from '@blink/assets/src/icons/ethereum.svg';
import { ReactComponent as BinanceIcon } from '@blink/assets/src/icons/binanceLogo.svg';
import { ReactComponent as PolygonIcon } from '@blink/assets/src/icons/polygon.svg';
import { ReactComponent as ArbitrumIcon } from '@blink/assets/src/icons/arbitrum.svg';
import { NETWORK } from '@blink/components/src/constants/global';
import { useDispatch } from 'react-redux';

import { changeNetworkSagaAction } from '../../../store/wallets/actions';
import { SelectableCard } from '../../SelectableCard';
import { useUnsupportedNetworkModalStyle } from './UnsupportedNetworkModal.style';

export const UnsupportedNetworkModal: FC = () => {
    const dispatch = useDispatch();
    const classes = useUnsupportedNetworkModalStyle();

    const handleEthereumNetworkChange = () => {
        dispatch(changeNetworkSagaAction(NETWORK.ETHEREUM));
    };
    const handleBinanceNetworkChange = () => {
        dispatch(changeNetworkSagaAction(NETWORK.BNB));
    };
    const handlePolygonNetworkChange = () => {
        dispatch(changeNetworkSagaAction(NETWORK.POLYGON));
    };
    const handleArbitrumNetworkChange = () => {
        dispatch(changeNetworkSagaAction(NETWORK.ARBITRUM));
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.title}>Unsupported Network!</div>
            <div className={classes.subtitle}>
                Please switch your wallet to the following networks
            </div>
            <div className={classes.cardsWrapper}>
                <SelectableCard
                    icon={<EthereumIcon />}
                    name='Ethereum'
                    onClick={handleEthereumNetworkChange}
                />
                <SelectableCard
                    icon={<BinanceIcon />}
                    name='Binance Smart Chain'
                    onClick={handleBinanceNetworkChange}
                />
                <SelectableCard
                    icon={<PolygonIcon />}
                    name='Polygon'
                    onClick={handlePolygonNetworkChange}
                />
                <SelectableCard
                    icon={<ArbitrumIcon />}
                    name='Arbitrum'
                    onClick={handleArbitrumNetworkChange}
                />
            </div>
        </div>
    );
};
