export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export enum ModalName {
    CONNECT_WALLET_MODAL = 'CONNECT_WALLET_MODAL',
    METAMASK_NOT_INSTALLED_MODAL = 'METAMASK_NOT_INSTALLED_MODAL',
    COINBASE_NOT_INSTALLED_MODAL = 'COINBASE_NOT_INSTALLED_MODAL',
    RABBY_NOT_INSTALLED_MODAL = 'RABBY_NOT_INSTALLED_MODAL',
    UNSUPPORTED_NETWORK_MODAL = 'UNSUPPORTED_NETWORK_MODAL',
    LIQUIDITY_MODAL = 'LIQUIDITY_MODAL',
    WALLET_INFO_MODAL = 'WALLET_INFO_MODAL',
    BORROW_MODAL = 'BORROW_MODAL',
    OPERATION_STATUS_MODAL = 'OPERATION_STATUS_MODAL',
    WALLET_CONNECT = 'WALLET_CONNECT',
    REGISTER_MARGIN_ACCOUNT_MODAL = 'REGISTER_MARGIN_ACCOUNT_MODAL',
    MA_DISCONNECTION = 'MA_DISCONNECTION',
    FUND_CLAIM_MODAL = 'FUND_CLAIM_MODAL',
}
