import React, { FC, useState } from 'react';
import { ReactComponent as MetamaskIcon } from '@blink/assets/src/icons/metamask.svg';
import { ReactComponent as RabbyIcon } from '@blink/assets/src/icons/rabby.svg';
import { ReactComponent as WalletConnectIcon } from '@blink/assets/src/icons/walletConnect.svg';
import { ButtonStroke, Checkbox } from '@blink/components';
import { WALLET } from '@blink/components/src/types';
import { useDispatch } from 'react-redux';
import { walletMap } from '@blink/components/src/constants/wallet';
import { INSTALL_METAMASK_URL } from '@blink/components/src/constants/global';
import { ButtonSize } from '@blink/components/src/Button/Button.types';

import { useConnectWalletModalStyle } from './ConnectWalletModal.style';
import { useHideModal } from '../ModalWrapperHooks';
import { addWalletsSaga } from '../../../store/wallets/actions';
import { showModalAction } from '../../../store/modals/actions';
import { SelectableCard } from '../../SelectableCard';
import { ModalName } from '../../../store/modals/constants';

export const ConnectWalletModal: FC = () => {
    const [selectedWallet, setSelectedWallet] = useState<WALLET | null>(null);
    const [termsAccepted, setTermsAccepted] = useState(true);
    const classes = useConnectWalletModalStyle();
    const { handleCloseModal } = useHideModal();
    const dispatch = useDispatch();
    const isMetaMaskWalletInstalled: boolean =
        walletMap.get(WALLET.META_MASK)?.isAvailable() || false;
    const isRabbyBaseWalletInstalled: boolean = walletMap.get(WALLET.RABBY)?.isAvailable() || false;

    const handleTermsAccept = () => setTermsAccepted(!termsAccepted);
    const handleMetamaskSelect = () => setSelectedWallet(WALLET.META_MASK);
    const handleRabbySelect = () => setSelectedWallet(WALLET.RABBY);
    const handleWalletConnectSelect = () => setSelectedWallet(WALLET.WALLET_CONNECT);

    const handleConnect = () => {
        if (!selectedWallet) {
            return;
        }

        if (selectedWallet === WALLET.META_MASK && !isMetaMaskWalletInstalled) {
            return dispatch(showModalAction({ modalName: ModalName.METAMASK_NOT_INSTALLED_MODAL }));
        }

        if (selectedWallet === WALLET.RABBY && !isRabbyBaseWalletInstalled) {
            return dispatch(showModalAction({ modalName: ModalName.RABBY_NOT_INSTALLED_MODAL }));
        }
        dispatch(addWalletsSaga(selectedWallet));
        handleCloseModal();
    };

    return (
        <div className={classes.root}>
            <div className={classes.title}>Choose Wallet</div>
            <div className={classes.content}>
                <SelectableCard
                    isActive={selectedWallet === WALLET.META_MASK}
                    icon={<MetamaskIcon />}
                    name='Metamask'
                    onClick={handleMetamaskSelect}
                />
                <SelectableCard
                    isActive={selectedWallet === WALLET.WALLET_CONNECT}
                    icon={<WalletConnectIcon />}
                    name='WalletConnect'
                    onClick={handleWalletConnectSelect}
                />
                <SelectableCard
                    isActive={selectedWallet === WALLET.RABBY}
                    icon={<RabbyIcon />}
                    name='Rabby'
                    onClick={handleRabbySelect}
                />
            </div>
            <Checkbox
                checked={termsAccepted}
                onChange={handleTermsAccept}
                className={classes.checkbox}
            >
                <div className={classes.checkboxText}>
                    Accept <a className={classes.checkboxLink}>Terms of Service</a>
                </div>
            </Checkbox>
            <ButtonStroke
                className={classes.button}
                isPrimary
                size={ButtonSize.xl}
                disabled={!selectedWallet || !termsAccepted}
                onClick={handleConnect}
            >
                Connect Wallet
            </ButtonStroke>
            <div className={classes.tip}>
                Don’t have an account yet?
                <a href={INSTALL_METAMASK_URL} className={classes.walletLink}>
                    Learn how to connect
                </a>
            </div>
        </div>
    );
};
