import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from 'src/components/AuthProvider';

interface ProtectedRouteProps {
    children: ReactNode;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const { isLoggedIn } = useAuth();

    if (!isLoggedIn) {
        return <Navigate to='/' replace />;
    }

    return <>{children}</>;
};
