import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Api, ButtonStroke, UserControls } from '@blink/components';

import { reconnectIfWalletAvailableSaga } from 'src/store/wallets/actions';
import { useConnectWalletStyles } from './ConnectWallet.style';
import { LoggedInProvider } from '../LoggedInProvider';
import { SelectNetwork } from '../SelectNetwork';
import { ConnectedWalletInfo } from './ConnectWalletInfo';
import { showModalAction } from '../../store/modals/actions';
import { ModalName } from '../../store/modals/constants';
import { getMeAction } from 'src/store/me/actions';
import { useProviders } from 'src/context/provider';
import { useAuth } from '../AuthProvider';
import { Store } from 'src/store';

interface ConnectWalletProps {
    handleSetBannerState: (value: boolean) => void;
}

export const ConnectWallet: React.FC<ConnectWalletProps> = ({ handleSetBannerState }) => {
    const providers = useProviders();
    const classes = useConnectWalletStyles();
    const dispatch = useDispatch();

    const { isLoggedIn } = useAuth();

    const { wallet } = useSelector((state: Store) => ({
        wallet: state.wallets.active.address,
    }));

    useEffect(() => {
        isLoggedIn && dispatch(reconnectIfWalletAvailableSaga());
    }, [isLoggedIn, providers]);

    useEffect(() => {
        dispatch(getMeAction());
    }, []);

    const handleConnectWalletClick = () =>
        dispatch(
            showModalAction({
                modalName: ModalName.CONNECT_WALLET_MODAL,
                modalTitle: 'Connect Wallet',
            }),
        );

    const handleSignIn = async () => {
        window.location.href = `${Api.API_AUTH_ENDPOINT}/start?rd=${window.location}`;
    };

    return (
        <div className={classes.block}>
            <div className={classes.wrapper}>
                {wallet && (
                    <LoggedInProvider
                        haveAuth={<SelectNetwork handleShowBanner={handleSetBannerState} />}
                    />
                )}
            </div>
            <div className={classes.wrapper}>
                {wallet && <LoggedInProvider haveAuth={<ConnectedWalletInfo />} />}
            </div>

            {!wallet && (
                <div className={classes.wrapper}>
                    <LoggedInProvider
                        haveAuth={
                            <ButtonStroke
                                onClick={handleConnectWalletClick}
                                className={classes.walletButton}
                            >
                                Connect Wallet
                            </ButtonStroke>
                        }
                    />
                </div>
            )}

            <div className={classes.wrapper}>
                <LoggedInProvider
                    haveAuth={<UserControls />}
                    haveNoAuth={
                        <ButtonStroke
                            isPrimary
                            onClick={handleSignIn}
                            className={classes.walletButton}
                        >
                            Sign In
                        </ButtonStroke>
                    }
                />
            </div>
        </div>
    );
};
