import React, { FC } from 'react';
import { InputNumber, Spin } from 'antd';
import { AssetWithIcon } from '@blink/components';
import { concatClasses } from '@blink/components/src/utils';

import { useLiquidityAssetInputStyle } from './AssetInput.style';

type AssetInputProps = {
    assetName: string;
    balance: number;
    value?: number;
    setValue: (value: number) => void;
    maxBorrowLoading?: boolean;
    disabled?: boolean;
    customTitle?: string;
    customBalanceLabel?: string;
    classes?: Partial<Record<AssetInputClasses, string>>;
    loading?: boolean;
};

type AssetInputClasses = 'root';

export const AssetInput: FC<AssetInputProps> = ({
    value,
    setValue,
    assetName,
    balance,
    classes,
    customBalanceLabel,
    customTitle,
    disabled,
    maxBorrowLoading,
}) => {
    const baseClasses = useLiquidityAssetInputStyle();

    const handleAmountChange = (value: number | null) => setValue(Number(value));
    const setMaxAmount = () => handleAmountChange(balance);
    const getMaxBorrow = () => {
        return maxBorrowLoading ? (
            <div>
                <Spin size='small' />
            </div>
        ) : (
            <div>({balance})</div>
        );
    };
    return (
        <div className={concatClasses(baseClasses.root, classes?.root || '')}>
            <div className={baseClasses.header}>
                <div>{customTitle ?? 'You Deposit'}</div>
                <div className={baseClasses.balanceWrapper}>
                    <div onClick={setMaxAmount} className={baseClasses.balanceName}>{`${
                        customBalanceLabel ?? 'Balance'
                    } `}</div>
                    <div className={baseClasses.balance}>{getMaxBorrow()}</div>
                </div>
            </div>
            <div className={baseClasses.footer}>
                <div>
                    <AssetWithIcon
                        assetName={assetName || ''}
                        classes={{ assetName: baseClasses.assetName }}
                    />
                </div>
                <div className={baseClasses.input}>
                    <InputNumber
                        type='number'
                        autoFocus
                        min={0}
                        placeholder={'0'}
                        max={balance}
                        controls={false}
                        value={value}
                        onChange={handleAmountChange}
                        disabled={disabled}
                    />
                </div>
            </div>
        </div>
    );
};
