import React from 'react';

import { useFooterStyle } from './Footer.style';

export const Footer: React.FC = () => {
    const classes = useFooterStyle();

    return (
        <div className={classes.root}>
            <div>Arkis © 2024. All rights reserved.</div>
            <div className={classes.links}>
                <a>Terms of Use</a>
                <a>Privacy Policy</a>
            </div>
        </div>
    );
};
