import React, { FC, useState } from 'react';
import { AssetWithIcon, InputNumberCustom } from '@blink/components';
import { concatClasses } from '@blink/components/src/utils';

import { useLiquidityAssetInputStyle } from './AssetInputSupply.style';

type AssetInputSupplyProps = {
    tokenSymbol: string;
    balance: string;
    value?: string;
    setValue: (value: any) => void;
    disabled?: boolean;
    customBalanceLabel?: string;
    classes?: Partial<Record<AssetInputSupplyClasses, string>>;
    isSupply?: boolean;
    borrowApy?: string;
    decimals: number;
};

type AssetInputSupplyClasses = 'root';

export const AssetInputSupply: FC<AssetInputSupplyProps> = ({
    value,
    setValue,
    tokenSymbol,
    balance,
    classes,
    customBalanceLabel,
    disabled,
    isSupply = true,
    borrowApy,
    decimals,
}) => {
    const baseClasses = useLiquidityAssetInputStyle();
    const [isError, setIsError] = useState<boolean>(false);

    const handleMaxClick = () => {
        setValue(balance);
        setIsError(false);
    };

    const handleAmountChange = (value: number | null) => {
        setValue(value);
        const isValueMoreThanBalance = Number(value) > Number(balance);
        setIsError(isValueMoreThanBalance);
    };

    return (
        <>
            <div
                className={concatClasses(
                    baseClasses.root,
                    classes?.root || '',
                    `${isError && 'error'}`,
                )}
            >
                <div className={baseClasses.footer}>
                    <div className={baseClasses.input}>
                        <InputNumberCustom
                            placeholder={'0'}
                            value={value}
                            handleChange={handleAmountChange}
                            disabled={disabled}
                            decimals={decimals}
                        />
                    </div>
                    <div className={baseClasses.inputWrapper}>
                        <AssetWithIcon
                            assetName={tokenSymbol || ''}
                            classes={{ assetName: baseClasses.assetName }}
                        />
                    </div>
                </div>
            </div>
            <div className={baseClasses.infoWrapper}>
                <div className={baseClasses.balanceWrapper}>
                    <div>{`${customBalanceLabel ?? 'Max Available'}`}</div>
                    <div className={baseClasses.textBtn} onClick={handleMaxClick}>
                        {balance}
                    </div>
                </div>
                {!isSupply && (
                    <div>
                        <div className={baseClasses.borrowApyWrapper}>
                            <div className={baseClasses.borrowApy}>
                                <div>Borrow APY </div>
                            </div>
                            <div className={baseClasses.borrowApyNumber}>{Number(borrowApy)}%</div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
