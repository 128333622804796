import { createUseStyles } from 'react-jss';
import { color, spacing } from '@blink/components';

export const useUserSuppliesRowsStyle = createUseStyles({
    tr: {
        height: 70,
        borderTop: `1px solid ${color.border.opaque}`,
    },
    firstTd: {
        paddingLeft: spacing['06'],
    },
    rewards: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            width: 32,
            height: 32,
            marginRight: spacing['04'],
        },
    },
    secondary: {
        color: color.content.secondary,
    },
    buttonTd: {
        width: '10%',
    },
    button: {
        width: 100,
        margin: `0 ${spacing['06']} 0 auto`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
    },
});
