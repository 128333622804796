import React, { FC, useState } from 'react';
import { InputNumberCustom, TextButton } from '@blink/components';
import { AssetIcon } from '@blink/components/src/Asset/AssetIcon';

import { useCollateralAssetInputStyle } from './CollateralAssetInput.style';
import { compareBigNumbers } from 'src/utils/helpers';

type CollateralAssetInputProps = {
    max: string;
    assetToBorrowName: string;
    onSubmit: (value: string) => void;
    isShown?: boolean;
    decimals: number;
};

export const CollateralAssetInput: FC<CollateralAssetInputProps> = ({
    max,
    assetToBorrowName,
    onSubmit,
    isShown,
    decimals,
}) => {
    const classes = useCollateralAssetInputStyle();
    const [amount, setAmount] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [isMaxError, setIsMaxError] = useState(false);
    const [isInputError, setIsInputError] = useState(false);

    const handleInputChange = (value: string) => {
        if (value === '' || Number(value) === 0) {
            setAmount(value);
            setDisabled(true);
            return;
        }

        const compareResult = compareBigNumbers(max, value, decimals);
        if (compareResult >= 0) {
            setIsMaxError(false);
            setDisabled(false);
            setIsInputError(false);
            setAmount(value);
            return;
        }
        setIsMaxError(true);
        setIsInputError(true);
        setDisabled(true);
    };

    const handleSubmit = () => {
        onSubmit(amount);
    };

    return (
        <div className={isShown ? '' : classes.hidden}>
            <div className={classes.inputSection}>
                <InputNumberCustom
                    className={isInputError ? classes.input + ' error' : classes.input}
                    placeholder={'0'}
                    value={amount}
                    handleChange={handleInputChange}
                    max={max ? max : ''}
                    btnLink={true}
                    decimals={decimals}
                />
                <div className={classes.inputSectionRight}>
                    <div className={isMaxError ? classes.max + ' error' : ''}>{`(${max})`}</div>
                    <div className={classes.inputSectionConclusion}>
                        <div>≈</div>
                        <AssetIcon assetName={assetToBorrowName} className={classes.assetImage} />
                        <TextButton
                            className={classes.submitButton}
                            onClick={handleSubmit}
                            disabled={disabled}
                        >
                            Submit
                        </TextButton>
                    </div>
                </div>
            </div>
        </div>
    );
};
