import { HIDE_MODAL, ModalName, SHOW_MODAL } from './constants';

export type ModalProps = {
    activeModal: ModalName | null;
    modalTitle: string | null;
    modalProps: any;
};

type ModalBaseProps = ModalProps & {
    previousModal: ModalProps | null;
};

const defaultState: ModalBaseProps = {
    activeModal: null,
    modalTitle: null,
    modalProps: null,
    previousModal: null,
};

export default function modals(state = defaultState, action: { type: string; payload: any }) {
    const { type, payload } = action;

    switch (type) {
        case SHOW_MODAL:
            return {
                ...state,
                activeModal: payload.modalName,
                modalTitle: payload.modalTitle,
                modalProps: payload.modalProps,
                previousModal:
                    payload.previousModal !== undefined
                        ? payload.previousModal
                        : state.previousModal,
            };
        case HIDE_MODAL:
            return { ...defaultState };
        default:
            return state;
    }
}
