import React, { FC } from 'react';

import { useAssetWithIconStyle } from './AssetWithIcon.style';
import { concatClasses } from '../../utils';
import { AssetIcon } from '../AssetIcon';

type AssetWithIconProps = {
    assetName: string;
    assetSymbol?: string;

    classes?: Partial<Record<AssetWithIconClasses, string>>;
};

type AssetWithIconClasses = 'root' | 'assetName' | 'icon';

export const AssetWithIcon: FC<AssetWithIconProps> = ({ assetName, assetSymbol, classes }) => {
    const mainClasses = useAssetWithIconStyle();

    return (
        <div className={concatClasses(mainClasses.root, classes?.root || '')}>
            <AssetIcon assetName={assetSymbol || assetName} className={mainClasses.image} />
            <div className={classes?.assetName}>{assetName}</div>
        </div>
    );
};
