import * as actions from './actions';
import { SET_TOKENS } from './constants';
import { Token } from 'src/api/borrow';

export type TokensState = {
    tokens: Token[];
};

const defaultState: TokensState = {
    tokens: [],
};

type OrderAction = ReturnType<TInferValueTypes<typeof actions>>;

export default function supplies(state = defaultState, action: OrderAction): TokensState {
    switch (action.type) {
        case SET_TOKENS: {
            return {
                ...state,
                tokens: action.payload,
            };
        }
        default: {
            return state;
        }
    }
}
