import React from 'react';
import { Button } from '@blink/components';
import { ReactComponent as RabbyIcon } from '@blink/assets/src/icons/rabby.svg';
import { RABBY_DOWNLOAD_URL } from '@blink/components/src/constants/global';

import { useModalStyles } from './RabbyNotInstalledModal.style';
import { useHideModal } from '../ModalWrapperHooks';

export const RabbyNotInstalledModal = () => {
    const classes = useModalStyles();

    const { handleCloseModal } = useHideModal();

    const openCoinbaseInstall = () => {
        window.open(RABBY_DOWNLOAD_URL, '_blank', 'noreferrer');
        handleCloseModal();
    };

    return (
        <>
            <div className={classes.wrapper}>
                <div className={classes.icon}>
                    <RabbyIcon />
                </div>
                <div className={classes.title}>Rabby is not installed</div>
                <div className={classes.description}>
                    The Rabby extension is not installed <br /> in your browser
                </div>
                <div className={classes.btn}>
                    <Button name='Install' onClick={openCoinbaseInstall} />
                </div>
            </div>
        </>
    );
};
