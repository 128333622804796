import React from 'react';

import { useAuth } from '../AuthProvider';

export type LoginProviderProps = {
    haveAuth: unknown;
    haveNoAuth?: unknown;
};

/**
 * Logged in provider components switching components
 * in case if user is not logged in or showing nothing by default
 * */
export const LoggedInProvider = ({ haveAuth, haveNoAuth }: LoginProviderProps) => {
    const { isLoggedIn } = useAuth();
    const componentsToShow = isLoggedIn ? haveAuth : haveNoAuth;
    return <>{componentsToShow}</>;
};
