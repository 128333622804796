import React from 'react';
import { ALLOWANCE_STATUS } from '@blink/components/src/types';
import { ReactComponent as SuccessAllowanceIcon } from '@blink/assets/src/icons/succesAllowance.svg';
import { ReactComponent as CautionIcon } from '@blink/assets/src/icons/cautions.svg';
import { ReactComponent as ErrorIcon } from '@blink/assets/src/icons/alert.svg';
import { Spin } from 'antd';

import { useStatusStyle } from './Status.style';

type StatusType = {
    status: ALLOWANCE_STATUS;
};

export const StatusCaution = () => {
    const baseClasses = useStatusStyle();
    return (
        <div className={baseClasses.root}>
            <CautionIcon /> Caution
        </div>
    );
};

export const StatusDone = () => {
    const baseClasses = useStatusStyle();
    return (
        <div className={baseClasses.root}>
            <SuccessAllowanceIcon /> Done
        </div>
    );
};

export const StatusFailed = () => {
    const baseClasses = useStatusStyle();
    return (
        <div className={`${baseClasses.root} ${baseClasses.error}`}>
            <ErrorIcon /> Failed
        </div>
    );
};

export const StatusProcessing = () => {
    const baseClasses = useStatusStyle();
    return (
        <div className={baseClasses.root}>
            <Spin size='small' />
            Processing
        </div>
    );
};

export const StatusWaiting = () => {
    return <div>Awaiting</div>;
};

export const STATUSES = new Map()
    .set(ALLOWANCE_STATUS.SUCCESS, <StatusDone />)
    .set(ALLOWANCE_STATUS.ERROR, <StatusFailed />)
    .set(ALLOWANCE_STATUS.PROCESSING, <StatusProcessing />)
    .set(ALLOWANCE_STATUS.WAITING, <StatusWaiting />)
    .set(ALLOWANCE_STATUS.WARNING, <StatusCaution />);

export const Status = ({ status }: StatusType) => {
    const baseClasses = useStatusStyle();
    const statusComponent = STATUSES.has(status) ? STATUSES.get(status) : null;
    return <div className={baseClasses.root}>{statusComponent}</div>;
};
