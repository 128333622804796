import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';

export const useUserBorrowsCardStyles = createUseStyles({
    root: {
        padding: spacing['05'],
        borderRadius: 4,
        border: `1px solid ${color.border.opaque}`,
        color: color.content.secondary,
        transition: `box-shadow 0.3s ease`,
        '&:hover': {
            color: color.content.secondary,
            boxShadow: `0 0 0 0px ${color.border.opaque}, 0 0 0 2px ${color.border.accent}`,
        },
    },
    connected: {
        border: `1px solid ${color.border.opaque}`,
    },
    connectedTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        color: color.content.primary,
    },
    headerRow: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: spacing['05'],
    },
    assetInfo: {
        display: 'flex',
        gap: spacing['03'],
    },
    assetIcon: {
        width: 32,
        height: 32,
    },
    networkBadge: {
        display: 'flex',
        alignItems: 'center',
        gap: spacing['01'],
        padding: `${spacing['01']} 6px ${spacing['01']} ${spacing['02']}`,
        borderRadius: 4,
        backgroundColor: color.background.accent,
        color: color.content.inversePrimary,
        fontSize: fontSize.sm,
        height: 24,
    },
    footerRow: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    footerRight: {
        display: 'flex',
        gap: spacing['04'],
    },
    footerCell: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: '14px',
        fontSize: fontSize.sm,
        lineHeight: '16px',
    },
    riskFactor: {
        color: color.background.warning,
        fontSize: fontSize.md,
        lineHeight: '20px',
    },
    totalBalance: {
        display: 'flex',
        alignItems: 'baseline',
        lineHeight: '16px',
    },
    totalBalanceFull: {
        marginRight: spacing['02'],
        fontSize: fontSize.lg,
    },
    white: {
        color: color.content.primary,
        fontSize: fontSize.md,
        fontWeight: 'bold',
        lineHeight: '20px',
    },
    emptyRoot: {
        minHeight: 125,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: spacing['03'],
        borderRadius: 4,
        border: `1px dashed ${color.border.opaque}`,
        background: color.background.secondary,
        color: color.content.secondary,
        cursor: 'pointer',
    },
    wallet: {
        display: 'flex',
        fontSize: fontSize.sm,
        '& button': {
            marginTop: -3,
            padding: `0 0 0 ${spacing['02']}`,
            height: 14,
        },
        '& svg': {
            width: 14,
            height: 14,
            color: color.content.secondary,
            '& path': {
                fill: color.content.secondary,
            },
        },
    },
    stressValue: {
        display: 'flex',
        alignItems: 'center',
        lineHeight: '20px',
        '& img': {
            width: 16,
            height: 16,
        },
    },
    stressValueFull: {
        fontSize: fontSize.md,
    },
    stressTestValue: {
        margin: `${spacing['05']} 0 ${spacing['03']}`,
        paddingBottom: spacing['03'],
        borderBottom: `1px solid ${color.border.opaque}`,
    },
    totalValue: {
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
        marginRight: 0,
    },
    notClickable: {
        border: `1px solid ${color.border.opaque}`,
        '&:hover': {
            border: `1px solid ${color.border.opaque}`,
            cursor: 'auto',
        },
    },
    lastCell: {
        alignItems: 'end',
    },
    smallSkeleton: {
        width: `30px !important`,
        '& span': {
            minWidth: `20px !important`,
        },
    },
});
