import { WALLET, WalletAddress } from '@blink/components/src/types';
import { NETWORK } from '@blink/components/src/constants/global';
import { EIP6963ProviderDetail } from '@blink/components/src/utils';

import {
    ADD_WALLETS,
    ADD_PROVIDER,
    ADD_ACTIVE_WALLET,
    ADD_WALLETS_SAGA,
    REMOVE_WALLETS,
    RECONNECT_IF_WALLET_AVAILABLE_SAGA,
    DISCONNECT_WALLET_SAGA,
    ADD_NETWORK,
    CHANGE_NETWORK_SAGA,
    ADD_WALLET_CONNECT,
    SET_WALLET_CONNECT_PAIRING_PROCESSING,
    ADD_WALLET_CONNECT_SAGA,
    DISCONNECT_WALLET_CONNECT_SAGA,
} from './constants';
import { ActiveWallet } from './index';

export type AddWalletsData = { wallets: WalletAddress[]; type: WALLET };
export const addWallets = (data: AddWalletsData) => ({ type: ADD_WALLETS, payload: data });
export const addProvider = (data: EIP6963ProviderDetail) => ({ type: ADD_PROVIDER, payload: data });

export const addNetwork = (data: NETWORK) => ({ type: ADD_NETWORK, payload: data });

export const removeWallets = () => ({ type: REMOVE_WALLETS });
export const addActiveWallet = (data: ActiveWallet) => ({ type: ADD_ACTIVE_WALLET, payload: data });

export const changeNetworkSagaAction = (data: NETWORK) => ({
    type: CHANGE_NETWORK_SAGA,
    payload: data,
});

export const addWalletsSaga = (walletType: WALLET) => ({
    type: ADD_WALLETS_SAGA,
    payload: walletType,
});

export const addWalletConnectSaga = ({ walletAddress, chainId }: any) => ({
    type: ADD_WALLET_CONNECT_SAGA,
    payload: { walletAddress, chainId },
});

export const reconnectIfWalletAvailableSaga = () => ({ type: RECONNECT_IF_WALLET_AVAILABLE_SAGA });
export const disconnectWalletSaga = () => ({ type: DISCONNECT_WALLET_SAGA });
export const disconnectWalletConnectSaga = () => ({ type: DISCONNECT_WALLET_CONNECT_SAGA });

export type SetWalletConnectType = {
    name: string;
    url: string;
    marginAccount: string;
    topic: string;
};

export const setWalletConnect = (payload: SetWalletConnectType) => ({
    type: ADD_WALLET_CONNECT,
    payload,
});
export const setWalletConnectPairingProcessing = (payload?: boolean) => ({
    type: SET_WALLET_CONNECT_PAIRING_PROCESSING,
    payload,
});
