import { createUseStyles } from 'react-jss';

import { color } from '../variables';

export const useCheckboxStyle = createUseStyles({
    root: {
        '& .ant-checkbox .ant-checkbox-inner:after': {
            border: `2px solid ${color.background.primary}`,
            borderLeft: 0,
            borderTop: 0,
        },
        '& .ant-checkbox-checked .ant-checkbox-inner': {
            backgroundColor: `${color.content.tertiary} !important`,
            border: `1px solid ${color.border.accent}`,
        },
        '& .ant-checkbox': {
            alignSelf: 'initial',
            marginRight: 1,
        },
        '& .ant-checkbox-inner, .ant-checkbox-input': {
            transform: 'scale(1.125)',
            border: `1px solid ${color.border.opaque}`,
            backgroundColor: 'transparent',
        },
    },
});
