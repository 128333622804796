import React from 'react';
import { WALLET } from '@blink/components/src/types';
import { ReactComponent as MetamaskIcon } from '@blink/assets/src/icons/metamask.svg';
import { ReactComponent as CoinbaseIcon } from '@blink/assets/src/icons/coinbase.svg';
import { ReactComponent as RabbyIcon } from '@blink/assets/src/icons/rabby.svg';
import { ReactComponent as WalletConnectIcon } from '@blink/assets/src/icons/walletConnect.svg';

import { useWalletIconStyle } from './WalletIcon.style';

export const walletIconMap = new Map()
    .set(WALLET.META_MASK, (root: string) => <MetamaskIcon className={root} />)
    .set(WALLET.RABBY, (root: string) => <RabbyIcon className={root} />)
    .set(WALLET.COIN_BASE, (root: string) => <CoinbaseIcon className={root} />)
    .set(WALLET.WALLET_CONNECT, (root: string) => <WalletConnectIcon className={root} />);

export type WalletIconProps = {
    walletType: WALLET;
};

export const WalletIcon = ({ walletType }: WalletIconProps) => {
    const classes = useWalletIconStyle();
    return walletIconMap.has(walletType) ? walletIconMap.get(walletType)(classes.root) : <></>;
};
