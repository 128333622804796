import React from 'react';

import { useDescriptionStyle } from './Description.style';

interface DescriptionProps {
    description: string;
}

export const Description: React.FC<DescriptionProps> = ({ description }) => {
    const classes = useDescriptionStyle();

    return <div className={classes.description}>{description}</div>;
};
