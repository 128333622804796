import { createUseStyles } from 'react-jss';

import { color, spacing } from '../variables';

export const usePaginationStyles = createUseStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: spacing['03'],
    },
    number: {
        minWidth: 44,
        height: 44,
        fontWeight: 'bold',
        padding: `12px 6px`,
        color: color.content.secondary,
        fontSize: 14,
    },
    activeNumber: {
        borderRadius: 4,
        border: `1px solid ${color.border.opaque}`,
    },
    arrow: {
        padding: `10px 12px`,
        height: 42,
        '& svg > path': {
            fill: color.content.primary,
        },
        '&:disabled': {
            '& svg > path': {
                fill: color.content.secondary,
            },
        },
        '& svg': {
            width: 20,
            height: 20,
        },
    },
    leftArrow: {
        transform: 'rotate(90deg)',
    },
    rightArrow: {
        transform: 'rotate(270deg)',
    },
});
