import React, { FC, ReactNode } from 'react';

import { useLayoutStyle } from './Layout.style';

type LayoutProps = {
    children: ReactNode;

    isBorder?: boolean;
};

export const Layout: FC<LayoutProps> = ({ isBorder, children }) => {
    const classes = useLayoutStyle();

    return <div className={`${classes.root} ${isBorder ? classes.border : null}`}>{children}</div>;
};
