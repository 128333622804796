import { createUseStyles } from 'react-jss';

import { color } from '../variables';

export const useTextButtonStyles = createUseStyles({
    root: {
        color: color.content.tertiary,
        '& > span': {
            textDecoration: 'underline',
        },
    },
});
