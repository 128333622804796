import React, { FC, useEffect, useState } from 'react';
import { ButtonGroup, Pagination } from '@blink/components';
import { useDispatch, useSelector } from 'react-redux';
import { RadioChangeEvent, Select } from 'antd';

import { useUserBorrowsSectionStyle } from './UserBorrowsSection.style';
import { UserBorrowsCard } from './UserBorrowsCard';
import { UserSuppliesConnectWallet, UserSuppliesEmpty } from '../../UserSupplies';
import { Store } from '../../../store';
import { BorrowStatusType, History, MaintenanceData } from '../../../api/borrow';
import { getTransformedUserBorrowList } from 'src/utils/helpers';
import {
    getUserBorrowsClosedAction,
    getUserBorrowsOpenedAction,
    getUserBorrowsRegisteredAction,
    getUserBorrowsSuspendedAction,
} from 'src/store/borrows/actions';
import { useCentrifuge } from 'src/hooks/useCentrifuge';
import { UserBorrowsCardSkeleton } from './UserBorrowsCard/UserBorrowsCardSkeleton';

const PULL_INTERVAL = 15000;

const { Option } = Select;

const borrowStatusList = [
    {
        text: 'Opened',
        value: BorrowStatusType.OPENED,
    },
    {
        text: 'On Hold',
        value: BorrowStatusType.SUSPENDED,
    },
    {
        text: 'Closed',
        value: BorrowStatusType.CLOSED,
    },
];

export type UserBorrowItem = {
    address: string;
    id: number;
    apy: string;
    leverageAddress: string;
    name: string;
    symbol: string;
    decimals: number;
    amount: string;
    openedAt: string;
    maintenance: MaintenanceData;
    history: History<MaintenanceData>[];
};

export const UserBorrows: FC = () => {
    const dispatch = useDispatch();
    const classes = useUserBorrowsSectionStyle();
    const [page, setPage] = useState(0);
    const {
        walletAddress,
        blockchainName,
        marginAccount,
        userBorrowsOpened,
        userBorrowsSuspended,
        userBorrowsRegistered,
        userBorrowsClosed,
        userBorrowsOpenedLoading,
        userBorrowsSuspendedLoading,
        userBorrowsRegisteredLoading,
        userBorrowsClosedLoading,
    } = useSelector((state: Store) => ({
        walletAddress: state.wallets.active?.address,
        blockchainName: state.wallets.network,
        marginAccount: state.wallets.walletConnect?.connectedDapp?.marginAccount,
        userBorrowsOpened: state.borrows.userBorrowsOpened,
        userBorrowsSuspended: state.borrows.userBorrowsSuspended,
        userBorrowsRegistered: state.borrows.userBorrowsRegistered,
        userBorrowsClosed: state.borrows.userBorrowsClosed,
        userBorrowsOpenedLoading: state.borrows.userBorrowsOpenedLoading,
        userBorrowsSuspendedLoading: state.borrows.userBorrowsSuspendedLoading,
        userBorrowsRegisteredLoading: state.borrows.userBorrowsRegisteredLoading,
        userBorrowsClosedLoading: state.borrows.userBorrowsClosedLoading,
    }));
    const [items, setItems] = useState<UserBorrowItem[]>([]);
    const { subscribeToChannel } = useCentrifuge();

    const [selectedBorrowStatusList, setSelectedBorrowStatusList] = useState(
        BorrowStatusType.OPENED,
    );

    const [step, setStep] = useState(3);

    const handleChangeItemsPerPage = (value: number) => {
        setStep(value);
    };

    const handleSelectedBorrowStatusListChange = (e: RadioChangeEvent) => {
        setSelectedBorrowStatusList(e.target.value);
    };

    const updateUserBorrowsState = (status: BorrowStatusType) => {
        switch (status) {
            case BorrowStatusType.OPENED:
                setItems(getTransformedUserBorrowList(userBorrowsOpened));
                userBorrowsOpened.forEach((item) => {
                    subscribeToChannel(`maintenance:${item.address}`);
                });

                break;
            case BorrowStatusType.SUSPENDED:
                setItems(
                    getTransformedUserBorrowList([
                        ...userBorrowsSuspended,
                        ...userBorrowsRegistered,
                    ]),
                );
                break;
            case BorrowStatusType.CLOSED:
                setItems(getTransformedUserBorrowList(userBorrowsClosed));
                break;
        }
    };

    useEffect(() => {
        updateUserBorrowsState(selectedBorrowStatusList);
    }, [
        userBorrowsOpened,
        userBorrowsSuspended,
        userBorrowsRegistered,
        userBorrowsClosed,
        selectedBorrowStatusList,
    ]);

    useEffect(() => {
        const callUserBorrows = () => {
            (async () => {
                if (!walletAddress) {
                    setItems([]);
                    return;
                }
                try {
                    switch (selectedBorrowStatusList) {
                        case BorrowStatusType.OPENED:
                            if (!userBorrowsOpenedLoading) {
                                dispatch(getUserBorrowsOpenedAction());
                            }
                            break;
                        case BorrowStatusType.SUSPENDED:
                            if (!userBorrowsSuspendedLoading) {
                                dispatch(getUserBorrowsSuspendedAction());
                            }
                            if (!userBorrowsRegisteredLoading) {
                                dispatch(getUserBorrowsRegisteredAction());
                            }
                            break;
                        case BorrowStatusType.CLOSED:
                            if (!userBorrowsClosedLoading) {
                                dispatch(getUserBorrowsClosedAction());
                            }
                            break;
                    }
                } catch (e) {
                    setItems([]);
                    console.error(e);
                }
            })();
        };
        callUserBorrows();
        const interval = setInterval(() => {
            callUserBorrows();
        }, PULL_INTERVAL);

        return () => clearInterval(interval);
    }, [walletAddress, blockchainName, selectedBorrowStatusList]);

    const getContent = () => {
        if (!walletAddress) {
            return <UserSuppliesConnectWallet />;
        }
        if (!items.length) {
            let title = 'No opened margin accounts. Crypto awaits!';
            let subtitle = 'Create a new margin account to get started';

            if (selectedBorrowStatusList === BorrowStatusType.SUSPENDED) {
                title = "You currently don't have any margin accounts on hold";
                subtitle = 'Create a new margin account to get started';
            }
            if (selectedBorrowStatusList === BorrowStatusType.CLOSED) {
                title = "You currently don't have any closed margin accounts";
                subtitle = 'Create a new margin account to get started';
            }

            return (
                <UserSuppliesEmpty
                    customButtonName='Borrow'
                    customSubtitle={subtitle}
                    customTitle={title}
                />
            );
        }

        const cards = items
            .map((item, index) => {
                const isOnCurrentPage = index >= page * step && index < page * step + step;

                if (
                    selectedBorrowStatusList === BorrowStatusType.OPENED &&
                    (!item.maintenance || !item.history)
                ) {
                    return isOnCurrentPage ? (
                        <UserBorrowsCardSkeleton
                            key={index}
                            address={item.address}
                            symbol={item.symbol}
                            connectedMarginAccount={marginAccount}
                            status={selectedBorrowStatusList}
                        />
                    ) : null;
                }

                return isOnCurrentPage ? (
                    <UserBorrowsCard
                        key={index}
                        connectedMarginAccount={marginAccount}
                        status={selectedBorrowStatusList}
                        {...item}
                    />
                ) : null;
            })
            .filter(Boolean);

        return (
            <>
                <div className={classes.cardsWrapper}>
                    {cards}
                    {page === 0 && items.length < 3}
                </div>
                <div className={classes.pages}>
                    <div>
                        <span>ITEMS PER PAGE:</span>
                        <Select
                            defaultValue={3}
                            style={{ width: 120 }}
                            onChange={handleChangeItemsPerPage}
                            className={classes.itemsSelector}
                        >
                            <Option value={3}>3</Option>
                            <Option value={6}>6</Option>
                            <Option value={9}>9</Option>
                        </Select>
                    </div>

                    <Pagination
                        currentPage={page}
                        pageSize={step}
                        totalItems={items.length}
                        onChange={setPage}
                        classes={{ root: classes.paginationWrapper }}
                    />
                </div>
            </>
        );
    };

    return (
        <div className={classes.root}>
            {walletAddress && (
                <ButtonGroup
                    items={borrowStatusList}
                    value={selectedBorrowStatusList}
                    handleChange={handleSelectedBorrowStatusListChange}
                    className={classes.borrowRatesListButtons}
                />
            )}

            {getContent()}
        </div>
    );
};
