import React, { FC, useContext, useEffect, useState } from 'react';
import { ReactComponent as ArrowIcon } from '@blink/assets/src/icons/arrow.svg';
import { Api, Button, ButtonStroke } from '@blink/components';
import { ButtonSize } from '@blink/components/src/Button/Button.types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { Contract, ethers, getAddress } from 'ethers';
import { AbstractWallet } from '@blink/components/src/utils';
import { walletMap } from '@blink/components/src/constants/wallet';
import { NETWORK_CHAIN, NETWORK_TO_NETWORK_CHAIN } from '@blink/components/src/constants/global';
import { Message } from '@blink/components/src/constants/messages';

import IERCAbi from 'src/abi/IERCAbi.json';
import { useBorrowDetailsCloseCredit } from './BorrowDetailsCloseCredit.style';
import { Store } from '../../../../../store';
import { CentrifugeContext } from 'src/context/centrifuge';

type BorrowDetailsCloseCreditProps = {
    onCancel: () => void;
    marginAccountAddress: string;
    amount: string;
    accumulatedInterest: string;
    leverageAddress: string;
};

export const BorrowDetailsCloseCredit: FC<BorrowDetailsCloseCreditProps> = ({
    onCancel,
    marginAccountAddress,
    amount,
    accumulatedInterest,
    leverageAddress,
}) => {
    const classes = useBorrowDetailsCloseCredit();
    const centrifuge = useContext(CentrifugeContext);
    const [submitProcessing, setSubmitProcessing] = useState<boolean>(false);
    const { blockchainName, type } = useSelector((state: Store) => ({
        blockchainName: state.wallets.network,
        type: state.wallets.active.type,
    }));
    const [disabled, setDisabled] = useState(false);

    const navigate = useNavigate();

    const network = NETWORK_TO_NETWORK_CHAIN.has(blockchainName)
        ? NETWORK_TO_NETWORK_CHAIN.get(blockchainName)
        : NETWORK_CHAIN.ETHEREUM_NETWORK;

    const getTotalDebt = () => {
        return Number(amount) + Number(accumulatedInterest);
    };

    useEffect(() => {
        (async () => {
            const wallet: AbstractWallet = walletMap.get(type) as AbstractWallet;
            const walletProvider = await wallet.getProvider();
            const provider = new ethers.BrowserProvider(walletProvider, Number(network));
            const signer = await provider.getSigner();

            const leverage = new Contract(leverageAddress, IERCAbi, signer);
            const leverageBalanceOf = await leverage.balanceOf(marginAccountAddress);
            const decimals = await leverage.decimals();
            const leverageBalance = Number(ethers.formatUnits(leverageBalanceOf, decimals));

            if (leverageBalance < getTotalDebt()) {
                setDisabled(true);
            }
        })();
    }, []);

    const closeMarginAccountHandler = async () => {
        if (submitProcessing) {
            return;
        }

        setSubmitProcessing(true);
        const wallet: AbstractWallet = walletMap.get(type) as AbstractWallet;
        const walletProvider = await wallet.getProvider();
        const provider = new ethers.BrowserProvider(walletProvider, Number(network));

        const CLOSE_SIGNATURE_DEFAULT_TTL_SECS = 5 * 60;

        const deadlineInSecs =
            Math.floor(Date.now() / 1000) +
            Number(Api.CLOSE_SIGNATURE_TTL_SECS || CLOSE_SIGNATURE_DEFAULT_TTL_SECS);
        const deadlineUTC = new Date(deadlineInSecs * 1000).toUTCString();
        const messageToSigh = `I give my consent to close the following margin account: ${getAddress(
            marginAccountAddress,
        )}. My signature is valid until ${deadlineUTC}.`;

        const signer = await provider.getSigner();
        const signedMessage = await signer.signMessage(messageToSigh);

        const body = {
            account: marginAccountAddress,
            deadline: deadlineUTC,
            signature: signedMessage,
        };

        centrifuge?.rpc('close_margin_account', body).then(
            function (res) {
                message.success(Message.CLOSE_MARGIN_ACCOUNT_SUCCESS);
                console.log(res);
                setSubmitProcessing(false);
            },
            function (err: string) {
                message.error(err);
                message.error(Message.CLOSE_MARGIN_ACCOUNT_ERROR);
                setSubmitProcessing(false);
            },
        );

        navigate(`/borrow`);
    };

    return (
        <>
            <Button type='text' className={classes.title} onClick={onCancel}>
                <ArrowIcon />
                <div>Close Credit Account</div>
            </Button>
            <div className={classes.totalDebt}>
                <div className={classes.listLeft}>
                    <div>Total debt</div>
                    <div className={classes.listText}>Debt + interest accrued</div>
                </div>
                <div>{`${getTotalDebt()}`}</div>
            </div>
            <ButtonStroke
                size={ButtonSize.xl}
                className={classes.button}
                isSecondary
                loading={submitProcessing}
                onClick={closeMarginAccountHandler}
                disabled={disabled}
            >
                Proceed
            </ButtonStroke>
        </>
    );
};
