import React, { createContext, JSX } from 'react';
import { Centrifuge } from 'centrifuge';

import { useCentrifuge } from 'src/hooks/useCentrifuge';

type CentrifugeProviderProps = {
    children: JSX.Element;
};

export const CentrifugeContext = createContext<Centrifuge | null>(null);

export const CentrifugeProvider = ({ children }: CentrifugeProviderProps) => {
    const { centrifuge } = useCentrifuge();
    return <CentrifugeContext.Provider value={centrifuge}>{children}</CentrifugeContext.Provider>;
};
