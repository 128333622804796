import { WalletAddress } from '../../types';
import { SendTransactionProps } from './types';
import { NETWORK, NETWORK_CHAIN } from '../../constants/global';

export abstract class AbstractWallet {
    public abstract isAvailable(): boolean;

    public abstract getProvider(): Promise<any>;

    public abstract isConnected(): Promise<boolean>;

    public abstract connect(): Promise<WalletAddress[]>;

    public abstract disconnect(): Promise<void>;

    public abstract getNetwork(): Promise<NETWORK_CHAIN>;

    public abstract changeNetwork(network: NETWORK): Promise<any>;

    public abstract sendTransaction(data: SendTransactionProps): Promise<void>;

    public abstract getChainId(): Promise<string>;
}
