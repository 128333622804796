import React from 'react';
import { ReactComponent as ArrowIcon } from '@blink/assets/src/icons/arrowRight.svg';
import { ReactComponent as SuccessAllowanceIcon } from '@blink/assets/src/icons/succesAllowance.svg';
import { Spin } from 'antd';
import { ALLOWANCE_STATUS } from '@blink/components/src/types';

import { useAllowanceStepStyle } from './AllowanceStep.style';

type AllowanceStepType = {
    title: string;
    amount: string;
    isLoading?: boolean;
    status: ALLOWANCE_STATUS;
    border?: boolean;
};

export const AllowanceStep = ({ amount, title, isLoading, status, border }: AllowanceStepType) => {
    const baseClasses = useAllowanceStepStyle();
    return (
        <div className={`${baseClasses.root} ${border && baseClasses.border}`}>
            {isLoading && status === ALLOWANCE_STATUS.PROCESSING && <Spin size='small' />}
            {status === ALLOWANCE_STATUS.SUCCESS && <SuccessAllowanceIcon />}
            <section className={baseClasses.main}>
                <span>{title}</span>
                <span className={baseClasses.mainAmount}>
                    <ArrowIcon />
                    {amount}
                </span>
            </section>
        </div>
    );
};
