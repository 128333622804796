import React, { FC } from 'react';
import { concatClasses, copyToClipboard, truncate } from '@blink/components/src/utils';
import { NETWORK, NETWORK_READABLE_NAME } from '@blink/components/src/constants/global';
import { ReactComponent as BinanceIcon } from '@blink/assets/src/icons/binanceLogo.svg';
import { ReactComponent as EthereumIcon } from '@blink/assets/src/icons/ethereumWhite.svg';
import { ReactComponent as PolygonIcon } from '@blink/assets/src/icons/polygonLogo.svg';
import { ReactComponent as ArbitrumIcon } from '@blink/assets/src/icons/arbitrumLogo.svg';
import { ReactComponent as CopyIcon } from '@blink/assets/src/icons/copy.svg';
import { AssetIcon } from '@blink/components/src/Asset/AssetIcon';
import { TextButton } from '@blink/components';
import { message } from 'antd';
import SkeletonInput from 'antd/es/skeleton/Input';

import { useUserBorrowsCardStyles } from './UserBorrowsCard.styles';
import { BorrowStatusType } from 'src/api/borrow';

type UserBorrowsCardSkeleton = {
    connectedMarginAccount?: string;
    status: BorrowStatusType;
    address: string;
    symbol: string;
};

export const UserBorrowsCardSkeleton: FC<UserBorrowsCardSkeleton> = ({
    address,
    symbol,
    connectedMarginAccount,
    status,
}) => {
    const classes = useUserBorrowsCardStyles();

    const iconMap = new Map()
        .set(NETWORK.ETHEREUM, <EthereumIcon />)
        .set(NETWORK.BNB, <BinanceIcon />)
        .set(NETWORK.ARBITRUM, <ArbitrumIcon />)
        .set(NETWORK.POLYGON, <PolygonIcon />);

    const isBorrowConnected = connectedMarginAccount === address;

    const handleCopyAddress = (e: any) => {
        e.preventDefault();
        copyToClipboard(address);
        message.success('Copied to clipboard!');
    };

    return (
        <div
            className={`${classes.root} ${isBorrowConnected && classes.connected} ${
                status !== BorrowStatusType.OPENED && classes.notClickable
            }`}
        >
            <div className={classes.headerRow}>
                <div className={classes.assetInfo}>
                    <AssetIcon assetName={symbol} className={classes.assetIcon} />
                    <div>
                        <div className={classes.white}>
                            <div className={classes.white}>{symbol}</div>
                        </div>
                        <div className={classes.wallet}>
                            {truncate({ value: address, startSymbols: 6 })}
                            <TextButton onClick={handleCopyAddress}>
                                <CopyIcon />
                            </TextButton>
                        </div>
                    </div>
                </div>
                <div className={classes.networkBadge}>
                    {iconMap.get(NETWORK.ETHEREUM)}
                    <div>{NETWORK_READABLE_NAME.get(NETWORK.ETHEREUM)}</div>
                </div>
            </div>
            <div className={concatClasses(classes.footerRow, classes.stressTestValue)}>
                <div className={classes.footerCell}>
                    <>
                        <div>Stress-tested value</div>
                        <div className={classes.stressValue}>
                            <div className={concatClasses(classes.white, classes.stressValueFull)}>
                                <SkeletonInput className={classes.smallSkeleton} size='small' />
                            </div>
                        </div>
                    </>
                </div>
                <div className={classes.footerRight}>
                    <div className={classes.footerCell}>
                        <div>Borrow APR</div>
                        <SkeletonInput className={classes.smallSkeleton} size='small' />
                    </div>
                    <div className={concatClasses(classes.footerCell, classes.lastCell)}>
                        <div>Risk Factor</div>
                        <div className={classes.riskFactor}>
                            <SkeletonInput className={classes.smallSkeleton} size='small' />
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.footerRow}>
                <div className={concatClasses(classes.footerCell, classes.totalValue)}>
                    <div>Total Interest Amount</div>
                    <div className={classes.totalBalance}>
                        <div className={concatClasses(classes.white, classes.totalBalanceFull)}>
                            <SkeletonInput className={classes.smallSkeleton} size='small' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
