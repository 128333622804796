import { createUseStyles } from 'react-jss';
import { color, spacing } from '@blink/components';

export const useAllowanceStepStyle = createUseStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: '6px 0',
        '& > .ant-spin': {
            marginRight: spacing['02'],
        },
        '& > svg': {
            marginRight: spacing['02'],
        },
    },
    border: {
        borderBottom: `1px solid ${color.border.opaque}`,
    },
    main: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    mainAmount: {
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',

        '& > svg': {
            marginRight: spacing['03'],
        },
    },
});
