/* eslint-disable @typescript-eslint/no-unused-vars */
//Commented for now, will be needed for AR-1094
import React, { FC, useEffect, useState } from 'react';
import { Button, color } from '@blink/components';
import {
    Area,
    AreaChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { RadioChangeEvent, Spin } from 'antd';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import { ReactComponent as ArrowIcon } from '@blink/assets/src/icons/arrow.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReactComponent as WalletConnect } from '@blink/assets/src/icons/walletConnect.svg';

import {
    axisTickStyles,
    useBorrowDetailsRiskFactorSectionStyle,
} from './BorrowDetailsActionsSection.style';
import { BorrowDetailsButton } from './BorrowDetailsButton';
import { BorrowDetailsCloseCredit } from './BorrowDetailsCloseCredit/BorrowDetailsCloseCredit';
import { BorrowDetailState } from '../../../../pages/BorrowDetails';
import { showModalAction } from '../../../../store/modals/actions';
import { ModalName } from '../../../../store/modals/constants';
import { Store } from '../../../../store';

type BorrowDetailsRiskFactorSectionProps = {
    pageState: BorrowDetailState;
    setPageState: (value: BorrowDetailState) => void;
    marginAccountAddress: string;
    amount: string;
    accumulatedInterest: string;
    leverageAddress: string;
};

export enum UserBorrowRiskFactorChartFormat {
    WEEK = 'week',
    MONTH = 'month',
}

// const menuItems = [ //Commented for now, will be needed for AR-1094
//     {
//         text: 'Week',
//         value: UserBorrowRiskFactorChartFormat.WEEK,
//     },
//     {
//         text: 'Month',
//         value: UserBorrowRiskFactorChartFormat.MONTH,
//     },
// ];

const TICK_MARGIN = 20;
const MIN_TICK_GAP = 100;
const STROKE_WIDTH = 2;
const GRADIENT_ID = 'gradientId';

const normalizeGraphData = (data: any) => {
    return (
        data.map((item: any) => ({
            timestamp: new Date(item.data.calculatedAt).getTime(),
            value: Number(item.data.riskFactor).toFixed(2),
        })) || []
    );
};

const formatTimestamp = (timestamp: string) => {
    const date = new Date(timestamp);

    const formattedDate = date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
    });

    const formattedTime = date.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    });

    return {
        date: formattedDate,
        time: formattedTime,
    };
};

const timestampTickFormatter = (value: any) => {
    return formatTimestamp(value).time;
};

export const BorrowDetailsActionsSection: FC<BorrowDetailsRiskFactorSectionProps> = ({
    pageState,
    setPageState,
    marginAccountAddress,
    amount,
    accumulatedInterest,
    leverageAddress,
}) => {
    const classes = useBorrowDetailsRiskFactorSectionStyle();
    const dispatch = useDispatch();
    const { address } = useParams();
    const [selectedFilter, setSelectedFilter] = useState(UserBorrowRiskFactorChartFormat.WEEK);

    const { walletAddress, blockchainName, userBorrowsOpened } = useSelector((state: Store) => ({
        walletAddress: state.wallets.active?.address,
        blockchainName: state.wallets.network,
        userBorrowsOpened: state.borrows.userBorrowsOpened,
    }));

    const userBorrow = userBorrowsOpened.find((item) => item.address === address);

    const [graphLoading, setGraphLoading] = useState(true);
    const [riskFactorGraph, setRiskFactorGraph] = useState<any>([]);

    //const handleFilterChange = (e: RadioChangeEvent) => setSelectedFilter(e.target.value); Commented for now, will be needed for AR-1094

    useEffect(() => {
        (async () => {
            try {
                setGraphLoading(true);
                const userBorrowGraphData = normalizeGraphData(userBorrow?.history);
                setRiskFactorGraph(userBorrowGraphData);
                setGraphLoading(false);
            } catch (e) {
                console.error(e);
                setRiskFactorGraph([]);
                setGraphLoading(false);
            }
        })();
    }, [walletAddress, blockchainName, selectedFilter, userBorrowsOpened]);

    const renderTooltip = (props: TooltipProps<any, any>) => {
        const entry = props?.payload?.[0]?.payload;

        if (!entry) {
            return;
        }

        const timestamp = formatTimestamp(entry.timestamp);

        return (
            <div className={classes.tooltip}>
                <div className={classes.tooltipTitle}>
                    {timestamp.date + ' • ' + timestamp.time}
                </div>
                <div className={classes.tooltipValue}>{entry.value}</div>
            </div>
        );
    };
    const walletConnectModalTitle = () => {
        return (
            <div className={classes.walletConnectIconWrapper}>
                <WalletConnect className={classes.walletConnectIcon} />
                WalletConnect
            </div>
        );
    };

    const showTradeModal = () => {
        dispatch(
            showModalAction({
                modalName: ModalName.WALLET_CONNECT,
                modalTitle: walletConnectModalTitle() as any,
                modalProps: {
                    marginAccountAddress,
                },
            }),
        );
    };

    const getContent = () => {
        if (pageState === BorrowDetailState.CLOSE_CREDIT) {
            return (
                <BorrowDetailsCloseCredit
                    marginAccountAddress={marginAccountAddress}
                    onCancel={() => setPageState(BorrowDetailState.DEFAULT)}
                    amount={amount}
                    accumulatedInterest={accumulatedInterest}
                    leverageAddress={leverageAddress}
                />
            );
        }

        const getChart = () => {
            return graphLoading ? (
                <span className={classes.graphLoadingContainer}>
                    <Spin />
                </span>
            ) : (
                <AreaChart data={riskFactorGraph}>
                    <defs>
                        <linearGradient id={GRADIENT_ID} x1='0' x2='0' y1='0' y2='1'>
                            <stop offset='5%' stopColor={color.chartLine2} stopOpacity={0.3} />
                            <stop offset='95%' stopColor={color.chartLine2} stopOpacity={0.1} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid vertical={false} strokeDasharray='8' />
                    <XAxis
                        tickFormatter={timestampTickFormatter}
                        dataKey='timestamp'
                        minTickGap={MIN_TICK_GAP}
                        tickMargin={TICK_MARGIN}
                        tick={axisTickStyles}
                    />
                    <YAxis tick={axisTickStyles} />
                    <Tooltip content={renderTooltip} />
                    <Area
                        type='linear'
                        dataKey='value'
                        stroke={color.chartLine2}
                        fillOpacity={1}
                        fill={`url(#${GRADIENT_ID})`}
                        strokeWidth={STROKE_WIDTH}
                        dot={false}
                    />
                </AreaChart>
            );
        };

        return (
            <>
                <div className={classes.riskFactorTitleWrapper}>
                    <div className={classes.riskFactorTitle}>Risk Factor</div>
                    {/* Commented for now, will be needed for AR-1094 */}
                    {/* <ButtonGroup
                        items={menuItems}
                        value={selectedFilter}
                        handleChange={handleFilterChange}
                        className={classes.filters}
                    /> */}
                </div>
                <div className={classes.chart}>
                    <ResponsiveContainer width='99%' height='99%'>
                        {getChart()}
                    </ResponsiveContainer>
                </div>
                <div className={classes.historicalChartLineWrapper}>
                    <div className={classes.historicalChartLine} />
                    <div>Historical Chart Line</div>
                </div>
                <div className={classes.actionButtonGroupWrapper}>
                    <div className={classes.actionButtonWrapper}>
                        <BorrowDetailsButton
                            icon={<WalletConnect className={classes.walletConnectIconList} />}
                            title='Trade with WalletConnect'
                            text='Connect with WalletConnect to trade'
                            onClick={() => showTradeModal()}
                        />
                    </div>
                </div>
                <Button
                    type='text'
                    className={classes.closeCreditButton}
                    onClick={() => setPageState(BorrowDetailState.CLOSE_CREDIT)}
                >
                    <div>Close Credit Account</div>
                    <ArrowIcon />
                </Button>
            </>
        );
    };

    return <div className={classes.root}>{getContent()}</div>;
};
