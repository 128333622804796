import { AbstractWallet } from './abstractWallet';
import { WalletAddress } from '../../types';
import { SendTransactionProps } from './types';
import {
    COINBASE_ERRORS,
    METAMASK_ERRORS,
    NETWORK,
    NETWORK_CHAIN,
    NETWORK_TO_NETWORK_CHAIN,
} from '../../constants/global';
import { convertToHex } from '../convertToHex';

export enum ETHEREUM_METHOD {
    REQUEST = 'eth_requestAccounts',
    ACCOUNTS = 'eth_accounts',
    CHANGE_NETWORK = 'wallet_switchEthereumChain',
    ADD_NETWORK = 'wallet_addEthereumChain',
    TRANSACTION = 'eth_sendTransaction',
}

const addChainParamsMap = new Map()
    .set(NETWORK.BNB, {
        chainName: 'Binance Smart Chain',
        nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
        rpcUrls: ['https://bsc-dataseed.binance.org/'],
        blockExplorerUrls: ['https://bscscan.com/'],
    })
    .set(NETWORK.POLYGON, {
        chainName: 'Polygon Mainnet',
        nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
        rpcUrls: ['https://polygon-rpc.com/'],
        blockExplorerUrls: ['https://polygonscan.com/'],
    })
    .set(NETWORK.ARBITRUM, {
        chainName: 'Arbitrum One',
        nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
        rpcUrls: ['https://arb1.arbitrum.io/rpc/'],
        blockExplorerUrls: ['https://arbiscan.io/'],
    });
/**
 *     .set(NETWORK.ARKIS, {
 *         chainName: 'Arkis',
 *         nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
 *         rpcUrls: ['https://blinknode-ws.arkisuat.net/'],
 *         blockExplorerUrls: ['https://bscscan.com/'],
 *     })
 */

/**
 * After installation of other wallet extensions
 * Metamask and CoinBase can be in Providers so need to take them from
 * provider map and call all methods from it
 */
export abstract class EthereumWallet extends AbstractWallet {
    protected constructor() {
        super();
    }

    public abstract isWalletInProvider(): boolean;

    public async connect(): Promise<WalletAddress[]> {
        const provider = await this.getProvider();
        return provider.request({ method: ETHEREUM_METHOD.REQUEST });
    }

    public async disconnect(): Promise<void> {
        const provider = await this.getProvider();
        return provider.close ? provider.close() : Promise.resolve();
    }

    public async isConnected(): Promise<boolean> {
        const accounts = await this.getAccounts();
        return this.isAvailable() && accounts.length > 0;
    }

    public async getAccounts(): Promise<WalletAddress[]> {
        const provider = await this.getProvider();
        return provider.request({ method: ETHEREUM_METHOD.ACCOUNTS });
    }

    public async getNetwork(): Promise<NETWORK_CHAIN> {
        const provider = await this.getProvider();
        return provider.networkVersion;
    }

    public async getChainId(): Promise<string> {
        const provider = await this.getProvider();
        return await provider.request({
            method: 'eth_chainId',
            params: [],
        });
    }

    public async changeNetwork(network: NETWORK) {
        const chainId = NETWORK_TO_NETWORK_CHAIN.get(network) as string;
        const chainIdHex = convertToHex(chainId);
        const provider = await this.getProvider();
        try {
            await provider.request({
                method: ETHEREUM_METHOD.CHANGE_NETWORK,
                params: [{ chainId: chainIdHex }],
            });
        } catch (switchError: any) {
            if (
                switchError.code === METAMASK_ERRORS.CHAIN_NOT_ADDED ||
                switchError.code === COINBASE_ERRORS.CHAIN_NOT_ADDED
            ) {
                const params = addChainParamsMap.get(network);

                await provider.request({
                    method: ETHEREUM_METHOD.ADD_NETWORK,
                    params: [
                        {
                            chainId: chainIdHex,
                            ...params,
                        },
                    ],
                });

                // This is needed for a case when user adds a new network but then proceeds to rejecting the switch.
                // wallet_addEthereumChain will not throw an error in this case, so we need to catch it manually.
                // https://github.com/WalletConnect/web3modal/issues/363
                await new Promise((resolve) => setTimeout(resolve, 500));

                const currentNetwork = await this.getNetwork();
                if (currentNetwork !== chainId) {
                    throw { code: METAMASK_ERRORS.USER_REJECTED };
                }

                return;
            }

            throw switchError;
        }
    }

    public async sendTransaction({ data }: SendTransactionProps) {
        const provider = await this.getProvider();
        return provider.request({ method: ETHEREUM_METHOD.TRANSACTION, params: [data] });
    }
}
