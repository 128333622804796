import React, { FC } from 'react';
import { ReactComponent as EtherscanIcon } from '@blink/assets/src/icons/etherscan.svg';

import { useViewOnEtherscanLinkStyles } from './ViewOnEtherscanLink.style';

type ViewOnEtherscanLinkProps = {
    address: string;
};

export const ViewOnEtherscanLink: FC<ViewOnEtherscanLinkProps> = ({ address }) => {
    const classes = useViewOnEtherscanLinkStyles();

    return (
        <a
            href={`https://etherscan.io/address/${address}`}
            target='_blank'
            rel='noreferrer'
            className={classes.root}
        >
            <EtherscanIcon />
            <div>View on Etherscan</div>
        </a>
    );
};
