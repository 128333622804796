export const enum FixedRateMenuKey {
    FIXED_RATE = 'FIXED RATE',
    PROFIT_SHARING = 'PROFIT SHARING',
}

export const fixedRateMenuList = [
    {
        label: 'Fixed Rate',
        key: FixedRateMenuKey.FIXED_RATE,
    },
    // {
    //     label: 'Profit Sharing',
    //     key: FixedRateMenuKey.PROFIT_SHARING,
    // },
];
