import {useEffect, useState} from "react";

/** Returns path to the icon for provided *assetName* */
export const useAssetIcon = (assetName?: string | null) => {
    const [icon, setIcon] = useState<string | null>(null);

    

    useEffect(() => {
        if (!assetName) {
            return;
        }

        (async () => {

            try {

                if (assetName.startsWith('PT-')) {
                    const response = await import(`@blink/assets/src/icons/assets/pendleLogo.png`);
                    setIcon(response.default);
                } else {
                    
                    try {
                        const response = await import(`@blink/assets/src/icons/assetsSvg/${assetName}.svg`);
                        setIcon(response.default);
                    } catch {

                        try {
                            const response = await import(`@blink/assets/src/icons/assets/${assetName}.png`);
                            setIcon(response.default);
                        } catch {
                            return;
                        }
                        
                    }

                }

            } catch {
                return;
            }    

        })();
    }, [assetName]);

    return icon;
}