import React from 'react';
import { Collapse } from 'antd';
import { ReactComponent as CaretDown } from '@blink/assets/src/icons/caret-down.svg';
import { concatClasses } from '@blink/components/src/utils';

import { Layout } from '../Layout/Layout';
import { useFaqsStyles } from './Faqs.styles';

const { Panel } = Collapse;

export const Faqs = () => {
    const classes = useFaqsStyles();

    const expandIcon = ({ isActive }: { isActive?: boolean }) => (
        <CaretDown className={concatClasses(classes.caret, isActive ? classes.activeCaret : '')} />
    );

    return (
        <div className={classes.root}>
            <Layout>
                <div className={classes.title}>FAQ's</div>
                <Collapse expandIcon={expandIcon} ghost>
                    <Panel
                        className={classes.title}
                        header='How can I earn with Arkis Protocol?'
                        key='1'
                    >
                        <p>
                            There are two ways you can get yield through Arkis: Pools and Vaults.
                            Pools usually refer to the P2P deal between the Lender and Asset Manager
                            where they decide on Lend/Borrow APY, types of collateral accepted, etc.
                            If you are a Lender but still don’t have your own Pool yet - please
                            contact <a href='mailto:operations@arkis.xyz'>operations@arkis.xyz</a>.
                        </p>
                        <p>
                            Once you have your Pool - you can Supply your assets to an Pool and earn
                            passive yield. Even though Pools are referred to as P2P markets, several
                            Lenders and Asset Managers can participate in the same Pool. On the
                            other hand, Vaults operate like credit funds managed by Credit Managers:
                            Lenders provide their capital to Vaults while Credit Managers distribute
                            them across various Pools. As a result, the APY earned by Lenders is the
                            weighted average of Pools APYs. If you want to get whitelisted for
                            supplying to Arkis Vaults or want to create your own Arkis Vault and
                            become a Credit Manager - please contact{' '}
                            <a href='mailto:operations@arkis.xyz'>operations@arkis.xyz</a>.
                        </p>
                    </Panel>
                    <Panel
                        className={classes.title}
                        header='How can I withdraw funds from a Pool?'
                        key='2'
                    >
                        <p>
                            On Your Lend tab - click on the pool from which you want to withdraw
                            your funds. If there is available liquidity for withdrawal - you will
                            receive your funds + interest back. If no liquidity is available - your
                            request will be put into the Withdrawal Queue, and the Asset Manager
                            will have 2 weeks to return funds + interest to the liquidity pool.
                        </p>
                    </Panel>
                    <Panel
                        className={classes.title}
                        header='How can I borrow and trade with leverage through Arkis?'
                        key='3'
                    >
                        <p>
                            Click on Borrow, and you will see the list of pools from which you can
                            borrow. Each Pool defines APY, types of collateral, and risk parameters
                            set between the Lender and Asset Manager. If your list of available
                            Pools is empty, you don’t have a Lender willing to lend out to you yet.
                            In this case - please contact{' '}
                            <a href='mailto:operations@arkis.xyz'>operations@arkis.xyz</a>.
                        </p>
                    </Panel>
                </Collapse>
            </Layout>
        </div>
    );
};
