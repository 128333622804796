import { Menu } from '@blink/components';
import React, { FC } from 'react';

import { useFixRateMenuStyle } from './FixedRateMenu.styles';
import { FixedRateMenuKey, fixedRateMenuList } from './constants';

type FixedRateMenuProps = {
    selected: string;
    setSelected: (value: FixedRateMenuKey) => void;
};

export const FixedRateMenu: FC<FixedRateMenuProps> = ({ setSelected, selected }) => {
    const classes = useFixRateMenuStyle();

    return (
        <div className={classes.root}>
            <Menu
                items={fixedRateMenuList}
                onSelect={(event) => setSelected(event.key as FixedRateMenuKey)}
                selectedKeys={[selected]}
            />
        </div>
    );
};
