import { createUseStyles } from 'react-jss';
import { color, fontSize, radius, spacing } from '@blink/components';

export const useModalStyles = createUseStyles({
    modal: {
        width: 'auto !important',
        '&.operational .ant-modal-content': {
            padding: `${spacing['08']} ${spacing['08']} ${spacing['08']}`,
        },
        '& .ant-modal-content': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: `73px ${spacing['08']} ${spacing['07']}`,
            border: `1px solid ${color.border.opaque}`,
            borderRadius: radius.overall,
            backgroundColor: color.background.primary,
        },
        '& .ant-modal-close': {
            top: '24px',
            right: '32px',
            width: '28px',
            height: '28px',

            '&:hover': {
                '& path': {
                    fillOpacity: '1',
                },
            },
        },
    },
    title: {
        position: 'absolute',
        top: 24,
        left: 32,
        right: 32,
        paddingBottom: spacing['07'],
        fontSize: fontSize.xl,
        lineHeight: '32px',
        fontWeight: 600,
        borderBottom: `1px solid ${color.content.stateDisable}`,
    },
});
