import React, { FC, useState } from 'react';
import { ButtonStroke, TipCard } from '@blink/components';
import { ButtonSize } from '@blink/components/src/Button/Button.types';
import { useDispatch, useSelector } from 'react-redux';
import { Message } from '@blink/components/src/constants/messages';
import { message } from 'antd';
import { WalletHelper } from '@blink/components/src/utils';

import { Store } from '../../../store';
import { useLiquidityStyle } from './Liquidity.style';
import { useModal } from '../ModalWrapperHooks';
import { AssetInputSupply } from '../../AssetInputSupply';
import { showModalAction } from '../../../store/modals/actions';
import { ModalName } from '../../../store/modals/constants';
import { useEthers } from '../../../hooks/useEthers';
import { OperationStatusType } from '../../OperationStatusModal';
import { getAssetsToSupplyAction, getUsersSupplies } from '../../../store/supplies/actions';

export const LiquidityModal: FC = () => {
    const classes = useLiquidityStyle();
    const dispatch = useDispatch();
    const { deposit, withdraw } = useEthers();
    const { modalProps } = useModal();
    const {
        balance,
        tokenSymbol,
        currentApy,
        totalDeposited,
        thresholdOnTotalDeposit,
        isWithdraw,
        decimals,
        agreementAddress,
        tokenAddress,
    } = modalProps || {};
    const [amount, setAmount] = useState<string>();

    const { walletAddress } = useSelector((state: Store) => ({
        walletAddress: state.wallets.active.address,
    }));
    const currentModal = {
        activeModal: ModalName.LIQUIDITY_MODAL,
        modalTitle: 'Supply Liquidity',
        modalProps,
    };

    const handleError = (e: any) => {
        dispatch(
            showModalAction({
                modalName: ModalName.OPERATION_STATUS_MODAL,
                modalProps: {
                    type: OperationStatusType.Error,
                    description: e.info?.error?.message || Message.DEFAULT_ERROR,
                },
            }),
        );
    };

    const handleSubmit = async () => {
        if (!walletAddress) {
            dispatch(
                showModalAction({
                    modalName: ModalName.CONNECT_WALLET_MODAL,
                    modalTitle: 'Connect Wallet',
                    previousModal: currentModal,
                }),
            );

            return;
        }

        dispatch(
            showModalAction({
                modalName: ModalName.OPERATION_STATUS_MODAL,
                modalProps: {
                    type: OperationStatusType.Pending,
                    description: Message.PENDING_TEXT,
                },
                previousModal: currentModal,
            }),
        );

        const commonVariables = {
            amount: amount?.toString() || '0',
            agreementAddress,
            tokenAddress,
            orderPlacedCallback: () =>
                dispatch(
                    showModalAction({
                        modalName: ModalName.OPERATION_STATUS_MODAL,
                        modalProps: {
                            type: OperationStatusType.Success,
                            description: Message.SUPPLY_ORDER_PLACED,
                        },
                    }),
                ),
            receiptReceivedCallback: (data: any) => {
                if (data.status === 1) {
                    message.success(Message.TRANSACTION_COMPLETED);
                    dispatch(getUsersSupplies());
                    dispatch(getAssetsToSupplyAction());

                    return;
                }

                throw data.error || Message.DEFAULT_ERROR;
            },
        };

        try {
            if (isWithdraw) {
                await withdraw({
                    ...commonVariables,
                });

                return;
            }

            await deposit(commonVariables);
        } catch (e: any) {
            console.error(e);
            const badMessage = WalletHelper.messageInCaseOfNotCorrectNetwork(e);
            if (badMessage) {
                console.error(badMessage);
            }
            handleError(e);
        }
        return;
    };

    const getSubmitButtonText = () => {
        if (!walletAddress) {
            return 'Connect wallet';
        }

        return `${isWithdraw ? 'Withdraw' : 'Approve'} ${tokenSymbol}`;
    };

    return (
        <div className={classes.root}>
            <div className={classes.text}>{`How much do you want to ${
                isWithdraw ? 'withdraw' : 'supply'
            }?`}</div>
            <AssetInputSupply
                tokenSymbol={tokenSymbol}
                balance={balance}
                value={amount}
                setValue={setAmount}
                decimals={decimals}
            />
            <div className={classes.tipWrapper}>
                <TipCard
                    text='Arkis Liquidity (LP) tokens automatically earn interest proportional to your share
                of the pool.'
                    title='Tip'
                />
            </div>
            <div className={classes.summaryWrapper}>
                <div className={classes.summaryLine}>
                    <div className={classes.summaryLeft}>Current APY</div>
                    <div className={classes.apyWrapper}>
                        <div>{currentApy}%</div>
                    </div>
                </div>
                <div className={classes.summaryLine}>
                    <div className={classes.summaryLeft}>Pool Cap</div>
                    <div>{`${totalDeposited} ${tokenSymbol} / ${thresholdOnTotalDeposit} ${tokenSymbol}`}</div>
                </div>
            </div>
            <ButtonStroke
                size={ButtonSize.lg}
                isPrimary
                className={classes.button}
                onClick={handleSubmit}
                disabled={
                    Number(amount) === 0 ||
                    (!amount && !!walletAddress) ||
                    Number(balance) < Number(amount)
                }
            >
                {getSubmitButtonText()}
            </ButtonStroke>
        </div>
    );
};
