import React, { useState } from 'react';

import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { useGuideInfoStyle } from './GuideInfo.style';

export const GuideInfo = () => {
    const classes = useGuideInfoStyle();
    const [closed, setClosed] = useState(false);
    return closed ? null : (
        <div className={classes.root}>
            Get ready to level up your DeFi lending and borrowing game with our comprehensive guide!
            <CloseIcon className={classes.closeIcon} onClick={() => setClosed(true)} />
        </div>
    );
};
