import { fork } from 'redux-saga/effects';

import { walletWatcher } from './wallet';
import { suppliesWatcher } from './supplies';
import { borrowsWatcher } from './borrows';
import { agreementsWatcher } from './agreements';
import { meWatcher } from './me';

export default function* rootSaga() {
    yield fork(walletWatcher);
    yield fork(suppliesWatcher);
    yield fork(borrowsWatcher);
    yield fork(agreementsWatcher);
    yield fork(meWatcher);
}
