type TruncateProps = {
    value: string;

    startSymbols?: number;
    endSymbols?: number;
};

const DEFAULT_TRUNCATE_LENGTH = 4;

export const truncate = ({
    value,
    startSymbols = DEFAULT_TRUNCATE_LENGTH,
    endSymbols = DEFAULT_TRUNCATE_LENGTH,
}: TruncateProps) => `${value.slice(0, startSymbols)}...${value.slice(value.length - endSymbols)}`;
