import { createUseStyles } from 'react-jss';

import { spacing } from '../../variables';

export const useAssetWithIconStyle = createUseStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    image: {
        height: 32,
        width: 32,
        marginRight: spacing['03'],
    },
});
