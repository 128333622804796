import { createUseStyles } from 'react-jss';

import { color, fontSize, spacing } from '../variables';

export const useBannerStyles = createUseStyles({
    banner: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        padding: `${spacing['04']} ${spacing['10']}`,
        justifyContent: 'center',
        alignItems: 'center',
        gap: spacing['04'],
        backgroundColor: color.background.negative,
        border: 'none',
        borderRadius: 0,
        margin: `${spacing['08']} 0px`,
        '& .ant-alert-content': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 'none',
            gap: spacing['06'],
        },
        '& .ant-alert-message': {
            marginBottom: 0,
            fontSize: fontSize.md,
            fontWeight: 500,
        },
        '& .ant-alert-description': {
            fontSize: fontSize.md,
            fontWeight: 400,
            position: 'relative',
            '&:before': {
                content: "''",
                width: '2px',
                height: '2px',
                backgroundColor: `${color.background.alwaysLight}`,
                position: 'absolute',
                borderRadius: '50%',
                top: 10,
                left: -11,
            },
        },
        '& .ant-alert-close-icon': {
            position: 'absolute',
            right: 20,
            '& path': {
                color: color.content.primary,
            },
        },
        '& .ant-alert-icon': {
            width: 16,
            height: 16,
            marginInlineEnd: 0,
        },
    },
});
