import { createUseStyles } from 'react-jss';
import { color } from '@blink/components';

export const useLayoutStyle = createUseStyles({
    root: {
        padding: '0 10%',
        background: color.background.primary,
    },
    border: {
        borderBottom: `1px solid ${color.border.opaque}`,
    },
});
