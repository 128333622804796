export enum WALLET {
    /**
     * IMPORTANT
     * META_MASK and COIN_BASE taken from providers
     * Don't change it
     */
    META_MASK = 'MetaMask',
    COIN_BASE = 'CoinbaseWallet',

    RABBY = 'Rabby Wallet',

    WALLET_CONNECT = 'WalletConnect',

    KU_COIN = 'KuCoin',
}

export type InstalledWalletList = {
    [key in WALLET]: boolean;
};

export type WalletAddress = string;
