import React from 'react';

import { useAllowedStyle } from '../Allowed/Allowed.style';

const item = {
    address: 'Borrower 1',
    entity: 'Edge Capital',
};

export const Counterparties: React.FC = () => {
    const classes = useAllowedStyle();

    return (
        <div className={classes.tableWrapper}>
            <table className={classes.table}>
                <thead>
                    <tr className={classes.tr}>
                        <th>Margin Account</th>
                        <th>Entity</th>
                    </tr>
                </thead>
                <tbody>
                    <>
                        {/* {items.map((item, index) => {                            
                            return ( */}
                        <tr key={'index //TODO REPLACE'} className={classes.tr}>
                            <td className={classes.firstTd}>{item.address}</td>
                            <td>{item.entity}</td>
                        </tr>
                        {/* );
                        })} */}
                    </>
                </tbody>
            </table>
        </div>
    );
};
