import { actionCreator } from '@blink/components/src/utils';

import {
    GET_USER_BORROWS_CLOSED,
    GET_USER_BORROWS_OPENED,
    GET_USER_BORROWS_REGISTERED,
    GET_USER_BORROWS_SUSPENDED,
    SET_USER_BORROWS_CLOSED,
    SET_USER_BORROWS_MAINTENANCE,
    SET_USER_BORROWS_MAINTENANCE_HISTORY,
    SET_USER_BORROWS_OPENED,
    SET_USER_BORROWS_REGISTERED,
    SET_USER_BORROWS_SUSPENDED,
} from './constants';
import { History, MaintenanceData, UserBorrow } from 'src/api/borrow';

export const getUserBorrowsOpenedAction = () => actionCreator(GET_USER_BORROWS_OPENED);
export const setUserBorrowsOpenedAction = (payload: UserBorrow[]) =>
    actionCreator(SET_USER_BORROWS_OPENED, payload);

export const getUserBorrowsSuspendedAction = () => actionCreator(GET_USER_BORROWS_SUSPENDED);
export const setUserBorrowsSuspendedAction = (payload: UserBorrow[]) =>
    actionCreator(SET_USER_BORROWS_SUSPENDED, payload);

export const getUserBorrowsRegisteredAction = () => actionCreator(GET_USER_BORROWS_REGISTERED);
export const setUserBorrowsRegisteredAction = (payload: UserBorrow[]) =>
    actionCreator(SET_USER_BORROWS_REGISTERED, payload);

export const getUserBorrowsClosedAction = () => actionCreator(GET_USER_BORROWS_CLOSED);
export const setUserBorrowsClosedAction = (payload: UserBorrow[]) =>
    actionCreator(SET_USER_BORROWS_CLOSED, payload);

export const setUserBorrowsMaintenanceAction = (payload: MaintenanceData) =>
    actionCreator(SET_USER_BORROWS_MAINTENANCE, payload);

export const setUserBorrowsMaintenanceHistoryAction = (payload: History<MaintenanceData>[]) =>
    actionCreator(SET_USER_BORROWS_MAINTENANCE_HISTORY, payload);
