import { useSelector } from 'react-redux';
import { AbstractWallet, WalletHelper } from '@blink/components/src/utils';
import { Contract, ethers, parseUnits } from 'ethers';
import { walletMap } from '@blink/components/src/constants/wallet';

import { Store } from '../store';
import AgreementABI from '../abi/AgreementABI.json';
import IERCAbi from '../abi/IERCAbi.json';

type Callbacks = {
    /** Will fire on order being placed on blockchain */
    orderPlacedCallback?: () => void;
    /** Will fire on order being completed/failed on blockchain */
    receiptReceivedCallback?: (data: any) => void;
};

type LiquidityProps = Callbacks & {
    amount: string;
    agreementAddress: string;
    tokenAddress: string;
};

type WithdrawProps = LiquidityProps;

export const useEthers = () => {
    const { address, network, type } = useSelector((state: Store) => ({
        address: state.wallets.active.address,
        type: state.wallets.active.type,
        network: state.wallets.network,
    }));

    const setup = async (agreementAddress: string, tokenAddress: string) => {
        const wallet: AbstractWallet = walletMap.get(type) as AbstractWallet;
        const walletProvider = await wallet.getProvider();

        const provider = new ethers.BrowserProvider(
            walletProvider,
            WalletHelper.getNetworkNumber(network),
        );
        const signer = await provider.getSigner();

        const agreement = new Contract(agreementAddress, AgreementABI, signer);
        const token = new Contract(tokenAddress, IERCAbi, signer);
        return { agreement, token };
    };

    const deposit = async ({
        amount,
        agreementAddress,
        tokenAddress,
        orderPlacedCallback,
        receiptReceivedCallback,
    }: LiquidityProps) => {
        const { agreement, token } = await setup(agreementAddress, tokenAddress);
        const decimals = await token.decimals();
        const allowance = await token.allowance(address, agreementAddress);
        const amountDecimal = parseUnits(amount.toString(), decimals);
        if (allowance < amountDecimal) {
            const approveToken = await token.approve(agreementAddress, amountDecimal);
            await approveToken.wait();
        }

        const tx = await agreement.deposit(amountDecimal);

        orderPlacedCallback?.();

        if (receiptReceivedCallback) {
            const receipt = await tx.wait(1);

            receiptReceivedCallback(receipt);
        }
    };

    const withdraw = async ({
        amount,
        agreementAddress,
        tokenAddress,
        orderPlacedCallback,
        receiptReceivedCallback,
    }: WithdrawProps) => {
        const { agreement, token } = await setup(agreementAddress, tokenAddress);
        const decimals = await token.decimals();
        const amountDecimal = parseUnits(amount, decimals);

        const tx = await agreement.withdraw(amountDecimal);

        orderPlacedCallback?.();

        if (receiptReceivedCallback) {
            const receipt = await tx.wait();

            receiptReceivedCallback(receipt);
        }
    };

    return { deposit, withdraw };
};
