import { createUseStyles } from 'react-jss';
import { color, fontSize, radius } from '@blink/components';

export const useWalletManagerStyles = createUseStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: radius.overall,
        border: `1px solid ${color.border.opaque}`,
        height: 44,
        boxShadow: 'none !important',
    },
    text: {
        color: color.content.primary,
        fontSize: fontSize.md,
        fontWeight: 600,
    },
});
