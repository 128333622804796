type FormatCurrencyProps = Pick<
    Intl.NumberFormatOptions,
    'minimumFractionDigits' | 'maximumFractionDigits' | 'currency'
> & {
    amount: number;
};

export const formatCurrency = ({
    currency = 'USD',
    amount,
    minimumFractionDigits = 2,
    maximumFractionDigits = 8,
}: FormatCurrencyProps) => {
    const numberFormat = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        ...(minimumFractionDigits === undefined
            ? {}
            : { minimumFractionDigits, maximumFractionDigits }),
    });

    return numberFormat.format(amount);
};

export const shortFormatUSDCurrency = ({
    amount,
    minimumFractionDigits = 0,
    maximumFractionDigits = 0,
}: Omit<FormatCurrencyProps, 'currency'>) => {
    if (amount >= 1000000000) {
        return `${formatCurrency({
            amount: amount / 1000000000,
            minimumFractionDigits,
            maximumFractionDigits,
        })}b`;
    }

    if (amount >= 1000000) {
        return `${formatCurrency({
            amount: amount / 1000000,
            minimumFractionDigits,
            maximumFractionDigits,
        })}m`;
    }

    return formatCurrency({ amount, minimumFractionDigits, maximumFractionDigits });
};

export const shortFormatNumber = ({
    amount,
}: Omit<FormatCurrencyProps, 'currency' | 'maximumFractionDigits' | 'minimumFractionDigits'>) => {
    if (amount >= 1000000000) {
        return `${(amount / 1000000000).toFixed(2)}b`;
    }

    if (amount >= 1000000) {
        return `${(amount / 1000000).toFixed()}m`;
    }

    return amount;
};
