import React, { FC } from 'react';
import { Checkbox as AntCheckbox, CheckboxProps as AntCheckboxProps } from 'antd';

import { useCheckboxStyle } from './Checkbox.style';

type CheckboxProps = AntCheckboxProps;

export const Checkbox: FC<CheckboxProps> = ({ className, ...props }) => {
    const classes = useCheckboxStyle();

    return <AntCheckbox className={`${classes.root} ${className}`} {...props} />;
};
