import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';

export const useUserSuppliesSectionStyle = createUseStyles({
    root: {
        width: '100%',
        color: color.content.primary,
        marginTop: 52,
    },
    title: {
        fontSize: fontSize.lg,
        padding: `0 0 ${spacing['06']} ${spacing['06']}`,
    },
    titleWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    iconInfo: {
        width: '20px',
        height: '20px',
        marginLeft: spacing['04'],
    },
    tooltipRoot: {
        borderRadius: 4,
        width: 'fit-content',
        '& .ant-tooltip-arrow': {
            top: 1,
        },
        '& .ant-tooltip-inner': {
            display: 'flex',
            minWidth: 96,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: 4,
            padding: 12,
            border: `1px solid transparent`,
            background: `${color.background.primary} !important`,
        },
        '& .ant-tooltip-arrow:before': {
            background: `${color.background.primary} !important`,
        },
        '&.ant-tooltip': {
            fontSize: `${fontSize.sm} !important`,
            maxWidth: 320,
        },
    },
    tooltipAccountValue: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: 263,
        gap: 4,
        '& p': {
            margin: 0,
            fontSize: fontSize.sm,
            fontWeight: 400,
        },
    },
    table: {
        borderCollapse: 'collapse',
        width: '100%',
        background: color.background.primary,
        border: `1px solid ${color.border.opaque}`,
        minWidth: 820,
    },
    tr: {
        borderBottom: `1px solid ${color.border.opaque}`,
        height: 52,
        '& span': {
            fontWeight: 500,
        },
        '& > th': {
            fontWeight: 'normal',
            textAlign: 'left',
            color: color.content.primary,
            width: '20%',
            background: color.background.secondary,

            '&:last-child': {
                textAlign: 'right',
            },
        },

        '& > td:last-child, th:last-child': {
            paddingRight: spacing['07'],
        },
        '& > th:first-child, td:first-child': {
            paddingLeft: spacing['06'],
        },
    },
    spin: {
        height: 52,
        textAlign: 'center',
    },
    tableWrapper: {
        overflowX: 'auto',
        '& th': {
            fontWeight: 500,
        },
    },
});
