import { call, put, takeLatest } from 'redux-saga/effects';

import { getMe, Me } from 'src/api/info';
import { setMeAction } from 'src/store/me/actions';
import { GET_ME } from 'src/store/me/constants';

function* getMeSaga(): Generator<any, any, any> {
    try {
        const meInfo = yield call(getMe);

        yield put(setMeAction(meInfo as Me));
    } catch (e: any) {
        console.error(e);
    }
}

export function* meWatcher() {
    yield takeLatest(GET_ME, getMeSaga);
}
