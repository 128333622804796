// @ts-nocheck
// File created by ./scripts/create-asset-map
// DO NOT MODIFY
const assetsMap = new Map();
import Coinkwa78 from '../icons/assets/1INCH.png';
assetsMap.set('1INCH', Coinkwa78);
import Coindl6ql from '../icons/assets/3CRV.png';
assetsMap.set('3CRV', Coindl6ql);
import Coinbr2o5 from '../icons/assets/AAVE.png';
assetsMap.set('AAVE', Coinbr2o5);
import Coinwupz6 from '../icons/assets/ADA.png';
assetsMap.set('ADA', Coinwupz6);
import Coinni7fj from '../icons/assets/AERGO.png';
assetsMap.set('AERGO', Coinni7fj);
import Coinocqlb from '../icons/assets/ARB.png';
assetsMap.set('ARB', Coinocqlb);
import Coin3rt9x from '../icons/assets/Arkis.png';
assetsMap.set('Arkis', Coin3rt9x);
import Coin6707a from '../icons/assets/ATOM.png';
assetsMap.set('ATOM', Coin6707a);
import Coinep8q2 from '../icons/assets/AVAX.png';
assetsMap.set('AVAX', Coinep8q2);
import Coinvaf58 from '../icons/assets/BAL.png';
assetsMap.set('BAL', Coinvaf58);
import Coin16c0t from '../icons/assets/Blink.png';
assetsMap.set('Blink', Coin16c0t);
import Coinkob9u from '../icons/assets/BNB.png';
assetsMap.set('BNB', Coinkob9u);
import Coinwvzir from '../icons/assets/BTC.png';
assetsMap.set('BTC', Coinwvzir);
import Coinylgct from '../icons/assets/CAKE.png';
assetsMap.set('CAKE', Coinylgct);
import Coinyxl2f from '../icons/assets/Coinbase.png';
assetsMap.set('Coinbase', Coinyxl2f);
import Cointqnvz from '../icons/assets/COMP.png';
assetsMap.set('COMP', Cointqnvz);
import Coin4m6qx from '../icons/assets/CRO.png';
assetsMap.set('CRO', Coin4m6qx);
import Coinapgys from '../icons/assets/CRV.png';
assetsMap.set('CRV', Coinapgys);
import Coin3ke7d from '../icons/assets/CRV3CRYPTO.png';
assetsMap.set('CRV3CRYPTO', Coin3ke7d);
import Coin73n2c from '../icons/assets/CVX.png';
assetsMap.set('CVX', Coin73n2c);
import Coinmbu5x from '../icons/assets/CVXCRV.png';
assetsMap.set('CVXCRV', Coinmbu5x);
import Coinp23gn from '../icons/assets/DAI.png';
assetsMap.set('DAI', Coinp23gn);
import Coin65ksh from '../icons/assets/DFI.png';
assetsMap.set('DFI', Coin65ksh);
import Coini0x3r from '../icons/assets/DOGE.png';
assetsMap.set('DOGE', Coini0x3r);
import Coinqruhh from '../icons/assets/DOT.png';
assetsMap.set('DOT', Coinqruhh);
import Coingykgj from '../icons/assets/ETC.png';
assetsMap.set('ETC', Coingykgj);
import Coin4ejl4 from '../icons/assets/ETH.png';
assetsMap.set('ETH', Coin4ejl4);
import Coinmixyx from '../icons/assets/FRAX.png';
assetsMap.set('FRAX', Coinmixyx);
import Coinzx44m from '../icons/assets/FXS.png';
assetsMap.set('FXS', Coinzx44m);
import Coinqktez from '../icons/assets/GFI.png';
assetsMap.set('GFI', Coinqktez);
import Coingb5pw from '../icons/assets/GMX.png';
assetsMap.set('GMX', Coingb5pw);
import Coinizsd6 from '../icons/assets/HBAR.png';
assetsMap.set('HBAR', Coinizsd6);
import Coinlbemf from '../icons/assets/LDO.png';
assetsMap.set('LDO', Coinlbemf);
import Coinm5ahj from '../icons/assets/LEO.png';
assetsMap.set('LEO', Coinm5ahj);
import Coinj39p4 from '../icons/assets/LQTY.png';
assetsMap.set('LQTY', Coinj39p4);
import Coinev60k from '../icons/assets/LTC.png';
assetsMap.set('LTC', Coinev60k);
import Coinbmzxl from '../icons/assets/MATIC.png';
assetsMap.set('MATIC', Coinbmzxl);
import Coino4tzi from '../icons/assets/Metamask.png';
assetsMap.set('Metamask', Coino4tzi);
import Coinqt7jj from '../icons/assets/OP.png';
assetsMap.set('OP', Coinqt7jj);
import Coinlo9fn from '../icons/assets/OSMO.png';
assetsMap.set('OSMO', Coinlo9fn);
import Coinw9ku9 from '../icons/assets/pendleLogo.png';
assetsMap.set('pendleLogo', Coinw9ku9);
import Coinra7kh from '../icons/assets/SHIB.png';
assetsMap.set('SHIB', Coinra7kh);
import Coin8lmqt from '../icons/assets/SNX.png';
assetsMap.set('SNX', Coin8lmqt);
import Coin2iakd from '../icons/assets/SOL.png';
assetsMap.set('SOL', Coin2iakd);
import Coinkfizo from '../icons/assets/SPELL.png';
assetsMap.set('SPELL', Coinkfizo);
import Coinvxruq from '../icons/assets/STETH.png';
assetsMap.set('STETH', Coinvxruq);
import Coinykkcb from '../icons/assets/STG.png';
assetsMap.set('STG', Coinykkcb);
import Coin8w9w6 from '../icons/assets/SUSHI.png';
assetsMap.set('SUSHI', Coin8w9w6);
import Coin9vs7k from '../icons/assets/TRX.png';
assetsMap.set('TRX', Coin9vs7k);
import Coinx31pp from '../icons/assets/UNI.png';
assetsMap.set('UNI', Coinx31pp);
import Coinnge1a from '../icons/assets/USDC.png';
assetsMap.set('USDC', Coinnge1a);
import Coind9znw from '../icons/assets/USDT.png';
assetsMap.set('USDT', Coind9znw);
import Coinbw7e9 from '../icons/assets/VVS.png';
assetsMap.set('VVS', Coinbw7e9);
import Coinys1my from '../icons/assets/WalletConnect.png';
assetsMap.set('WalletConnect', Coinys1my);
import Coin3fr3n from '../icons/assets/WAVES.png';
assetsMap.set('WAVES', Coin3fr3n);
import Coinfpw93 from '../icons/assets/WBTC.png';
assetsMap.set('WBTC', Coinfpw93);
import Coin4r3lr from '../icons/assets/WETH.png';
assetsMap.set('WETH', Coin4r3lr);
import Coinqvl5x from '../icons/assets/XLM.png';
assetsMap.set('XLM', Coinqvl5x);
import Coindt9an from '../icons/assets/XRP.png';
assetsMap.set('XRP', Coindt9an);
import Coinvnbc7 from '../icons/assets/XVS.png';
assetsMap.set('XVS', Coinvnbc7);
export { assetsMap };
