import React, { FC } from 'react';
import { ReactComponent as Loader } from '@blink/assets/src/icons/loaderDash.svg';

import { useUserSuppliesStyle } from '../UserSupplies.style';

export const UserSuppliesConnectWallet: FC = () => {
    const commonClasses = useUserSuppliesStyle();

    return (
        <div className={commonClasses.noSuppliesBlock}>
            <Loader />
            <div className={commonClasses.noSuppliesTitle}>Please, connect your wallet</div>
            <div className={commonClasses.noSuppliesSubtitle}>
                Please connect your wallet to see your supplies, borrowings, and open positions
            </div>
        </div>
    );
};
