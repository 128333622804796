import React, { FC, ReactNode } from 'react';

import { useSelectableCardStyle } from './SelectableCard.style';

type SelectableCardProps = {
    icon: ReactNode;
    name: string;
    onClick: () => void;

    isActive?: boolean;
};

export const SelectableCard: FC<SelectableCardProps> = ({ icon, name, isActive, onClick }) => {
    const classes = useSelectableCardStyle();

    return (
        <div
            className={`${classes.root} ${isActive ? classes.rootSelected : ''}`}
            onClick={onClick}
        >
            {icon}
            <div className={classes.label}>{name}</div>
        </div>
    );
};
