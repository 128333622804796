import React, { FC } from 'react';
import { ReactComponent as ArrowIcon } from '@blink/assets/src/icons/arrow.svg';

import { TextButton } from '../TextButton';
import { usePaginationStyles } from './Pagination.styles';
import { concatClasses } from '../utils';

type PaginationProps = {
    currentPage: number;
    pageSize: number;
    totalItems: number;
    onChange: (page: number) => void;

    classes?: Partial<Record<PaginationClasses, string>>;
};

type PaginationClasses = 'root';

export const Pagination: FC<PaginationProps> = ({
    currentPage,
    pageSize,
    totalItems,
    onChange,
    classes,
}) => {
    const styles = usePaginationStyles();

    const handlePrevClick = () => onChange(currentPage - 1);
    const handleNextClick = () => onChange(currentPage + 1);

    const pagesAmount = Math.ceil(totalItems / pageSize);

    if (pagesAmount === 1) {
        return null;
    }

    return (
        <div className={concatClasses(styles.root, classes?.root || '')}>
            <TextButton
                onClick={handlePrevClick}
                disabled={currentPage === 0}
                className={concatClasses(styles.arrow, styles.leftArrow)}
            >
                <ArrowIcon />
            </TextButton>
            {new Array(pagesAmount).fill(undefined).map((_, index) => (
                <TextButton
                    key={index}
                    onClick={() => index !== currentPage && onChange(index)}
                    className={concatClasses(
                        styles.number,
                        index === currentPage ? styles.activeNumber : '',
                    )}
                >
                    {index + 1}
                </TextButton>
            ))}
            <TextButton
                onClick={handleNextClick}
                disabled={currentPage === pagesAmount - 1}
                className={concatClasses(styles.arrow, styles.rightArrow)}
            >
                <ArrowIcon />
            </TextButton>
        </div>
    );
};
