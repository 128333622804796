import { createUseStyles } from 'react-jss';
import { color, fontSize, lineHeight, radius, spacing } from '@blink/components';

export const useSelectStyles = createUseStyles({
    select: {
        width: '100%',
        lineHeight: lineHeight.sm,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&.ant-select-open': {
            '& .ant-select-arrow': {
                transform: 'rotate(180deg)',
                transition: '0.5s',
            },
        },
        '&:hover': {
            backgroundColor: color.background.secondary,
        },
        '& .ant-select-selector': {
            alignItems: 'center',
            height: '44px !important',
            boxShadow: 'none',
            border: `1px solid ${color.border.opaque} !important`,
            padding: `0 ${spacing['04']} !important`,
            fontSize: `${fontSize.md} !important`,
            WebkitBoxShadow: 'none !important',
            backgroundColor: 'transparent !important',
            borderRadius: radius.overall,
            '& span': {
                color: `${color.content.primary} !important`,
            },
            '& .ant-badge > div': {
                borderColor: color.border.accent,
                background: color.background.primary,
            },
            '&:hover': {
                borderColor: `${color.border.selected} !important`,
            },
        },
        '& .ant-select-selection-item': {
            display: 'flex',
            alignItems: 'center',
            '&>div>div': {
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                '&:after': {
                    content: "''",
                    position: 'absolute',
                    width: '7px',
                    height: '7px',
                    left: '17px',
                    top: '1px',
                    backgroundColor: color.background.primary,
                    borderRadius: '50%',
                    border: `1px solid ${color.background.secondary}`,
                },
            },
            '& path#border': {
                fill: color.border.opaque,
            },
            '& path#check': {
                display: 'none',
            },
        },
        '&:hover .ant-select-selector': {
            borderRadius: radius.overall,
        },
        '&.ant-select-open .ant-select-selector': {
            boxShadow: 'none !important',
            borderRadius: radius.overall,
        },
        '& .ant-select-arrow': {
            width: 20,
            height: 20,
            top: '44%',
            justifyContent: 'center',
            insetInlineEnd: 12,
            transition: '0.5s',

            '& svg > path': {
                fill: color.content.primary,
            },
        },
    },
    popup: {
        padding: 0,
        borderRadius: radius.overall,
        background: color.background.secondary,
        border: `1px solid ${color.border.opaque}`,

        '& .ant-select-item': {
            padding: 0,
        },

        '& .ant-select-item-option-content': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& path#check': {
                fill: color.border.selected,
                display: 'none',
                height: 16,
            },
        },

        '& .ant-select-item-option-selected': {
            '& span': {
                color: color.content.primary,
                fontWeight: 400,
            },
            '& .ant-badge > div': {
                borderColor: color.border.accent,
                background: color.background.primary,
            },
            '& path#border': {
                fill: color.content.primary,
            },
            '& path#check': {
                fill: color.border.selected,
                display: 'block !important',
            },
        },
    },
    title: {
        padding: spacing['04'],
        color: `${color.content.secondary} !important`,
        lineHeight: lineHeight.sm,
    },
    option: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        color: color.content.secondary,
        '&>div': {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
        },
        '& .ant-badge-dot': {
            width: '6px',
            height: '6px',
            top: '7px',
            right: '3px',
            backgroundColor: color.background.primary,
        },
        '& path#border': {
            fill: color.border.inverseOpaque,
        },
        '& svg': {
            width: 20,
            height: 20,
        },
    },
    optionLabel: {
        marginLeft: spacing['03'],
    },
    divider: {
        margin: '0px',
        borderBlockStartColor: `${color.border.opaque} !important`,
    },
    wrapper: {
        '& .ant-select-item': {
            padding: `10px ${spacing['04']}`,
            alignItems: 'center',
            '&:hover': {
                '& span': {
                    color: color.content.primary,
                },
            },
        },
        '& .ant-select-item-option': {
            '&:hover': {
                background: color.background.fourth,
                transition: 'none',
                borderRadius: 0,
            },
        },
    },
    hidden: {
        display: 'none !important',
    },
    icon: {
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 32,
        background: color.background.fourth,
        border: `0.5px solid ${color.border.stateDisable}`,
        boxSizing: 'border-box',
    },
    item: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '44px !important',
        border: `1px solid ${color.border.opaque} !important`,
        padding: `0 ${spacing['04']}`,
        fontSize: `${fontSize.md} !important`,
        backgroundColor: 'transparent !important',
        borderRadius: radius.overall,
        '& svg': {
            width: 18,
        },
    },
});
