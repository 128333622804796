import { createUseStyles } from 'react-jss';

import { color, fontSize, radius, spacing } from '../variables';

export const useStyles = createUseStyles({
    direction: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        '&.column': {
            flexDirection: 'column',
        },
        '& .ant-radio-group': {
            marginRight: spacing['05'],
            marginBottom: spacing['03'],
            borderRadius: radius.overall,
            '& label': {
                borderRadius: radius.overall,
            },
        },
        '& .ant-radio-button-wrapper': { fontSize: `${fontSize.lg} !important` },
    },
    input: {
        display: 'flex',
        alignItems: 'center',
        minWidth: '175px',
        height: '49px',
        borderRadius: radius.overall,
        borderColor: color.border.opaque,
        '& .ant-input-number-input-wrap': {
            width: '100%',
            '& input': {
                paddingLeft: spacing['05'],
                fontSize: fontSize.lg,
                '&::placeholder': {
                    color: color.content.secondary,
                },
            },
        },
    },
});
