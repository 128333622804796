import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { EIP6963ProviderDetail } from '@blink/components/src/utils/wallet/types';

import { walletStore } from './walletStore';

interface ProvidersProviderProps {
    children: ReactNode;
}
const ProvidersContext = createContext<EIP6963ProviderDetail[]>([]);

export const ProvidersProvider: React.FC<ProvidersProviderProps> = ({ children }) => {
    const [providers, setProviders] = useState<EIP6963ProviderDetail[]>(walletStore.value());

    useEffect(() => {
        const unsubscribe = walletStore.subscribe(() => {
            setProviders(walletStore.value());
        });

        return () => unsubscribe();
    }, []);

    return <ProvidersContext.Provider value={providers}>{children}</ProvidersContext.Provider>;
};

export const useProviders = () => useContext(ProvidersContext);
