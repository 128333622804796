import React, { FC } from 'react';
import { concatClasses } from '@blink/components/src/utils';
import { Skeleton, Spin } from 'antd';
import { ReactComponent as HealthIcon } from '@blink/assets/src/icons/loaderDashWhite.svg';

import { useCollateralHealthCardStyle } from './CollateralHealthCard.style';
import { InfoCard, InfoCardType } from '../../../InfoCard';
import { RiskFactorBar } from 'src/components/RiskFactor/RiskFactorBar';
import { RiskFactorTooltip } from 'src/components/RiskFactor/RiskFactorTooltip';

type CollateralHealthCardProps = {
    health: number | null;
};

export const CollateralHealthCard: FC<CollateralHealthCardProps> = ({ health }) => {
    const classes = useCollateralHealthCardStyle();

    const pivotHighRisk = 1;
    const pivotLowRisk = 3;

    const loading = health === null;
    const isLow = !loading && health > pivotLowRisk;
    const isWarning = !loading && health >= pivotHighRisk && health <= pivotLowRisk;
    const isHigh = !loading && health < pivotHighRisk;

    const formattedHealth = health?.toFixed(8);

    const getHealthBadge = () => {
        if (loading) {
            return (
                <div className={classes.flexWrapper}>
                    <Spin size='small' />
                    <div>Waiting</div>
                </div>
            );
        }

        return (
            <div
                className={concatClasses(
                    classes.healthBadge,
                    isLow ? classes.healthBadgeSuccess : '',
                    isWarning ? classes.healthBadgeWarning : '',
                    isHigh ? classes.healthBadgeError : '',
                )}
            >
                {isLow && (
                    <>
                        <HealthIcon />
                        <div>Safe</div>
                    </>
                )}
                {isWarning && (
                    <>
                        <HealthIcon />
                        <div>Warning</div>
                    </>
                )}
                {isHigh && (
                    <>
                        <HealthIcon />
                        <div>High Risk</div>
                    </>
                )}
            </div>
        );
    };

    const getInfoCardType = (): InfoCardType => {
        if (isLow) {
            return 'success';
        }

        if (isWarning) {
            return 'alert';
        }

        if (isHigh) {
            return 'error';
        }

        return 'info';
    };

    const getInfoCardText = () => {
        if (loading) {
            return "Secure your position by providing ample collateral to determine the trade's risk factor";
        }

        return isLow || isWarning
            ? 'If the health factor goes below 1, the liquidation of your collateral might be triggered.'
            : 'Your leverage is high, please manage your risk accordingly.';
    };

    return (
        <div>
            <div className={classes.healthFactor}>
                <div className={classes.flexWrapper}>
                    <RiskFactorTooltip riskFactor={formattedHealth || ''} />
                    <div className={classes.secondary}>/</div>
                    {loading ? (
                        <Skeleton.Input className={classes.micro} active />
                    ) : (
                        <div
                            className={
                                isLow
                                    ? classes.success
                                    : isWarning
                                    ? classes.warning
                                    : classes.error
                            }
                        >
                            {formattedHealth}
                        </div>
                    )}
                </div>
                {getHealthBadge()}
            </div>
            <RiskFactorBar health={health} />
            <InfoCard
                type={getInfoCardType()}
                text={getInfoCardText()}
                classes={{ root: classes.infoCard }}
            />
        </div>
    );
};
