import React from 'react';
import { Modal as ModalAnt } from 'antd';
import { ReactComponent as CloseIcon } from '@blink/assets/src/icons/close.svg';

import { useModalStyles } from './ModalWrapper.style';
import { ModalName } from '../../../store/modals/constants';
import { ConnectWalletModal } from '../ConnectWalletModal';
import { useHideModal, useModal } from '../ModalWrapperHooks';
import { CoinbaseNotInstalledModal } from '../CoinbaseNotInstalledModal';
import { MetamaskNotInstalledModal } from '../MetamaskNotInstalledModal';
import { UnsupportedNetworkModal } from '../UnsupportedNetworkModal';
import { LiquidityModal } from '../LiquidityModal';
import { WalletInfoModal } from '../WalletInfoModal';
import { OperationStatusModal } from '../../OperationStatusModal';
import { WalletConnectModal } from '../WalletConnectModal/WalletConnectModal';
import { RabbyNotInstalledModal } from '../RabbyNotInstalledModal';
import { RegisterMarginAccountModal } from '../RegisterMarginAccountModal';
import { MarginAccountDisconnectionModal } from '../MarginAccountDisconnectionModal';
import { BorrowModal } from '../BorrowModal';

const modals = new Map()
    .set(ModalName.CONNECT_WALLET_MODAL, {
        modalComponent: <ConnectWalletModal />,
        defaultModalTitle: 'Connect Wallet',
    })
    .set(ModalName.COINBASE_NOT_INSTALLED_MODAL, {
        modalComponent: <CoinbaseNotInstalledModal />,
    })
    .set(ModalName.RABBY_NOT_INSTALLED_MODAL, {
        modalComponent: <RabbyNotInstalledModal />,
    })
    .set(ModalName.METAMASK_NOT_INSTALLED_MODAL, {
        modalComponent: <MetamaskNotInstalledModal />,
    })
    .set(ModalName.UNSUPPORTED_NETWORK_MODAL, {
        modalComponent: <UnsupportedNetworkModal />,
        closable: false,
    })
    .set(ModalName.LIQUIDITY_MODAL, {
        modalComponent: <LiquidityModal />,
    })
    .set(ModalName.BORROW_MODAL, {
        modalComponent: <BorrowModal />,
    })
    .set(ModalName.WALLET_INFO_MODAL, {
        modalComponent: <WalletInfoModal />,
        defaultModalTitle: 'Account',
    })
    .set(ModalName.WALLET_CONNECT, {
        modalComponent: <WalletConnectModal />,
    })
    .set(ModalName.OPERATION_STATUS_MODAL, {
        modalComponent: <OperationStatusModal />,
        closable: false,
        className: 'operational',
    })
    .set(ModalName.REGISTER_MARGIN_ACCOUNT_MODAL, {
        modalComponent: <RegisterMarginAccountModal />,
        closable: true,
    })
    .set(ModalName.MA_DISCONNECTION, {
        modalComponent: <MarginAccountDisconnectionModal />,
    });

export const ModalWrapper: React.FC = () => {
    const classes = useModalStyles();
    const { modalName, modalTitle } = useModal();
    const {
        modalComponent,
        closable,
        defaultModalTitle,
        className = '',
    } = modals.get(modalName) || {};
    const title = modalTitle || defaultModalTitle;

    const { handleCloseModal } = useHideModal();
    return (
        <>
            {modalName ? (
                <ModalAnt
                    open={!!modalName}
                    centered
                    footer={null}
                    className={`${classes.modal} ${className}`}
                    closeIcon={<CloseIcon />}
                    closable={closable}
                    onCancel={handleCloseModal}
                    destroyOnClose={true}
                    title={title ? <div className={classes.title}>{title}</div> : undefined}
                >
                    {modalComponent}
                </ModalAnt>
            ) : null}
        </>
    );
};
