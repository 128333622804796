import { createUseStyles } from 'react-jss';

import { color, spacing } from '../variables';

export const useViewOnEtherscanLinkStyles = createUseStyles({
    root: {
        color: `${color.content.secondary} !important`,
        width: 'fit-content',
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        gap: spacing['03'],
        height: 44,
        transition: 'none',
        '& path': {
            fill: color.content.secondary,
        },
        '&:hover': {
            color: `${color.content.primary} !important`,
            '& path': {
                fill: color.border.selected,
            },
        },
    },
});
